import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useTranslation } from 'react-i18next'
import Input from '../../../components/Input/index'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
// import {useSelector, useDispatch} from "react-redux"
import { Checkbox } from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import InputError from '../../../components/Error/InputError'
import LockIcon from '@material-ui/icons/Lock'
import { ToastContainer, toast } from 'react-toastify'

export default function Company({
  setUserData,
  value,
  handleCompany,
  setSeePassword,
  seePasswordIcon,
  setActiveTab,
  dispatch,
  Auth,
  authActionTypes,
  userData,
  setAuthPasscode,
}) {
  const [agreeTerms, setAgreeTerms] = useState(false)

  const history = useHistory()
  const { t } = useTranslation()
  const formik = useFormik({
    initialValues: {
      company_name: userData?.company_name ? userData.company_name : '',
      inn: userData?.inn ? userData.inn : '',
      password: userData?.password ? userData.password : '',
    },

    validationSchema: Yup.object({
      company_name: Yup.string()
        .required('Email/Phone number is required.')
        .min(3, 'Company name must be at least 3 characters.')
        .max(30, 'Company name must be less than 50 characters.'),

      inn: Yup.string()
        // add validation for inn
        .required('Password is required.'),
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters.')
        .required('Password is required.'),
    }),

    onSubmit: (values) => {
      if (!agreeTerms) {
        toast.error('Please agree to the terms and conditions.')
      } else {
        try {
          // update user data
          setUserData((value) => ({
            ...value,
            company_name: values.company_name,
            inn: values.inn,
            password: values.password,
          }))
          console.log(value)
          setActiveTab('company')
          Auth.RegistratsiyaCompany({
            owner_name: userData.owner_name,
            email: userData.email,
            phone: userData.phone,
            inn: values.inn,
            password: values.password,
            company_name: values.company_name,
          })
            .then((res) => {
              if (res.code === 200) {
                setAuthPasscode(res.data.passcode_token)
                localStorage.setItem('user_id', res.data.user_id)
                setActiveTab('confirmCode')
                toast.success('Code sent')

                // history.push("/home/dashboard/teamList");
                // localStorage.setItem("token", res.data.token.access_token);

                // dispatch({
                //   type: authActionTypes.AUTH_LOGIN,
                //   payload: {
                //     userLogin: res.data.user.login,
                //     phoneNumber: res.data.user.phone,
                //     accessToken: res.data.token.access_token,
                //     refreshToken: res.data.token.refresh_token,
                //     permissions: res.data.role.permissions,
                //   },
                // });

                // setPasscode((old) => ({
                //   ...old,
                //   passcode_token: res.data.passcode_token,
                // })
                // );
              }
            })
            .catch((err) => {
              switch (err.data.error) {
                case 'NOT_FOUND':
                  toast.error('Не найден', {
                    className: 'bg-red-500 text-white',
                    closeOnClick: true,
                    ideProgressBar: false,
                    autoClose: 2000,
                  })
                  break
                default:
                  // toast.error("Произошла ошибка");
                  toast.error('Произошла ошибка', {
                    className: 'bg-red-500 text-white',
                    closeOnClick: true,
                    ideProgressBar: false,
                    autoClose: 2000,
                  })
                  break
              }
            })
        } catch (err) {
          toast.error('Произошла ошибка', {
            className: 'bg-red-500 text-white',
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 2000,
          })
          console.log(err)
        }
      }
    },
  })
  // console.log(state);
  return (
    <div className=' w-full'>
      <h2 className='mb-10 font-header'>{t('registration')}</h2>

      <div className='w-full flex justify-between '>
        <button
          onClick={() => setActiveTab('registration')}
          type='button'
          className='text-gray-300 p-2 mb-3'
        >
          <ArrowBackIosIcon />
          {t('back')}
        </button>
        <span className='text-gray-300 p-2 mb-3'>{t('page.number.twee')}</span>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className='flex flex-col space-y-2'>
          <label htmlFor='Company_name'>{t('company.name')}</label>
          <span className='items-center space-x-2 p-0.5 rounded-lg flex'>
            <Input
              name='company_name'
              icon={<AccountBalanceIcon className='text-iconColor' />}
              onChange={formik.handleChange}
              value={formik.values.company_name}
              id='fullname_id'
              type='text'
              className='w-full p-1 rounded-md'
            ></Input>
          </span>
          {formik.touched.company_name && formik.errors.company_name ? (
            <InputError msg={formik.errors.company_name} />
          ) : null}
          <label htmlFor='email_id'>ИНН</label>
          <span className='items-center space-x-2 p-0.5 rounded-lg flex'>
            <Input
              name='inn'
              icon={<AssignmentIndIcon className='text-iconColor' />}
              onChange={formik.handleChange}
              value={formik.values.inn}
              type='text'
              id='email_id'
              className='w-full p-1 rounded-md'
            ></Input>
          </span>
          {formik.touched.inn && formik.errors.inn ? (
            <InputError msg={formik.errors.inn} />
          ) : null}
          <div className='text-black my-4'>
            <label htmlFor='pasword_id'>{t('password')}</label>
            <span className='items-center space-x-2 p-0.5 mt-0.5  rounded-lg flex relative'>
              <Input
                name='password'
                className='input-field pr-10'
                onChange={formik.handleChange}
                value={formik.values.password}
                type={seePasswordIcon ? 'password' : 'text'}
                icon={<LockIcon className='text-iconColor' />}
              ></Input>

              <span className='absolute right-3'>
                {seePasswordIcon ? (
                  <VisibilityOffIcon
                    className='text-iconColor'
                    onClick={() => setSeePassword(false)}
                  />
                ) : (
                  <VisibilityIcon
                    className='text-iconColor'
                    onClick={() => setSeePassword(true)}
                  />
                )}
              </span>
            </span>
            {formik.touched.password && formik.errors.password ? (
              <InputError msg={formik.errors.password} />
            ) : null}
          </div>{' '}
          <span className='text-grey -ml-3 flex items-start'>
            <Checkbox
              className='m-0'
              color='primary'
              onClick={() => setAgreeTerms(!agreeTerms)}
            />{' '}
            <span className='w-full pt-2'>
              Нажимая кнопку «Зарегистрироваться», вы даёте согласие на
              обработку данных
            </span>
          </span>
          <div className='py-5'>
            <button
              type='submit'
              className='w-full p-3 rounded-md bg-blue-500 p-3 text-white font-semibold active:bg-blue-700'
            >
              Зарегистрироваться
            </button>
          </div>
          <ToastContainer />
          {/*<p className="text-center text-sm text-iconColor	mt-5">Восстановить пароль</p>*/}
        </div>
      </form>
    </div>
  )
}
