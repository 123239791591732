import ResetPassword from '../views/login/ConfirmEmail/ResetPassword'

export default [
  {
    id: 146,
    component: ResetPassword ,
    path: "/reset-password",
    exact: true,
    title: "Reset Password",
    icon: "",
    showSidepanel: true,
    permission: "",
    children: []
  },
].map((route) => ({ ...route, path: `${route.path}` }))
