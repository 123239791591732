import React, { useState } from 'react'
import './index.scss'
import Header from '../../components/Header/Header'
import TeamTable from '../../components/TeamTable/TeamTable'

export default function App() {
  return (
    <div className='p-5 font-body space-y-4'>
      <Header title={'Команда'} location={`/home/dashboard/create`} />
      <TeamTable />
    </div>
  )
}
