import { Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import cls from "./index.module.scss";
import TopPanel from "./TopPanel";
import * as Yup from "yup";
import { Formik } from "formik";
import InputMask from "react-input-mask";
import axios from "../../utils/axios";
import Form from "../Form/index";
import validateForm from "../../functions/validateForm";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Button from "../Buttons/index";
import { toast } from "react-toastify";
import Admins from "../../models/permissions";
import Select from "../Select/index";

export default function CreateResearcher() {
  const { t } = useTranslation();
  const param = useParams();
  const history = useHistory();
  const [roll, setRoll] = useState({});
  //  tab panel
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //   validation
  const ValidationSchema = Yup.object().shape({
    name: validateForm("name", t),
    email: validateForm("email", t),
    password: validateForm("password", t)
  });
  //    initialState
  const [initialValue, setInitialValue] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    profession_title: "",
    role_id: "",
  });
  const getResearcherRoll = () => {
    const param = {
      client_type_id: "5a3818a9-90f0-44e9-a053-3be0ba1e2c03",
    };
    Admins.getAllRolls(param)
      .then((res) => {
        setRoll(() => [
          ...res.data.roles.map((e) => ({
            ...e,
            label: e.name,
          })),
        ]);
      })
      .catch((err) => console.log(err));
  };
  const onSubmit = (data) => {
    axios
      .post(`/researcher`, {
        company_id: "mirobidjon",
        email: data.email.toLowerCase(),
        name: data.name,
        phone: data.phone,
        password: data.password,
        profession_title: data.profession_title,
        role_id: initialValue.role_id,
      })
      .then((res) => {
        history.push("/home/dashboard");
        toast.success(t("Успешно создан"), {
          className: "bg-green-500 text-white",
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 2000,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error(t(`${err.data.error}`), {
          className: "bg-red-500 text-white",
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 2000,
        });
      });
  };
  useEffect(() => {
    getResearcherRoll();
  }, []);
  return (
    <div className={cls.root}>
      <div className={cls.top}>
        <TopPanel />
      </div>
      <div className={cls.header} style={{ padding: "20px" }}>
        <div className={cls.row}>
          <div className={cls.card}>
            <Formik
              initialValues={initialValue}
              onSubmit={onSubmit}
              validationSchema={ValidationSchema}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <div>
                    <div className={cls.cardTitle}>
                      <h1
                        className="text-black"
                        style={{
                          fontStyle: "normal",
                          fontWeight: "bold",
                          fontSize: "18px",
                          lineHeight: "24px",
                          color: "rgba(26, 32, 36, 1)",
                        }}
                      >
                        {"Добавить нового сотрудника"}
                      </h1>
                    </div>
                    <div className={cls.cardBody}>
                      <div className="w-full py-4 flex items-baseline">
                        <div className="w-1/3">{t("Ф.И.О")}</div>
                        <div className="w-2/3">
                          <Form.Item name="name" formik={formik}>
                            <input
                              placeholder="Введите  Ф.И.О"
                              {...formik.getFieldProps("name")}
                            />
                          </Form.Item>
                        </div>
                      </div>

                      {/* email */}
                      <div className="w-full py-4 flex items-baseline">
                        <div className="w-1/3">{t("Электронная почта")}</div>
                        <div className="w-2/3">
                          <Form.Item name="email" formik={formik}>
                            <input
                              placeholder="Введите электронная почта"
                              {...formik.getFieldProps("email")}
                            ></input>
                          </Form.Item>
                        </div>
                      </div>
                      {/* phone */}
                      <div className="w-full py-4 flex items-baseline">
                        <div className="w-1/3">{t("Телефон")}</div>
                        <div className="w-2/3">
                          <Form.Item name="phone" formik={formik}>
                            <InputMask
                              placeholder="Введите имя номер телефона"
                              id="inputmask"
                              mask={`+9999999999999999`}
                              maskChar={null}
                              {...formik.getFieldProps("phone")}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      {/* password */}
                      <div className="w-full py-4 flex items-baseline">
                        <div className="w-1/3">{t("Пароль")}</div>
                        <div className="w-2/3">
                          <Form.Item name="password" formik={formik}>
                            <input
                              placeholder="Введите пароль"
                              {...formik.getFieldProps("password")}
                            ></input>
                          </Form.Item>
                        </div>
                      </div>
                      {/* postion */}
                      <div className="w-full py-4 flex items-baseline">
                        <div className="w-1/3">{t("Должность")}</div>
                        <div className="w-2/3">
                          <Form.Item name="profession_title" formik={formik}>
                            <input
                              placeholder="Введите должность"
                              {...formik.getFieldProps("profession_title")}
                            ></input>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="w-full py-4 flex items-baseline">
                        <div className="w-1/3">{t("Название Роли")}</div>
                        <div className="w-2/3">
                          <Form.Item name="role_id" formik={formik}>
                            <Select
                              height={"42px"}
                              options={roll}
                              onChange={(e) => {
                                setInitialValue((el) => ({
                                  ...el,
                                  role_id: e.id,
                                }));
                              }}
                              defaultValue={
                                roll?.length >= 1
                                  ? roll.find(
                                      (el) => el.id === initialValue.role_id
                                    )
                                  : {}
                              }
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="w-full py-6">
                        <Form.Item formik={formik}>
                          <div className="flex gap-2 float-right">
                            <Button
                              onClick={() => history.push("/home/dashboard")}
                              style={{
                                color: "blue",
                                border: "1px solid rgba(229, 233, 235, 1)",
                                padding: "20px ,16px",
                                background: "white",
                              }}
                            >
                              Назад
                            </Button>
                            <Button type="submit">Сохранить</Button>
                          </div>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}
