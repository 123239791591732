export default function SvgIcon({ name }) {
  switch (name) {
    case 'upload':
      return uploadIcon
    case 'document':
      return documentIcon
    case 'close':
      return closeIcon
    case 'check':
      return checkIcon
    case 'notCheck':
      return notChecked
    case 'word':
      return wordIcon
    default:
      return <></>
  }
}

const uploadIcon = (
  <svg
    width='14'
    height='16'
    viewBox='0 0 14 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clipRule='evenodd'
      d='M0 15C0 14.7348 0.105357 14.4805 0.292893 14.2929C0.48043 14.1054 0.734784 14 1 14H13C13.2652 14 13.5196 14.1054 13.7071 14.2929C13.8946 14.4805 14 14.7348 14 15C14 15.2652 13.8946 15.5196 13.7071 15.7071C13.5196 15.8947 13.2652 16 13 16H1C0.734784 16 0.48043 15.8947 0.292893 15.7071C0.105357 15.5196 0 15.2652 0 15ZM3.293 4.70703C3.10553 4.5195 3.00021 4.26519 3.00021 4.00003C3.00021 3.73487 3.10553 3.48056 3.293 3.29303L6.293 0.293031C6.48053 0.105559 6.73484 0.000244141 7 0.000244141C7.26516 0.000244141 7.51947 0.105559 7.707 0.293031L10.707 3.29303C10.8892 3.48163 10.99 3.73424 10.9877 3.99643C10.9854 4.25863 10.8802 4.50944 10.6948 4.69485C10.5094 4.88026 10.2586 4.98543 9.9964 4.9877C9.7342 4.98998 9.4816 4.88919 9.293 4.70703L8 3.41403V11C8 11.2652 7.89464 11.5196 7.70711 11.7071C7.51957 11.8947 7.26522 12 7 12C6.73478 12 6.48043 11.8947 6.29289 11.7071C6.10536 11.5196 6 11.2652 6 11V3.41403L4.707 4.70703C4.51947 4.8945 4.26516 4.99982 4 4.99982C3.73484 4.99982 3.48053 4.8945 3.293 4.70703Z'
      fill='#B0BABF'
    />
  </svg>
)


const wordIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.1663 3.25H7.83367C7.37658 3.25 7.00049 3.62609 7.00049 4.08318V7.62491L14.5003 9.81236L22 7.62491V4.08318C22 3.62609 21.6239 3.25 21.1669 3.25H21.1663Z" fill="#41A5EE"/>
    <path d="M21.9995 7.625H7V11.9998L14.4997 14.1873L21.9995 11.9998V7.625Z" fill="#2B7CD3"/>
    <path d="M21.9995 11.9995H7V16.3744L14.4997 18.5618L21.9995 16.3744V11.9995Z" fill="#185ABD"/>
    <path d="M21.9995 16.3745H7V19.9162C7 20.3732 7.376 20.7493 7.833 20.7493H21.1657C21.6227 20.7493 21.9988 20.3732 21.9988 19.9162L21.9995 16.3745Z" fill="#103F91"/>
    <path d="M12.4166 6.375H7V18.2496H12.4166C12.8732 18.2483 13.2484 17.873 13.2497 17.4165V7.20873C13.2484 6.75227 12.8732 6.377 12.4166 6.37564V6.375Z" fill="black" fill-opacity="0.098039"/>
    <path d="M11.7917 7H7V18.8746H11.7917C12.2482 18.8733 12.6235 18.498 12.6247 18.0415V7.83373C12.6235 7.37727 12.2482 7.002 11.7917 7.00064V7ZM11.7917 7H7V17.6246H11.7917C12.2482 17.6233 12.6235 17.248 12.6247 16.7915V7.83373C12.6235 7.37727 12.2482 7.002 11.7917 7.00064V7ZM11.1667 7H7V17.6246H11.1667C11.6232 17.6233 11.9985 17.248 11.9997 16.7915V7.83373C11.9985 7.37727 11.6232 7.002 11.1667 7.00064V7Z" fill="black" fill-opacity="0.2"/>
    <path d="M2.83309 7H11.1659C11.623 7 11.9991 7.376 11.9991 7.83309V16.166C11.9991 16.623 11.623 16.999 11.1659 16.999H2.83309C2.37609 16.999 2 16.623 2 16.166V7.83309C2 7.376 2.37609 7 2.83309 7Z" fill="#185ABD"/>
    <path d="M9.46861 15.1248H8.34361L7.03116 10.8125L5.65616 15.1248H4.53125L3.28125 8.875H4.40625L5.28116 13.2498L6.5937 9H7.53116L8.78116 13.2498L9.65607 8.875H10.7185L9.46861 15.1248Z" fill="white"/>
  </svg>

)

const notChecked = (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.74063 0.604536C8.40781 0.735786 8.21563 0.871723 7.96719 1.1436C7.67188 1.46704 7.4375 1.91704 7.13281 2.74204C6.81406 3.60922 6.65938 3.89516 6.47656 3.94672C6.40156 3.97016 5.92813 4.00766 5.43125 4.0311C4.93438 4.05922 4.3625 4.11079 4.16563 4.15297C3.23281 4.34516 2.65625 4.86547 2.4875 5.67641C2.375 6.2061 2.43594 6.67016 2.77344 7.82797C2.85781 8.12329 2.94219 8.48891 2.96094 8.63891C2.99375 8.9061 2.99375 8.91547 2.83906 9.07954C2.75 9.1686 2.41719 9.44985 2.09375 9.70297C1.32031 10.303 0.9125 10.7389 0.692188 11.1983C0.532813 11.5311 0.523438 11.5827 0.523438 11.9998C0.523438 12.417 0.532813 12.4686 0.692188 12.8014C0.9125 13.2608 1.32031 13.6967 2.09375 14.2967C2.41719 14.5498 2.75 14.8311 2.83906 14.9202C2.98906 15.0795 2.99375 15.0936 2.96094 15.3514C2.94219 15.5014 2.83438 15.9467 2.72188 16.3452C2.60938 16.7436 2.49219 17.2592 2.46406 17.4842C2.30469 18.7264 2.92813 19.5936 4.17031 19.8467C4.37188 19.8889 4.93906 19.9405 5.43594 19.9686C5.93281 19.992 6.40156 20.0295 6.47656 20.053C6.65938 20.1045 6.81406 20.3905 7.13281 21.2577C7.58281 22.4764 7.94844 23.0108 8.55313 23.3061C8.81094 23.4373 8.92813 23.4655 9.25156 23.4795C9.89844 23.503 10.3813 23.3155 11.3422 22.6592C11.9 22.2748 12.35 22.0311 12.5 22.0311C12.65 22.0311 13.1 22.2748 13.6578 22.6592C14.6281 23.3202 15.1109 23.5077 15.7578 23.4748C16.0766 23.4561 16.2031 23.428 16.4516 23.3061C17.0469 23.0108 17.4219 22.4717 17.8672 21.2577C18.1859 20.3905 18.3406 20.1045 18.5234 
    20.053C18.6031 20.0295 19.0719 19.992 19.5688 19.9686C20.0656 19.9405 20.6375 19.8889 20.8344 19.8467C21.7719 19.6545 22.3391 19.1389 22.5125 18.3233C22.625 17.7936 22.5641 17.3295 22.2266 16.1717C22.1422 15.8764 22.0578 15.5108 22.0391 15.3608C22.0063 15.0889 22.0063 15.0842 22.1844 14.8967C22.2875 14.7983 22.5688 14.5592 22.8125 14.3764C23.0563 14.1936 23.45 13.8514 23.6891 13.617C24.0406 13.2655 24.1531 13.1202 24.2984 12.8155C24.4672 12.4686 24.4766 12.417 24.4766 11.9998C24.4766 11.5827 24.4672 11.5311 24.3078 11.1983C24.0875 10.7389 23.6797 10.303 22.9063 9.70297C22.5828 9.44985 22.25 9.1686 22.1609 9.07485L21.9969 8.9061L22.0578 8.5311C22.0859 8.32485 22.1563 8.03891 22.2031 7.89829C22.3484 7.4811 22.5219 6.70766 22.5547 6.35141C22.6344 5.47954 22.2266 4.71547 21.4953 4.3686C21.0688 4.16704 20.6 4.08266 19.5688 4.0311C19.0719 4.00766 18.6031 3.97016 18.5234 3.94672C18.3406 3.89516 18.1859 3.60922 17.8672 2.74204C17.4172 1.52329 17.0516 0.98891 16.4469 0.693598C16.1891 0.562348 16.0719 0.534223 15.7484 0.520161C15.1016 0.496723 14.6188 0.684223 13.6578 1.34047C13.1 1.72485 12.65 1.9686 12.5 1.9686C12.3547 1.9686 11.8906 1.72016 11.4078 1.38735C10.7797 0.95141 10.4516 0.768598 10.0484 0.632661C9.60781 0.487348 9.07813 0.473286 8.74063 0.604536ZM16.5453 8.53579C16.7656 8.59672 17.0563 8.84985 17.1641 9.07485C17.2719 9.29985 17.2625 9.69828 17.1453 9.9186C17.0938 10.0264 15.9359 11.2123 14.3891 12.7545C11.8625 15.2717 11.7172 15.4123 11.5016 15.4639C11.225 15.5295 10.9906 15.4967 10.7422 15.3514C10.4469 15.178 7.925 12.6233 7.83125 12.3983C7.55 11.728 8.01406 11.0108 8.73125 11.0108C9.14844 11.0108 9.29844 11.1186 10.3203 12.1405L11.2578 13.078L13.5078 10.8327C15.2188 9.1311 15.7953 8.58266 15.9219 8.54516C16.1422 8.47485 16.3297 8.47485 16.5453 8.53579Z" fill="white"/>
    <path d="M8.74063 0.604536C8.40781 0.735786 8.21563 0.871723 7.96719 1.1436C7.67188 1.46704 7.4375 1.91704 7.13281 2.74204C6.81406 3.60922 6.65938 3.89516 6.47656 3.94672C6.40156 3.97016 5.92813 4.00766 5.43125 4.0311C4.93438 4.05922 4.3625 4.11079 4.16563 4.15297C3.23281 4.34516 2.65625 4.86547 2.4875 5.67641C2.375 6.2061 2.43594 6.67016 2.77344 7.82797C2.85781 8.12329 2.94219 8.48891 2.96094 8.63891C2.99375 8.9061 2.99375 8.91547 2.83906 9.07954C2.75 9.1686 2.41719 9.44985 2.09375 9.70297C1.32031 10.303 0.9125 10.7389 0.692188 11.1983C0.532813 11.5311 0.523438 11.5827 0.523438 11.9998C0.523438 12.417 0.532813 12.4686 0.692188 12.8014C0.9125 13.2608 1.32031 13.6967 2.09375 14.2967C2.41719 14.5498 2.75 14.8311 2.83906 14.9202C2.98906 15.0795 2.99375 15.0936 2.96094 15.3514C2.94219 15.5014 2.83438 15.9467 2.72188 16.3452C2.60938 16.7436 2.49219 17.2592 2.46406 17.4842C2.30469 18.7264 2.92813 19.5936 4.17031 19.8467C4.37188 19.8889 4.93906 19.9405 5.43594 19.9686C5.93281 19.992 6.40156 20.0295 6.47656 20.053C6.65938 20.1045 6.81406 20.3905 7.13281 21.2577C7.58281 22.4764 7.94844 23.0108 8.55313 23.3061C8.81094 23.4373 8.92813 23.4655 9.25156 23.4795C9.89844 23.503 10.3813 23.3155 11.3422 22.6592C11.9 22.2748 12.35 22.0311 12.5 22.0311C12.65 22.0311 13.1 22.2748 13.6578 22.6592C14.6281 23.3202 15.1109 23.5077 15.7578 23.4748C16.0766 23.4561 16.2031 23.428 16.4516 23.3061C17.0469 23.0108 17.4219 22.4717 17.8672 21.2577C18.1859 20.3905 18.3406 20.1045 18.5234 
    20.053C18.6031 20.0295 19.0719 19.992 19.5688 19.9686C20.0656 19.9405 20.6375 19.8889 20.8344 19.8467C21.7719 19.6545 22.3391 19.1389 22.5125 18.3233C22.625 17.7936 22.5641 17.3295 22.2266 16.1717C22.1422 15.8764 22.0578 15.5108 22.0391 15.3608C22.0063 15.0889 22.0063 15.0842 22.1844 14.8967C22.2875 14.7983 22.5688 14.5592 22.8125 14.3764C23.0563 14.1936 23.45 13.8514 23.6891 13.617C24.0406 13.2655 24.1531 13.1202 24.2984 12.8155C24.4672 12.4686 24.4766 12.417 24.4766 11.9998C24.4766 11.5827 24.4672 11.5311 24.3078 11.1983C24.0875 10.7389 23.6797 10.303 22.9063 9.70297C22.5828 9.44985 22.25 9.1686 22.1609 9.07485L21.9969 8.9061L22.0578 8.5311C22.0859 8.32485 22.1563 8.03891 22.2031 7.89829C22.3484 7.4811 22.5219 6.70766 22.5547 6.35141C22.6344 5.47954 22.2266 4.71547 21.4953 4.3686C21.0688 4.16704 20.6 4.08266 19.5688 4.0311C19.0719 4.00766 18.6031 3.97016 18.5234 3.94672C18.3406 3.89516 18.1859 3.60922 17.8672 2.74204C17.4172 1.52329 17.0516 0.98891 16.4469 0.693598C16.1891 0.562348 16.0719 0.534223 15.7484 0.520161C15.1016 0.496723 14.6188 0.684223 13.6578 1.34047C13.1 1.72485 12.65 1.9686 12.5 1.9686C12.3547 1.9686 11.8906 1.72016 11.4078 1.38735C10.7797 0.95141 10.4516 0.768598 10.0484 0.632661C9.60781 0.487348 9.07813 0.473286 8.74063 0.604536ZM16.5453 8.53579C16.7656 8.59672 17.0563 8.84985 17.1641 9.07485C17.2719 9.29985 17.2625 9.69828 17.1453 9.9186C17.0938 10.0264 15.9359 11.2123 14.3891 12.7545C11.8625 15.2717 11.7172 15.4123 11.5016 15.4639C11.225 15.5295 10.9906 15.4967 10.7422 15.3514C10.4469 15.178 7.925 12.6233 7.83125 12.3983C7.55 11.728 8.01406 11.0108 8.73125 11.0108C9.14844 11.0108 9.29844 11.1186 10.3203 12.1405L11.2578 13.078L13.5078 10.8327C15.2188 9.1311 15.7953 8.58266 15.9219 8.54516C16.1422 8.47485 16.3297 8.47485 16.5453 8.53579Z" fill="#6E8BB7" fill-opacity="0.3"/>
  </svg>

)

const checkIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path 
       d="M8.24063 0.604536C7.90781 0.735786 7.71563 0.871723 7.46719 1.1436C7.17188 1.46704 6.9375 1.91704 6.63281 2.74203C6.31406 3.60922 6.15938 3.89516 5.97656 3.94672C5.90156 3.97016 5.42813 4.00766 4.93125 4.0311C4.43438 4.05922 3.8625 4.11078 3.66563 4.15297C2.73281 4.34516 2.15625 4.86547 1.9875 5.67641C1.875 6.2061 1.93594 6.67016 2.27344 7.82797C2.35781 8.12329 2.44219 8.48891 2.46094 8.63891C2.49375 8.9061 2.49375 8.91547 2.33906 9.07954C2.25 9.1686 1.91719 9.44985 1.59375 9.70297C0.820313 10.303 0.4125 10.7389 0.192188 11.1983C0.0328125 11.5311 0.0234375 11.5827 0.0234375 11.9998C0.0234375 12.417 0.0328125 12.4686 0.192188 12.8014C0.4125 13.2608 0.820313 13.6967 1.59375 14.2967C1.91719 14.5498 2.25 14.8311 2.33906 14.9202C2.48906 15.0795 2.49375 15.0936 2.46094 15.3514C2.44219 15.5014 2.33438 15.9467 2.22188 16.3452C2.10938 16.7436 1.99219 17.2592 1.96406 17.4842C1.80469 18.7264 2.42813 19.5936 3.67031 19.8467C3.87188 19.8889 4.43906 19.9405 4.93594 19.9686C5.43281 19.992 5.90156 20.0295 5.97656 20.053C6.15938 20.1045 6.31406 20.3905 6.63281 21.2577C7.08281 22.4764 7.44844 23.0108 8.05313 23.3061C8.31094 23.4373 8.42813 23.4655 8.75156 23.4795C9.39844 23.503 9.88125 23.3155 10.8422 22.6592C11.4 22.2748 11.85 22.0311 12 22.0311C12.15 22.0311 12.6 22.2748 13.1578 22.6592C14.1281 23.3202 14.6109 23.5077 15.2578 23.4748C15.5766 23.4561 15.7031 23.428 15.9516 23.3061C16.5469 23.0108 16.9219 22.4717 17.3672 21.2577C17.6859 20.3905 17.8406 20.1045 18.0234 20.053C18.1031 20.0295 18.5719 19.992 19.0688 19.9686C19.5656 19.9405 20.1375 19.8889 20.3344 19.8467C21.2719 19.6545 21.8391 19.1389 22.0125 18.3233C22.125 17.7936 22.0641 17.3295 21.7266 16.1717C21.6422 15.8764 21.5578 15.5108 21.5391 15.3608C21.5063 15.0889 21.5063 15.0842 21.6844 14.8967C21.7875 14.7983 22.0688 
       14.5592 22.3125 14.3764C22.5563 14.1936 22.95 13.8514 23.1891 13.617C23.5406 13.2655 23.6531 13.1202 23.7984 12.8155C23.9672 12.4686 23.9766 12.417 23.9766 11.9998C23.9766 11.5827 23.9672 11.5311 23.8078 11.1983C23.5875 10.7389 23.1797 10.303 22.4062 9.70297C22.0828 9.44985 21.75 9.1686 21.6609 9.07485L21.4969 8.9061L21.5578 8.5311C21.5859 8.32485 21.6562 8.03891 21.7031 7.89828C21.8484 7.4811 22.0219 6.70766 22.0547 6.35141C22.1344 5.47954 21.7266 4.71547 20.9953 4.3686C20.5688 4.16704 20.1 4.08266 19.0688 4.0311C18.5719 4.00766 18.1031 3.97016 18.0234 3.94672C17.8406 3.89516 17.6859 3.60922 17.3672 2.74203C16.9172 1.52328 16.5516 0.988911 15.9469 0.693598C15.6891 0.562347 15.5719 0.534224 15.2484 0.520161C14.6016 0.496723 14.1188 0.684223 13.1578 1.34047C12.6 1.72485 12.15 1.9686 12 1.9686C11.8547 1.9686 11.3906 1.72016 10.9078 1.38735C10.2797 0.95141 9.95156 0.768599 9.54844 0.63266C9.10781 0.487349 8.57812 0.473286 8.24063 0.604536ZM16.0453 8.53578C16.2656 8.59672 16.5563 8.84985 16.6641 9.07485C16.7719 9.29985 16.7625 9.69829 16.6453 9.9186C16.5938 10.0264 15.4359 11.2123 13.8891 12.7545C11.3625 15.2717 11.2172 15.4123 11.0016 15.4639C10.725 15.5295 10.4906 15.4967 10.2422 15.3514C9.94688 15.178 7.425 12.6233 7.33125 12.3983C7.05 11.728 7.51406 11.0108 8.23125 11.0108C8.64844 11.0108 8.79844 11.1186 9.82031 12.1405L10.7578 13.078L13.0078 10.8327C14.7188 9.1311 15.2953 8.58266 15.4219 8.54516C15.6422 8.47485 15.8297 8.47485 16.0453 8.53578Z" fill="#0067F4"
       />
  </svg>

)

const documentIcon = (
  <svg
    width='16'
    height='20'
    viewBox='0 0 16 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M15.2266 5.63379V19.1952C15.2266 19.6398 14.8672 20 14.4236 20H1.57641C1.13277 20 0.773438 19.6398 0.773438 19.1952V0.804844C0.773438 0.360156 1.13277 0 1.57641 0H9.60594L15.2266 5.63379Z'
      fill='#2196F3'
    />
    <path
      d='M14.0222 4.42676V19.1954C14.0222 19.64 13.6629 20.0002 13.2192 20.0002H14.4237C14.8673 20.0002 15.2266 19.64 15.2266 19.1954V5.63398L14.0222 4.42676Z'
      fill='#1E88E5'
    />
    <path
      d='M15.2266 5.63379H10.4089C9.96545 5.63379 9.60596 5.27344 9.60596 4.82895V0L15.2266 5.63379Z'
      fill='#90CAF9'
    />
    <path
      d='M11.9236 9.75854H4.07653C3.74317 9.75854 3.4729 9.4883 3.4729 9.1549C3.4729 8.82154 3.74313 8.55127 4.07653 8.55127H11.9236C12.257 8.55127 12.5272 8.8215 12.5272 9.1549C12.5272 9.48826 12.257 9.75854 11.9236 9.75854Z'
      fill='#FAFAFA'
    />
    <path
      d='M11.9236 12.1731H4.07653C3.74317 12.1731 3.4729 11.9029 3.4729 11.5695C3.4729 11.2361 3.74313 10.9658 4.07653 10.9658H11.9236C12.257 10.9658 12.5272 11.2361 12.5272 11.5695C12.5272 11.9028 12.257 12.1731 11.9236 12.1731Z'
      fill='#FAFAFA'
    />
    <path
      d='M11.9236 14.5876H4.07653C3.74317 14.5876 3.4729 14.3174 3.4729 13.984C3.4729 13.6506 3.74313 13.3804 4.07653 13.3804H11.9236C12.257 13.3804 12.5272 13.6506 12.5272 13.984C12.5272 14.3174 12.257 14.5876 11.9236 14.5876Z'
      fill='#FAFAFA'
    />
    <path
      d='M8.54333 17.0022H4.07653C3.74317 17.0022 3.4729 16.732 3.4729 16.3986C3.4729 16.0652 3.74313 15.7949 4.07653 15.7949H8.54333C8.87669 15.7949 9.14696 16.0652 9.14696 16.3986C9.14696 16.732 8.87673 17.0022 8.54333 17.0022Z'
      fill='#FAFAFA'
    />
  </svg>
)

const closeIcon = (
  <svg
    width='13'
    height='13'
    viewBox='0 0 13 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fill-rule='evenodd'
      clipRule='evenodd'
      d='M0.293031 1.29308C0.480558 1.10561 0.734866 1.00029 1.00003 1.00029C1.26519 1.00029 1.5195 1.10561 1.70703 1.29308L6.00003 5.58608L10.293 1.29308C10.3853 1.19757 10.4956 1.12139 10.6176 1.06898C10.7396 1.01657 10.8709 0.988985 11.0036 0.987831C11.1364 0.986677 11.2681 1.01198 11.391 1.06226C11.5139 1.11254 11.6255 1.18679 11.7194 1.28069C11.8133 1.37458 11.8876 1.48623 11.9379 1.60913C11.9881 1.73202 12.0134 1.8637 12.0123 1.99648C12.0111 2.12926 11.9835 2.26048 11.9311 2.38249C11.8787 2.50449 11.8025 2.61483 11.707 2.70708L7.41403 7.00008L11.707 11.2931C11.8892 11.4817 11.99 11.7343 11.9877 11.9965C11.9854 12.2587 11.8803 12.5095 11.6948 12.6949C11.5094 12.8803 11.2586 12.9855 10.9964 12.9878C10.7342 12.99 10.4816 12.8892 10.293 12.7071L6.00003 8.41408L1.70703 12.7071C1.51843 12.8892 1.26583 12.99 1.00363 12.9878C0.741432 12.9855 0.49062 12.8803 0.305212 12.6949C0.119804 12.5095 0.0146347 12.2587 0.0123563 11.9965C0.0100779 11.7343 0.110873 11.4817 0.293031 11.2931L4.58603 7.00008L0.293031 2.70708C0.10556 2.51955 0.000244141 2.26525 0.000244141 2.00008C0.000244141 1.73492 0.10556 1.48061 0.293031 1.29308Z'
      fill='#F76659'
    />
  </svg>
)
