import { BrowserRouter } from 'react-router-dom'
import Root from './Root.jsx'
import { ToastContainer } from 'react-toastify'
// import { Provider } from "react-redux";
import './App.scss'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { authActionTypes } from './redux/actions/authActions/authActionTypes'
 
export default function App() {
  const dispatch = useDispatch()
  const access_token = localStorage.getItem('token')
  useEffect(() => {
    if (!access_token) {
      dispatch({
        type: authActionTypes.AUTH_LOGIN,
        payload: {
          userLogin: '',
          phoneNumber: '',
          accessToken: '',
          refreshToken: '',
          permissions: '',
        },
      })
    }
  }, [])
  return (
    <BrowserRouter>
      <Root />
      <div id='toast'>
        <ToastContainer
          position='top-right'
          autoClose={2000}
          hideProgressBar={true}
          closeOnClick={true}
        />
      </div>
    </BrowserRouter>
  )
}
