// dependency
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// import {Formik} from  "formik"
// import *as Yup from "yup"

// components
import Button from "../Buttons/index";
import Input from "../Input";
import Select from "../Select";
import { useSelector } from "react-redux";

// style class
import cls from "./test.module.scss";

// Service model
import Company from "../../models/companyinquiry";
import axios from "../../utils/axios";
// import ResSearechs from "../../models/researchers";

// functions
import validateForm from "../../functions/validateForm";
import UploadImage from "./UploadImage";
import UploadWithComment from "../UploadWitchComment";
import { GetGlobalVariables } from "./getGlobalVariables";
import { toast } from "react-toastify";
import { Switch } from "@material-ui/core";

export default function StepperTwoList({
  companyId,
  handleNext,
  handleBack,
  operationSistems,
  devices,
  researchersList,
  setinitialValue,
  initialValue,
  totalAmount,
  calculateAmount,
  files,
  setFiles,
  ndaFiles,
  setNdaFiles,
  activeStep,
  handleBlur,
  disableTime,
  totalCostWithoutNds,
  next,
  region,
  audience,
  is_supported,
  getRegions,
  attributeValue,
  getInterests,
  interests,
  active,
  setActive,
}) {
  // const [stepCount, setstepCount] = useState({
  //   base: 30,
  //   counter: 30,
  // });
  const [getVariables, setGetVariables] = useState(false);
  const [defoultstepCount, setdefoultstepCount] = useState(30);
  const [secondDefoultstepCount, setSecondDefoultstepCount] = useState(100);
  const [checkStauts, setCheckStatus] = useState(false);
  const company = useParams();
  const { t } = useTranslation();
  const [link, setLink] = useState();
  const [multipleUrl, setMultipleUrl] = useState([]);
  const [urlNDA, setUrlNDA] = useState([]);
  const [getDevice, setGetDevice] = useState([]);
  const [getOS, setGetOS] = useState([]);
  const [getCountry, setCountry] = useState([]);
  const [getInterest, setInterest] = useState([]);
  const permissions = useSelector((state) => state.auth.permissions);
  const [checkValue, setCheckValue] = useState(false);
  const [promocode, setPromocode] = useState(false);
  const [promocodeSum, setPromocodeSumm] = useState(false);
  const [activeSwitch, setActiveSwitch] = useState(false);

  console.log("activeSwitch", activeSwitch);

  useEffect(() => {
    if (getVariables !== false) {
      setdefoultstepCount(
        Number(
          getVariables.data.data.global_variables.filter(
            (el) => el.key === "base_duration"
          )[0].value
        )
      );
      setSecondDefoultstepCount(50);
    }
  }, [getVariables]);
  useEffect(() => {
    if (!company.inquery_id && getVariables !== false) {
      setinitialValue((el) => ({
        ...el,
        duration: Number(
          getVariables.data.data.global_variables.filter(
            (el) => el.key === "base_duration"
          )[0].value
        ),
      }));
    }
  }, [getVariables]);
  useEffect(() => {
    setUrlNDA([...urlNDA, initialValue.example_nda]);
  }, [company.activestep]);

  const uploadImage = (props) => {
    const file = props[0];

    const formData = new FormData();
    formData.append(`file`, file);
    axios
      .post(`/upload/file`, formData)
      .then((res) => {
        setMultipleUrl((old) => [...old, res.data.url]);
        setFiles((old) => [
          ...old,
          {
            file: {
              name: res.data.url,
            },
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Допустимый размер файла не более 5 МБ", {
          className: "bg-red-500 text-white",
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        });
      });
  };
  const makeObject = (arr) => {
    let linksObject = {};
    arr.forEach((elm, i) => {
      linksObject[i + 1] = elm;
    });
    return linksObject;
  };
  const GlobalCVarebel = async () => {
    let variables = await GetGlobalVariables(companyId);
    setGetVariables(variables);
  };

  useEffect(() => {
    GlobalCVarebel();
  }, [companyId]);

  const data = makeObject(multipleUrl);
  const ndaData = makeObject(urlNDA);
  const device = makeObject(getDevice.map((el) => el.value));
  const os = makeObject(getOS.map((el) => el.value));
  const country = getCountry.map((el) => el);
  const interest = getInterest.map((el) => el);
  const districts = attributeValue?.map((el) => el);
  const byInterest = interests?.map((el) => el);

  const secondUploadImage = (props) => {
    const file = props[0];

    const formData = new FormData();
    formData.append(`file`, file);
    axios
      .post(`/upload/file`, formData)
      .then((res) => {
        setUrlNDA((old) => [...old, res.data.url]);
        setNdaFiles((old) => [
          ...old,
          {
            file: {
              name: res.data.url,
            },
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Допустимый размер файла не более 5 МБ", {
          className: "bg-red-500 text-white",
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        });
      });
  };
  // DEVICE
  useEffect(() => {
    setinitialValue({
      ...initialValue,
      device: Boolean(device[1]) ? device : { 1: "smartphone" },
    });
  }, [getDevice]);

  useEffect(() => {
    const param = {
      requirement_attribute_id: "1a2eeeac-5722-4908-823c-ea8c0fe47e57",
    };
    if (next && activeStep === 1) {
      getInterests(param);
    }
  }, [activeStep, next]);

  // Country;
  useEffect(() => {
    setinitialValue((prev) => ({
      ...prev,
      inquiry_info: {
        district: Boolean(country[1]) ? country : districts,
        by_interest: prev?.inquiry_info?.by_interest,
      },
    }));
  }, [initialValue.region, attributeValue, getCountry]);

  //Interests
  useEffect(() => {
    setinitialValue((prev) => ({
      ...prev,
      inquiry_info: {
        district: prev?.inquiry_info?.district,
        by_interest: Boolean(interest[1]) ? interest : byInterest,
      },
    }));
  }, [initialValue.audience, interests, getInterest]);

  // OS
  useEffect(() => {
    setinitialValue({
      ...initialValue,
      os: Boolean(os[1]) ? os : { 1: "windows" },
    });
  }, [getOS]);

  useEffect(() => {
    if (checkValue) {
      calculateAmount();
      setCheckValue(false);
    }
  }, [checkValue]);

  useEffect(() => {
    calculateAmount({
      promo_code: promocode,
      promo_code_money: promocodeSum,
      promo: active ? true : false,
    });
  }, [active]);

  console.log("active", active);

  const dispatch = useDispatch();
  const [params, setParams] = useState({
    name: "",
    email: "",
    phone: "",
    company_id: company.company_id,
  });

  const onSubmit = (event) => {
    handleNext();
  };

  function setValue(key, value) {
    setinitialValue((el) => ({ ...el, [key]: value }));
  }

  useEffect(() => {
    if (checkStauts) {
      calculateAmount();
      setCheckStatus(false);
    }
  }, [checkStauts]);

  useEffect(() => {
    if (initialValue.researcher_id) {
      setinitialValue((old) => ({
        ...old,
        researcher_name: researchersList.filter(
          (el) => el.value === initialValue.researcher_id
        )[0]?.label,
      }));
    }
  }, [initialValue.researcher_id, activeStep]);

  useEffect(() => {
    if (company.inquiry_id) {
      handleBlur("researcher_name", initialValue.researcher_name);
    }
  }, [initialValue]);

  return (
    <>
      <div className={cls.root}>
        <div className="w-full h-full bg-white m-auto p-5">
          <div className="pt-2 flex w-full ">
            <div className=" w-full">
              {!next && (
                <>
                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3">
                      {t("Инициатор запроса")}
                      <span
                        style={{
                          color: "red",
                          marginLeft: "2px",
                        }}
                      >
                        *
                      </span>
                    </div>
                    <div className="w-2/3">
                      {Boolean(permissions.find((el) => el === "Менеджер")) ? (
                        <Select
                          options={researchersList}
                          onChange={(e) => {
                            setinitialValue((el) => ({
                              ...el,
                              researcher_id: e.value,
                            }));
                          }}
                          value={
                            researchersList
                              ? researchersList.filter(
                                  (el) =>
                                    el.value === initialValue.researcher_id
                                )[0]
                              : {}
                          }
                        />
                      ) : (
                        <Select
                          isDisabled={true}
                          options={researchersList}
                          onChange={(e) => {
                            setinitialValue((el) => ({
                              ...el,
                              researcher_id: researchersList.filter(
                                (el) =>
                                  el.value === localStorage.getItem("user_id")
                              )[0].value,
                            }));
                          }}
                          value={
                            researchersList
                              ? researchersList.filter(
                                  (el) =>
                                    el.value === localStorage.getItem("user_id")
                                )[0]
                              : {}
                          }
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
              {next && (
                <>
                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3">{t("Страна")}</div>
                    <div className="w-2/3">
                      <Select
                        onChange={(val) => {
                          setinitialValue((old) => ({
                            ...old,
                            region: val,
                          }));
                          const param = {
                            requirement_attribute_id: val.value,
                          };
                          getRegions(param);
                          setCheckValue(true);
                        }}
                        options={region}
                        defaultValue={
                          region.filter(
                            (el) => el.value === initialValue.region.value
                          )[0]
                        }
                      />
                    </div>
                  </div>
                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3">{t("Город")}</div>
                    <div className="w-2/3">
                      <Select
                        isMulti={true}
                        onChange={(val) => {
                          setCountry(val.map((el) => el));
                        }}
                        value={
                          getCountry < 1
                            ? attributeValue?.filter(
                                (el, ind) => initialValue.inquiry_info.district
                              )
                            : getCountry.filter((el) => el)
                        }
                        options={attributeValue}
                      />
                    </div>
                  </div>
                </>
              )}
              {next && (
                <div className="w-full py-4 flex items-baseline">
                  <div className="w-1/3">{t("Опрос узкой аудитории")}</div>
                  <div className="w-2/3">
                    <Select
                      onChange={(val) => {
                        setCheckValue(true);
                        setinitialValue((old) => ({
                          ...old,
                          audience: val,
                        }));
                      }}
                      options={audience}
                      defaultValue={
                        audience.filter(
                          (el) => el.value === initialValue.audience.value
                        )[0]
                      }
                    />
                  </div>
                </div>
              )}
              {next &&
              initialValue.audience.value ===
                "1a2eeeac-5722-4908-823c-ea8c0fe47e57" ? (
                <div className="w-full py-4 flex items-baseline">
                  <div className="w-1/3">
                    {t("Опросить людей, которые интересуются темой")}
                  </div>
                  <div className="w-2/3">
                    <Select
                      isMulti={true}
                      onChange={(val) => {
                        setInterest(val.map((el) => el));
                      }}
                      value={
                        getInterest < 1
                          ? interests?.filter(
                              (el, ind) =>
                                initialValue.inquiry_info.by_interest[ind + 1]
                            )
                          : getInterest.filter((el) => el)
                      }
                      options={interests}
                    />
                  </div>
                </div>
              ) : null}
              {next ? (
                <>
                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3">{t("Сопровождение")}</div>
                    <div className="w-2/3">
                      <Select
                        onChange={(val) => {
                          setCheckValue(true);
                          setinitialValue((old) => ({
                            ...old,
                            is_supported: val.value,
                          }));
                        }}
                        options={is_supported}
                        defaultValue={
                          is_supported.filter(
                            (el) => el.value === initialValue.is_supported
                          )[0]
                        }
                      />
                    </div>
                  </div>
                  <div className="w-full  flex items-baseline">
                    <div className="w-1/3"></div>
                    <div className="w-2/3">
                      <p style={{ fontWeight: "500" }}>{t("support")}</p>
                    </div>
                  </div>
                </>
              ) : null}

              {/*  */}
              {!next && (
                <>
                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3">
                      {t("Устройство для подключения")}
                    </div>
                    <div className="w-2/3">
                      <Select
                        isMulti={true}
                        onChange={(val) => {
                          setGetDevice(val.map((el) => el));
                        }}
                        value={
                          getDevice < 1
                            ? devices.filter(
                                (el, ind) => initialValue.device[ind + 1]
                              )
                            : getDevice.filter((el) => el)
                        }
                        options={devices}
                      />
                    </div>
                  </div>
                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3">{t("Операционная система")}</div>
                    <div className="w-2/3">
                      <Select
                        isMulti={true}
                        onChange={(val) => {
                          setGetOS(val.map((el) => el));
                        }}
                        value={
                          getOS < 1
                            ? operationSistems.filter(
                                (el, ind) => initialValue.os[ind + 1]
                              )
                            : getOS.filter((el) => el)
                        }
                        options={operationSistems}
                      />
                    </div>
                  </div>
                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3">
                      {t("Опишите, что необходимо приложить респонденту")}
                    </div>
                    <div className="w-2/3">
                      <Input
                        className="p-0.5"
                        placeholder={
                          "Здесь вы можете указать, какие документы нужно подписать или приложить респонденту. Допустимый формат: pdf, .doc, .docx"
                        }
                        type={"text"}
                        value={initialValue.nda_text}
                        onChange={(e) => {
                          company.inquiry_id
                            ? handleBlur("nda_text", e.target.value)
                            : setValue("nda_text", e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3">
                      {t("Загрузите шаблон или пример файла для респондента")}
                    </div>
                    <div className="w-2/3">
                      <UploadWithComment
                        link={link}
                        ndaData={ndaData}
                        files={files}
                        setFiles={setFiles}
                        nda={false}
                        initialValue={initialValue}
                        setMultipleUrl={setMultipleUrl}
                        multipleUrl={multipleUrl}
                        uploadImage={uploadImage}
                      />
                    </div>
                  </div>
                  <div className="w-full py-4 flex items-baseline">
                    <div className="w-1/3">
                      {t(
                        "Загрузите пример документа для заполнения или подписания"
                      )}
                    </div>
                    <div className="w-2/3">
                      <UploadWithComment
                        ndaData={ndaData}
                        setNdaFiles={setNdaFiles}
                        ndaFiles={ndaFiles}
                        initialValue={initialValue}
                        nda={true}
                        urlNDA={urlNDA}
                        setUrlNDA={setUrlNDA}
                        uploadImage={secondUploadImage}
                      />
                    </div>
                  </div>
                  {!next && (
                    <div className="w-full py-4 flex align-center">
                      <div className="w-1/3">
                        {t("Вознаграждение промокодом")}
                      </div>
                      <div className="w-2/3">
                        <Switch
                          defaultChecked={active}
                          onChange={(e) => {
                            setActive(e.target.checked);
                            setActiveSwitch(e.target.checked);
                          }}
                          color="primary"
                        />
                      </div>
                    </div>
                  )}
                  {activeSwitch && !next ? (
                    <div className="w-full py-4 flex items-baseline">
                      <div className="w-1/3">{t("Промокод")}</div>
                      <div className="w-2/3">
                        <Input
                          className="p-0.5"
                          placeholder={"Введите промокод"}
                          type={"text"}
                          value={initialValue.promo_code}
                          onChange={(e) => {
                            setValue("promo_code", e.target.value);
                            // setPromocode(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {activeSwitch && !next ? (
                    <div className="w-full py-4 flex items-baseline">
                      <div className="w-1/3">{t("Номинал промокода")}</div>
                      <div className="w-2/3">
                        <Input
                          className="p-0.5"
                          placeholder={"Укажите номинал промокода"}
                          type={"nuber"}
                          value={initialValue.promo_code_money}
                          onChange={(e) => {
                            setValue("promo_code_money", e.target.value);
                            // setPromocodeSumm(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <hr className="my-2"></hr>
                  <div className="w-full py-4 flex align-center">
                    <div className="w-1/3">{t("Длительность (мин)")}</div>
                    <div className="w-2/3">
                      <Input
                        disabled={disableTime}
                        step={defoultstepCount}
                        min={defoultstepCount}
                        type={"number"}
                        className="p-0.5"
                        value={initialValue.duration}
                        onKeyDown={(e) => {
                          !(e.keyCode === 38 || e.keyCode === 40) &&
                            e.preventDefault();
                        }}
                        onChange={(e) => {
                          setinitialValue((el) => ({
                            ...el,
                            duration: Number(
                              `${
                                parseInt(e.target.value) < 30
                                  ? 30
                                  : parseInt(e.target.value)
                              }`
                            ),
                          }));
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
              {next ? (
                <div className="w-full py-4 flex items-baseline">
                  <div className="w-1/3">{t("Количество респондентов")}</div>
                  <div className="w-2/3">
                    <Input
                      step={secondDefoultstepCount}
                      min={secondDefoultstepCount}
                      type={"number"}
                      className="p-0.5"
                      value={initialValue.number_of_partisipants}
                      onKeyDown={(e) => {
                        !(e.keyCode === 38 || e.keyCode === 40) &&
                          e.preventDefault();
                      }}
                      onChange={(e) => {
                        setinitialValue((el) => ({
                          ...el,
                          number_of_partisipants: Number(
                            `${
                              parseInt(e.target.value) < 50
                                ? 50
                                : parseInt(e.target.value)
                            }`
                          ),
                        }));
                        setCheckStatus(true);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="w-full py-4 flex items-baseline">
                  <div className="w-1/3">{t("Количество респондентов")}</div>
                  <div className="w-2/3">
                    <Input
                      className="p-0.5"
                      type={"number"}
                      min={1}
                      disabled={
                        initialValue.type_of_inquiry.type_of_inquiry === "group"
                          ? true
                          : false
                      }
                      value={
                        initialValue.type_of_inquiry.type_of_inquiry === "group"
                          ? initialValue.type_of_inquiry?.group_participants *
                            initialValue.type_of_inquiry?.number_of_groups
                          : initialValue.number_of_partisipants
                      }
                      onChange={(e) => {
                        setinitialValue((val) => ({
                          ...val,
                          number_of_partisipants: Number(e.target.value),
                        }));
                        setCheckStatus(true);
                      }}
                    />
                  </div>
                </div>
              )}

              <div className="w-full py-4 flex items-baseline">
                <div className="w-1/3 text-head">{t("Стоимость рекрута")}</div>
                <div className="w-2/3">
                  <Input
                    type={"number"}
                    className="p-0.5"
                    value={totalCostWithoutNds}
                    disabled
                  />
                </div>
              </div>
              <div className="w-full py-4 justify-between">
                <div className="w-full  float-right gap-4">
                  <Button
                    onClick={onSubmit}
                    style={{
                      background: "rgba(64, 148, 247, 1)",
                      paddingLeft: "10px",
                      float: "right",
                      padding: "10px",
                    }}
                  >
                    Продолжить
                  </Button>
                  <Button
                    className="float-right "
                    onClick={() => handleBack(0)}
                    style={{
                      marginRight: "5px",
                      color: "gray",
                      width: "100px",
                      borderRadius: "9px",
                      border: "1px solid rgba(229, 233, 235, 1)",
                      padding: " 10px",
                      background: "white",
                    }}
                  >
                    Назад
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
