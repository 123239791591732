import { combineReducers } from 'redux'
import counterReducer from './counterReducer'
import authReducer from './authReducer'
import regstratsiya from './regstratsiya'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import inqueryReducer from './inqueryReducer'

const authPersistConfig = {
  key: 'auth',
  storage,
}

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  counter: counterReducer,
  regstratsiya: regstratsiya,
  inqueryReducer:inqueryReducer,
})

export default rootReducer
