import request from '../utils/axios'
const base_Url = {
  respondent: '/respondents',
}
const requests = {
  respondent: (params) =>
    request({
      url: `${base_Url.respondent}`,
      params: params,
    }),
  updateRespondentStatus: ({ respondent_id, data }) =>
    request({
      url: `/respondent/update-status/${respondent_id}`,
      method: 'put',
      data,
    }),
}
export default requests
