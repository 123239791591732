import CreateResearcher from "../components/CreateResearcher/CreateResearcher.js";
import EditResearcher from "../components/CreateResearcher/EditResearcher.js";
import CompanyInquiryCreateList from "../views/company/InquiryCreateList";
import CompanyInquiryListItem from "../views/company/CompanresearcherListItem";
import Dashboard from "../views/dashboard/index.jsx";
import ResultQuestion from "../components/ResultQuestion/ResultQuestion.jsx";
import ResearcherCalendar from "../views/ResearcherCalendar/ResearcherCalendar";
import AllResearcherTable from "../components/ResearchTable/index.js";
import Transaction from "../views/transactions/transaction";
import CalendarBooked from "../views/company/Calendar/CalendarBooked.jsx";
import AnswerItem from "../components/TolokaInfoTable/InfoTable.jsx";

export default [
  {
    id: 1,
    component: Dashboard,
    path: "/dashboard",
    exact: true,
    title: "Dashboard",
    permission: "Команда",
    children: [],
  },
  {
    id: 145,
    component: EditResearcher,
    path: "/dashboard/edit/:id",
    exact: false,
    title: "Researcher",
    permission: "Редактировать",
    children: [],
  },
  {
    id: 146,
    component: CreateResearcher,
    path: "/dashboard/create",
    exact: true,
    title: "Researcher",
    permission: "Добавить",
    children: [],
  },
  {
    component: CompanyInquiryCreateList,
    path: "/company/companyinquirycreate/:company_id",
    exact: true,
    title: "companyinquirycreate",
    icon: "",
    showSidepanel: true,
    permission: "Запросы",
    children: [],
  },
  {
    component: CompanyInquiryCreateList,
    path: "/company/companyinquirycreate/:company_id/edit/:inquery_id/:activestep",
    exact: true,
    title: "company inquery edit",
    icon: "",
    showSidepanel: true,
    permission: "Запросы",
    children: [],
  },
  {
    component: CompanyInquiryListItem,
    path: "/company/inquiryitem/:id/:inquiry_id",
    exact: true,
    title: "inquiry",
    icon: "",
    showSidepanel: true,
    permission: "Запросы",
    children: [],
  },
  {
    component: AnswerItem,
    path: "/company/answers/:user_id/:pool_id/:index",
    exact: true,
    title: "inquiry",
    icon: "",
    showSidepanel: true,
    permission: "Запросы",
    children: [],
  },
  {
    component: ResultQuestion,
    path: "/question/:survey_id/:respondent_id",
    exact: true,
    title: "question",
    icon: "",
    permission: "Запросы",
    children: [],
  },
  {
    component: ResearcherCalendar,
    path: "/calendar",
    exact: true,
    title: "calendar",
    icon: "",
    permission: "Календарь",
    children: [],
  },
  {
    component: AllResearcherTable,
    path: "/research",
    exact: true,
    title: "researcher",
    icon: "",
    permission: "Запросы",
    children: [],
  },
  {
    component: CalendarBooked,
    path: "/calendar/table/:id/:inquiry_id",
    exact: true,
    title: "Calendar Table",
    icon: "",
    showSidepanel: true,
    permission: "Календарь",
    children: [],
  },
  {
    component: Transaction,
    path: "/transactions",
    exact: true,
    title: "researcher",
    icon: "",
    permission: "Транзакции",
    children: [],
  },
].map((route) => ({
  ...route,
  path: `/home${route.path}`,
  id: new Date().getTime() + Math.random(),
}));
