import { useHistory, useLocation } from "react-router-dom";
// import BackupErrow from "../../../components/svg/BackArrow"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Input from "../../../components/Input/index";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import { useTranslation } from "react-i18next";
import { ProfileIcon } from "../../../components/svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputError from "../../../components/Error/InputError";
import { ToastContainer, toast } from "react-toastify";
import validateForm from '../../../functions/validateForm'


export default function App({ setUserData, value, setActiveTab, userData }) {
  // console.log(BackupErrow);
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      fullname:  userData?.owner_name ? userData.owner_name : "",
      email: userData?.email ? userData.email : "",
      phone: userData?.phone ? userData.phone : "",
    },

    validationSchema: Yup.object({
      fullname: Yup.string()
        .required("Fullname is required")
        .min(5, "Fullname must be at least 5 characters")
        .max(50, "Fullname must be at most 50 characters"),

      email: Yup.string().email("Invalid Email").required("Email is required."),

      phone: Yup.string()
        .required("Phone number is required.")
        .test("test-name", "Enter Valid Phone", function (value) {
          const phoneRegex = /^\+{1}\d{3}[\s.-]?\d{2}[\s.-]?\d{3}[\s.-]?\d{2}[\s.-]?\d{2}$/; // Change this regex based on requirement
          let isValidPhone = phoneRegex.test(value);
          if (!isValidPhone) {
            return false;
          }
          return true;
        }),
    }),

    onSubmit: (values) => {
      try{
        setUserData((value) => ({
        ...value,
        owner_name: values.fullname,
        email: values.email,
        phone: values.phone,
      }));
      setActiveTab("company");
    }
      catch(err){
        toast("an error occured")
        console.log(err);}
      
      // alert(JSON.stringify(values, null, 2));
    },
  });
  return (
    <div className=" w-full">
      <h2 className="mb-10 font-header">{t("registration")}</h2>
      <div className="w-full flex justify-between">
        <button
          onClick={() => setActiveTab("login")}
          type="button"
          className="p-0 mb-3 mt-3 text-gray-300"
          style={{ background: "transparent" }}
        >
          <ArrowBackIosIcon /> {t("back")}
        </button>
        <span className="p-0 mb-3 mt-3 text-gray-300">
          {t("page.numberone")}
        </span>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col space-y-2">
          <label htmlFor="fullname_id">{t("full.name")}</label>
          <span className="items-center space-x-2 p-0.5 rounded-lg flex">
            <Input
              name="fullname"
              icon={<ProfileIcon className={"text-iconColor"} />}
              onChange={formik.handleChange}
              value={formik.values.fullname}
              id="fullname_id"
              type="text"
              className="w-full p-1 rounded-md"
            ></Input>
          </span>
          {formik.touched.fullname && formik.errors.fullname ? (
            <InputError msg={formik.errors.fullname} />
          ) : null}
          <label className="mt-2" htmlFor="email_id">
            {t("email.name")}
          </label>
          <span className="items-center space-x-2 p-0.5  rounded-lg flex">
            <Input
              name="email"
              icon={<AlternateEmailIcon className={"text-iconColor"} />}
              onChange={formik.handleChange}
              value={formik.values.email}
              type="text"
              id="email_id"
              className="w-full  p-1 rounded-md"
            ></Input>
          </span>
          {formik.touched.email && formik.errors.email ? (
            <InputError msg={formik.errors.email} />
          ) : null}
          <label htmlFor="phone_id">{t("Phone")}</label>
          <span className="items-center space-x-2 p-0.5 rounded-lg flex">
            <Input
              name="phone"
              icon={<LocalPhoneIcon className={"text-iconColor"} />}
              onChange={formik.handleChange}
              value={formik.values.phone}
              type="text"
              id="phone_id"
              placeholder={"+998 765 43 21"}
              className="w-full  p-1 rounded-md"
            ></Input>
          </span>
          {formik.touched.phone && formik.errors.phone ? (
            <InputError msg={formik.errors.phone} />
          ) : null}

          <div className="py-6">
            <button
              type="submit"
              className="w-full rounded-md p-3 bg-blue-500 p-3 text-white font-semibold active:bg-blue-700"
            >
              {t("next")}
            </button>
          </div>
        </div>
      </form>
      <ToastContainer/>
    </div>
  );
}
