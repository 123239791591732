import LockIcon from "@material-ui/icons/Lock";
import Input from "../../components/Input/index";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { useFormik } from "formik";
import Checkbox from "@material-ui/core/Checkbox";
import * as Yup from "yup";
import { useState } from "react";
import Auth from "../../services/auth";
import { authActionTypes } from "../../redux/actions/authActions/authActionTypes";
import { ToastContainer, toast } from "react-toastify";
import InputError from "../../components/Error/InputError";
import validateForm from "../../functions/validateForm";

export default function LoginForm({
  setSeePassword,
  seePasswordIcon,
  setActiveTab,
  t,
  history,
  dispatch,
}) {
  // Formik
  const [rememberMe, setRememberMe] = useState(false);
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },

    validationSchema: Yup.object({
      username: Yup.string()
        .required("Введите е-mail")
        .test("test-name", "Неправильный логин или пароль", function (value) {
          const emailRegex =
            /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

          const phoneRegex = /^(\+91-|\+91|0)?\d{9}$/; // Change this regex based on requirement
          let isValidEmail = emailRegex.test(value);
          let isValidPhone = phoneRegex.test(value);
          if (!isValidEmail && !isValidPhone) {
            return false;
          }
          return true;
        }),

      password: validateForm("password", t),
    }),

    onSubmit: (values) => {
      if (true) {
        Auth.login({
          username: values.username.toLowerCase(),
          password: values.password,
          data: "admin_panel",
        })
          .then((res) => {
            const permissions = res.data.role.permissions.map((el) => el.name);
            // history.push('/home/dashboard/teamList')
            localStorage.setItem("token", res.data.token.access_token);
            localStorage.setItem("refresh_token", res.data.token.refresh_token);
            localStorage.setItem("user_id", res.data.user.id);

            dispatch({
              type: authActionTypes.AUTH_LOGIN,
              payload: {
                userLogin: res.data.user.login,
                phoneNumber: res.data.user.phone,
                accessToken: res.data.token.access_token,
                refreshToken: res.data.token.refresh_token,
                permissions,
              },
            });
            history.push("/home/dashboard");
          })
          .catch((err) => {
            switch (err.data.error) {
              case "NOT_FOUND":
                toast.error("Не найден", {
                  className: "bg-red-500 text-white",
                  closeOnClick: true,
                  ideProgressBar: false,
                  autoClose: 2000,
                });
                break;
              default:
                toast.error("Неправильный логин или пароль!", {
                  className: "bg-red-500 text-white",
                  closeOnClick: true,
                  ideProgressBar: false,
                  autoClose: 2000,
                });
                break;
            }
          });
      }
    },
  });
  return (
    <div className=" w-full">
      <h2 className="mb-10 font-header">{t("platform")}</h2>

      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col space-y-0">
          <label className="" htmlFor="email_id">
            {t("emailAndPhoneNumber")}
          </label>
          <span className="space-x-2 p-0.5 mt-0.5 rounded-lg">
            <Input
              name="username"
              className="input-field"
              onChange={formik.handleChange}
              value={formik.values.username}
              type="text"
              placeholder="Введите адрес электронной почты"
              // id="email_id"
              className="p-1"
              icon={<AccountCircleIcon className="text-iconColor" />}
            ></Input>
          </span>
          {formik.touched.username && formik.errors.username ? (
            <InputError msg={formik.errors.username} />
          ) : null}
        </div>
        <div className="text-black my-4">
          <label htmlFor="pasword_id">{t("password")}</label>
          <span className="items-center space-x-2 p-0.5 mt-0.5  rounded-lg flex relative">
            <Input
              name="password"
              placeholder="Введите пароль"
              className="input-field pr-10"
              onChange={formik.handleChange}
              value={formik.values.password}
              type={seePasswordIcon ? "password" : "text"}
              icon={<LockIcon className="text-iconColor" />}
            ></Input>

            <span className="absolute right-3">
              {seePasswordIcon ? (
                <VisibilityOffIcon
                  className="text-iconColor"
                  onClick={() => setSeePassword(false)}
                />
              ) : (
                <VisibilityIcon
                  className="text-iconColor"
                  onClick={() => setSeePassword(true)}
                />
              )}
            </span>
          </span>
          {formik.touched.password && formik.errors.password ? (
            <InputError msg={formik.errors.password} />
          ) : null}
        </div>
        <div className="text-grey -ml-3 flex justify-between">
          <div>
            <Checkbox
              className="m-0"
              color="primary"
              onClick={() => setRememberMe(!rememberMe)}
            />{" "}
            Запомнить меня
          </div>
          <a
            onClick={() => {
              history.push("/auth/reset-password");
            }}
          >
            <span className="text-blue-500 underline">Забыли пароль?</span>
          </a>
        </div>
        <div className="py-5">
          <button
            disabled={false}
            className="w-full rounded-md bg-blue-500 p-3 text-white font-semibold active:bg-blue-700"
          >
            {t("panelBack")}
          </button>
        </div>

        {/* <button
          onClick={() => setActiveTab('registration')}
          className='w-full rounded-md border border-border p-3 text-primary font-semibold active:bg-blue-100'
        >
          {' '}
          {t('registration')}
        </button> */}
      </form>
      <ToastContainer />
    </div>
  );
}
