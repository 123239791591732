import { Box, Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import cls from './index.module.scss'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import axios from 'axios'
import { useParams , useHistory } from 'react-router-dom'
import SelectPaginationTable from './table'
import moment from 'moment'
import config from '../../config/defaultSettings'

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein }
}
const anotherAnswer = ['Другое', 'Башка', 'Басқа', 'Дигар', 'Boshqa', 'Other']
const resortById = (variant, answers) =>
  answers?.filter((el) => el === variant.id).length > 0

const matchColsToColumns = (column, list) => {
  // const callBack = (a, b) => a + b
  // const arrayList = (A, B) => A.reduce(callBack, 0) > B.reduce(callBack, 0) ? B : A

  // console.log(arrayList(column, list))
  const temp = []
  // console.log(column, list)
  column.forEach((col) => {
    list.forEach((ls) => {
      if (col === ls.id) temp.push(ls)
    })
  })
  return temp.map((el) => el.label).join(', ')
}
function getAnswerType(prop) {
  // console.log('prop', prop)
  switch (prop.type) {
    case 'radio':
      let answer = prop.answers?.filter((el) => el.id === prop.answer.answer)[0]
      if (answer && anotherAnswer.includes(answer.label)) {
        answer = prop.answer.text
      } else {
        answer = answer?.label?.ru || answer?.label || '-'
      }
      return {
        [prop.id]: answer,
      }

    case 'text':
      return {
        [prop.id]: prop.answer.text ? prop.answer.text : '-',
      }
    case 'number':
      return {
        [prop.id]: prop.answer.text ? prop.answer.text : '-',
      }

    case 'checkbox':
      return {
        [prop.id]: prop.answers.length
          ? prop.answers
              .filter((el) => resortById(el, prop.answer.answers))
              .map((el) => {
                if (el.is_open_value) {
                  return prop.answer.text || '-'
                }
                return el.label
              })
              .join(', ')
          : '-',
      }
    case 'info':
      return ''
    case 'date':
      return {
        [prop.id]: prop.answer.date
          ? moment(prop.answer.date).format('YYYY-MM-DD')
          : '-',
      }
    case 'matrix-single':
      let prods = {}
      // console.log(prop)
      prop.answer.matrix.forEach((p) => {
        prods = {
          ...prods,
          [p.row_id]: prop.columns?.filter((ans) => ans.id === p.column)[0]
            .label,
        }
      })
      // console.log('propper => ', prods)
      return {
        ...prods,
      }

    case 'matrix-multiple':
      let multiple = {}
      // console.log(prop)
      prop.answer.matrix.forEach((s) => {
        // console.log(s)
        multiple = {
          ...multiple,
          [s.row_id]: matchColsToColumns(s.columns, prop.columns),
        }
      })
      // console.log('propper => ', multiple)
      return {
        ...multiple,
      }

    default:
      return { [prop.id]: '-' }
  }
}
function getQuestions(questions) {
  let questionList = {}
  questions.forEach((el, i) => {
    questionList = {
      ...questionList,
      ...getAnswerType(el),
    }
  })
  // console.log('questionList', questionList)
  return questionList
}

const useStyles = makeStyles({
  table: {
    minWidth: '100%',
  },
})

export default function ResultQuestion() {
  const classes = useStyles()
  const params = useParams()
  const history = useHistory()
  const [reportList, setReportList] = useState()
  const [questionList, setQuestionList] = useState()
  const [rowData, setRowData] = useState(() => [])
  const [columnId, setColumnId] = useState()

  // console.log('REPORT', reportList)
  // console.log('QUESTION', questionList)

  const setRows = (list) => {
    // console.log('list', list)
    const questionLists = list
      .map((ls, i) => ({
        ...ls,
        ...getQuestions(ls.questions),
        key: Math.random() + '3dsfsd' + i,
      }))
      .filter((item) => item.questions.length)
    // console.log(questionList, list )
    setRowData(questionLists)
  }

  const getReports = () => {
    axios
      .post(
        `${config.managerBaseURL}v1/survey/${params.survey_id}/report?respondent_id=${params.respondent_id}`,
        {}
      )
      .then((res) => {
        setReportList(res.data.data)
        setRows(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const getQuestionList = () => {
    axios
      .get(
        `${config.managerBaseURL}v1/survey/${params.survey_id}/question`
      )
      .then((res) => {
        setQuestionList(res.data.questions)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  useEffect(() => {
    getReports()
    getQuestionList()
  }, [])

  useEffect(() => {
    setColumnId(
      questionList?.map((el) => ({
        ...el,
        Header: el.data.label.ru,
        accessor: el.id,
      }))
    )
  }, [questionList])
  return (
    <div className={cls.root}>
      <div className={cls.row}>
        <Box className={cls.box}>
          {columnId && (
            <SelectPaginationTable
              columns={columnId.filter((item) => item?.type !== 'info')}
              data={rowData}
              pageCount={1}
            />
          )}
        </Box>
        <Box className={cls.btnGroup}>
        <Button className={cls.btn} onClick={()=>{
        history.goBack()
      }}>Назад</Button>
        </Box>
      </div>
    </div>
  )
}
{
  /* <TableContainer component={Paper}>
            <Table className={classes.table} aria-label='customized table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Имя пользователя</StyledTableCell>
                  {questionList?.map((el, ind) => (
                    <StyledTableCell key={ind} align='center'>
                      {el?.data?.type !== 'info' && el?.data?.label?.ru}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow>
                  {reportList?.map((el, ind) => (
                    <>
                    <StyledTableCell key={ind} component='th' scope='row'>
                      {el.username}
                    </StyledTableCell>
                   
                    </>
                  ))}
                  {/* {rowData?.map((el, ind) => (
                    <StyledTableCell key={ind} align='center'>
                      {el?.data?.type !== 'info' &&
                        el?.data?.answers?.[0]?.label?.ru}
                    </StyledTableCell>
                  ))} */
}
{
  /* {rowData?.filter(item => item.questions.id ==)} */
}
//       </StyledTableRow>
//     </TableBody>
//   </Table>
// </TableContainer> */}
