import request from '../utils/axios'
const base_url = {
  researcher: '/researcher',
}

const requests = {
  getAllResearcher: (data) =>
    request({
      url: `${base_url.researcher}`,
      method: 'get',
      params: data,
    }),
}
export default requests
