// Dependency
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useMemo } from "react";
import SwipeableViews from "react-swipeable-views";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "../../utils/axios";

// Components
import Steps from "../../components/Steppers/Steps";
import Hearder from "../../components/Header/Header";
import Breadcrumbs from "../../components/Breadcrumb/index";
import StepperOneList from "../../components/StepperItem/StepperOneList";
import StepperTwoList from "../../components/StepperItem/StepperTwoList";
import StepperThreeList from "../../components/StepperItem/StepperThreeList";
import cls from "./InqueryCreate.module.scss";
import StepperFourthStep from "../../components/StepperItem/StepperFourthStep";

// Service models
import Attributs from "../../models/attributs";
import ResSearechs from "../../models/researchers";
import Inquery from "../../models/inquiry";
import CompanyInqueiry from "../../models/companyinquiry";
import Company from "../../models/companyinquiry";
import { toast } from "react-toastify";
import { Tooltip } from "antd";

// Functions
import transformToState from "../../functions/transformToState";
import StepperFive from "../../components/StepperItem/StepperFive";

const InquiryCreateList = () => {
  const { t } = useTranslation();
  const [imageUrl, setImageUrl] = useState({
    nda: "",
    example_nda: "",
  });
  const inquery_id = useSelector((old) => old?.inqueryReducer.id);

  const history = useHistory();
  const [checkDuration, setCheckDuration] = useState(30);
  const [additionalCost, setAdditionalCost] = useState(null);
  const [totalCostWithoutNds, setTotalCostWithoutNds] = useState();
  const [loader, setLoader] = useState(false);
  const [checkFirstStep, setCheckFirstStep] = useState({
    first: false,
    second: false,
    third: true,
    four: true,
  });
  const [files, setFiles] = useState([]);
  const [ndaFiles, setNdaFiles] = useState([]);
  const params = useParams();
  const [active, setActive] = useState(false);

  const makeObject = (arr) => {
    if (!arr.length) return { 1: "" };
    let linksObject = {};
    arr.forEach((elm, i) => {
      linksObject[i + 1] = elm?.file?.name;
    });
    return linksObject;
  };

  const [initialValue, setinitialValue] = useState({
    company_id: "",
    // one Steppers
    title: "",
    from_date: moment(new Date()),
    to_date: moment(new Date()).add(30, "d"),
    status_id: "72ee7350-4a2b-4fc2-945c-34faa56b13ca",
    partisipant_role: "respondent",
    study_type: "one_to_one",
    interview_type: "online",
    description: "",
    // two Stepppers
    researcher_id: "4d6c7a22-c652-4a82-afdb-07b5693350be",
    // data researchdelAll select
    duration: 30,
    number_of_partisipants: 0,
    type_of_inquiry: {
      group_participants: "1",
      number_of_groups: "1",
      type_of_inquiry: "usual",
    },
    region: {
      label: "Россия",
      value: "d244ae58-61c3-404a-a2fa-b1eea73039a6",
    },
    audience: {
      label: "По демографии",
      value: "bdefb0c2-a3b8-405f-904a-a7f2c85a3479",
    },
    is_supported: false,
    inquiry_info: {
      district: [],
      by_interest: [],
    },
    total_cost: 0,
    os: {},
    device: { 1: "smartphone" },
    cost: 0,
    additional_cost: 0,
    researcher_name: "",
    promo_code: "",
    promo_code_money: 0,
    nda_text: "",
    // thwee Steppers
    inquiry_requirement: [
      // {
      //     input_type: 'range',
      //     name: 'attribute',
      //     data_type: 'number',
      //     id: "",
      //     requirement_value:
      //        {
      //             attribute_id: "",
      //             id: "",
      //             value: ""
      //         }
      // }
    ],
    // four Steppers
    inquiry_timetables: [
      // {
      //     dates: "",
      //     end_time: "",
      //     number_of_applicants: 0,
      //     number_of_partisipants: 0,
      //     start_time: ""
      // }
    ],
    nda: { 1: "" },
    example_nda: { 1: "" },
    connection_info: "",
  });

  useEffect(() => {
    const firstNda = makeObject(files);
    setinitialValue((old) => ({ ...old, nda: firstNda }));
  }, [files]);
  useEffect(() => {
    const secondNda = makeObject(ndaFiles);
    setinitialValue((old) => ({ ...old, example_nda: secondNda }));
  }, [ndaFiles]);

  const [attributList, setattirbutList] = useState([]);
  const [rendertype, setrendertype] = useState([]);
  const [attributeValue, setAttributeValue] = useState([]);
  const [interests, setInterests] = useState([]);
  const [next, setNext] = useState(false);
  const gender = initialValue?.inquiry_requirement.filter(
    (item) => item?.name === "Пол"
  );

  const [partisipantRoles, setPartisipantRoles] = useState([
    // {
    //   label: t("respondent"),
    //   value: "respondent",
    // },
    // {
    //   label: t("Профессионал"),
    //   value: "professional",
    // },
    // {
    //   label: t("consultant"),
    //   value: "consultant",
    // },
    {
      label: (
        <>
          <Tooltip
            title={
              <h1 style={{ fontSize: "14px", color: "#fff" }}>{t("low")}</h1>
            }
            placement="right"
          >
            {t("Низкий уровень сложности")}
          </Tooltip>
        </>
      ),
      value: "respondent",
    },
    {
      label: (
        <>
          <Tooltip
            title={
              <h1 style={{ fontSize: "14px", color: "#fff" }}>{t("medium")}</h1>
            }
            placement="right"
          >
            {t("Средний уровень сложности")}
          </Tooltip>
        </>
      ),
      value: "professional",
    },
    {
      label: (
        <>
          <Tooltip
            title={
              <h1 style={{ fontSize: "14px", color: "#fff" }}>{t("high")}</h1>
            }
            placement="right"
          >
            {t("Высокий уровень сложности")}
          </Tooltip>
        </>
      ),
      value: "consultant",
    },
  ]);

  const [studyTypes, setStudyTypes] = useState([
    {
      label: t("interview"),
      value: "one_to_one",
    },
    {
      label: t("unmoderated"),
      value: "unmoderated",
    },
    {
      label: "Фокус-руппа",
      value: "group",
    },
    {
      label: "Количественный опрос",
      value: "toloka",
    },
  ]);

  const [interviewTypes, setInterviewTypes] = useState([
    {
      label: t("online"),
      value: "online",
    },
    {
      label: t("offline"),
      value: "in_persion",
    },
  ]);

  const [operationSistems, setoperationSistems] = useState([
    {
      label: t("Windows"),
      value: "windows",
    },
    {
      label: t("Linux"),
      value: "linux",
    },
    {
      label: t("Macos"),
      value: "macos",
    },
    {
      label: t("Android"),
      value: "android",
    },
    {
      label: t("IOS"),
      value: "ios",
    },
  ]);
  const [type, setType] = useState([
    {
      label: "Обычный",
      value: "usual",
    },
    {
      label: "Фокус-группа",
      value: "group",
    },
  ]);

  const [devices, setdevices] = useState([
    {
      label: t("smartphone"),
      value: "smartphone",
    },
    {
      label: t("computer"),
      value: "computer",
    },
    {
      label: t("planshet"),
      value: "planshet",
    },
  ]);

  const [status, setstatus] = useState([
    {
      label: "Черновик",
      value: "72ee7350-4a2b-4fc2-945c-34faa56b13ca",
    },
    {
      label: "Активный",
      value: "7b75685f-ee20-4fb9-bc5f-7e40c115e708",
    },
    {
      label: "Завершено",
      value: "2e857fbe-9add-4eae-a8c4-fe57fb384347",
    },
    {
      label: "Отменено",
      value: "2a98c22e-cbee-49f9-90ef-f56429d96333",
    },
  ]);
  const [region, setRegion] = useState([
    {
      label: "Россия",
      value: "d244ae58-61c3-404a-a2fa-b1eea73039a6",
    },
    {
      label: "Белорусия",
      value: "d7a0af0b-e4c8-4927-b97a-c277b0718fe1",
    },
    {
      label: "Казахстан",
      value: "44eb9407-316c-4d2c-80c8-b337179af5a2",
    },
  ]);
  const [audience, setAudience] = useState([
    {
      label: "По демографии",
      value: "bdefb0c2-a3b8-405f-904a-a7f2c85a3479",
    },
    {
      label: "По интересам",
      value: "1a2eeeac-5722-4908-823c-ea8c0fe47e57",
    },
  ]);
  const [is_supported, setEscort] = useState([
    {
      label: "Да",
      value: true,
    },
    {
      label: "Нет",
      value: false,
    },
  ]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [researchersList, setresearchersList] = useState([]);
  const [renderCompanaets, setrenderCompanaets] = useState(false);
  const [arrayStringOptions, setArrayStringOptions] = useState([]);
  const [arrayNumberOptions, setArrayNumberOptions] = useState([]);
  const [checkAdditionalCost, setCheckAdditionalCost] = useState(false);
  const [totalAmount, setTotalAmount] = useState();
  const [companyId, setCompanyId] = useState(null);
  // const [isFilter, setisFliter] = useState(true);

  const getRegions = (param) => {
    CompanyInqueiry.AttributValue(param)
      .then((res) => {
        const newAttribtList = res.data.values?.map((element, index) => {
          return {
            label: element.value,
            value: element.id,
          };
        });
        setAttributeValue(newAttribtList);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getInterests = (param) => {
    CompanyInqueiry.AttributValue(param)
      .then((res) => {
        const newAttribtList = res.data.values?.map((element, index) => {
          return {
            label: element.value,
            value: element.id,
          };
        });
        setInterests(newAttribtList);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    axios
      .get(`researcher/${localStorage.getItem("user_id")}`)
      .then((res) => {
        setCompanyId(res.data.company_id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (params.activestep === "4") {
      setActiveStep(4);
    }
  }, []);

  const handleNext = (arg) => {
    setActiveStep((prevActiveStep) => arg ?? prevActiveStep + 1);
  };

  const handleBack = (arg) => {
    setActiveStep((prevActiveStep) => arg ?? prevActiveStep - 1);
  };
  const filterParticipant = (key) => {
    switch (key) {
      case "respondent":
        return 1;
      case "professional":
        return 2;
      default:
        return 3;
    }
  };

  const filterElement = (list, key) => {
    return parseInt(list.filter((el) => el.key === key)[0].value);
  };

  const getInqueryById = () => {
    if (!params.inquery_id) return;
    setLoader(true);
    Inquery.inquiryItemList(params.inquery_id).then((res) => {
      setImageUrl(res.data.nda);
      const a = Object.keys(res.data.nda).map((el) => ({
        file: { name: res.data.nda[el] },
      }));
      const b = Object.keys(res.data.example_nda).map((el) => ({
        file: { name: res.data.example_nda[el] },
      }));
      setFiles(a);
      setNdaFiles(b);
      transformToState(res.data, setinitialValue, request);
      setCheckAdditionalCost(true);
      setLoader(false);
    });
  };
  const getResearchesList = (params) => {
    ResSearechs.researcherGetAllList(params).then((res) => {
      if (!res.data.researchers) {
        toast.error("Добавьте нового исследователя в вашу компанию", {
          className: "bg-red-500 text-white",
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 2000,
        });
        history.push(`/home/company`);
      }

      const data = res.data.researchers?.map((element) => {
        return {
          label: element.name,
          value: element.id,
        };
      });

      setresearchersList(data);
      setrenderCompanaets(true);
    });
  };
  const [checkStatusAttribute, setCheckStatusAttribute] = useState(false);
  const getAttributList = () => {
    Attributs.getAttribut().then((res) => {
      const newAttribtList = res.data.attributes?.map((element, index) => {
        return {
          label: element.name,
          value: {
            name: element.name,
            data_type: element.data_type,
            input_type: element.input_type,
            id: element.id,
          },
        };
      });
      setattirbutList(newAttribtList);
      setCheckStatusAttribute(true);
    });
  };
  const routes = [
    {
      title: t("inqueries"),
      link: true,
      route: `/home/company/companyinquiry/${params.company_id}`,
    },
    // {
    //   title: t('Михайлов'),
    //   link: true,
    //   route: '/home/company',
    // },
    {
      title: initialValue.title.length > 1 ? initialValue.title : t("add"),
      link: true,
    },
  ];

  useEffect(() => {
    getRegions({
      requirement_attribute_id: "d244ae58-61c3-404a-a2fa-b1eea73039a6",
    });
    if (params.activestep === "4") {
      setActiveStep(4);
    }
  }, []);
  useEffect(() => {
    if (initialValue.study_type === "toloka") {
      setNext(true);
    } else {
      setNext(false);
    }
  }, [initialValue.study_type]);

  useEffect(() => {
    getAttributList();
    getResearchesList(params);
  }, []);

  useEffect(() => {
    getInqueryById();
  }, []);
  useEffect(() => {
    setinitialValue({
      ...initialValue,
      additional_cost: Number(additionalCost),
      number_of_partisipants: next ? 100 : 10,
    });
  }, [additionalCost, next]);
  const request = (params, type) => {
    return CompanyInqueiry.AttributValue(params)
      .then((res) => {
        if (type === "array_string") {
          setArrayStringOptions((el) => [
            ...el,
            ...res.data.values.map((e) => ({
              ...e,
              label: e.value,
              value: e.value,
            })),
          ]);
        }
        if (type === "array_number") {
          setArrayNumberOptions((el) => [
            ...el,
            ...res.data.values.map((e) => ({
              ...e,
              label: e.value,
              value: e.value,
            })),
          ]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleBlur(key, value) {
    setinitialValue((old) => ({ ...old, [key]: value }));
    const form = {
      ...initialValue,
      [key]: value,
      company_id: params.company_id,
    };
    if (params.inquery_id) {
      Inquery.updateInquiry(form)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
  }

  function changeBlur(key, key2, value) {
    setinitialValue((old) => ({
      ...old,
      [key]: { ...old[key], [key2]: value },
    }));
    const form = {
      ...initialValue,
      [key]: { ...initialValue[key], [key2]: value },
      company_id: params.company_id,
    };
    if (params.inquery_id) {
      Inquery.updateInquiry(form)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
  }

  const calculateAmount = (e) => {
    axios
      .get(
        `/inquiry/calculate_cost?company_id=${
          params.company_id
        }&partisipant_role=${initialValue.partisipant_role}&duration=${
          initialValue.duration
        }&number_of_partisipants=${
          initialValue.number_of_partisipants
        }&additional_cost=0&is_online=${
          initialValue.interview_type === "online" ? true : false
        }&study_type=${initialValue.study_type}&is_supported=${
          initialValue.is_supported === true ? true : false
        }&select_interest=${
          initialValue.audience?.value ===
          "1a2eeeac-5722-4908-823c-ea8c0fe47e57"
            ? true
            : false
        }&select_region=${
          initialValue?.region?.value ===
            "d244ae58-61c3-404a-a2fa-b1eea73039a6" &&
          initialValue?.inquiry_info?.district?.length < 15 &&
          initialValue?.inquiry_info?.district?.length !== 15
            ? true
            : false ||
              (initialValue?.region?.value ===
                "d7a0af0b-e4c8-4927-b97a-c277b0718fe1" &&
                initialValue?.inquiry_info?.district?.length < 6 &&
                initialValue?.inquiry_info?.district?.length !== 6)
            ? true
            : false ||
              (initialValue?.region?.value ===
                "44eb9407-316c-4d2c-80c8-b337179af5a2" &&
                initialValue?.inquiry_info?.district?.length < 14 &&
                initialValue?.inquiry_info?.district?.length !== 14)
            ? true
            : false
        }&select_gender=${
          gender[0]?.requirement_value?.length > 0 &&
          gender[0]?.requirement_value?.length !== 2
            ? true
            : false
        }${e?.promo === true ? `&promo=${e?.promo}` : `&promo=${false}`}`
      )
      .then((res) => {
        // console.log(res)
        setTotalAmount(res.data.total_cost);
        setTotalCostWithoutNds(res.data.total_cost_without_ndc);

        setinitialValue((old) => ({
          ...old,
          total_cost: Number(res.data.total_cost),
          cost: res.data.cost,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (checkAdditionalCost) {
      calculateAmount();
      setCheckAdditionalCost(false);
    }
  }, [checkAdditionalCost]);
  useEffect(() => {
    calculateAmount({
      promo: active ? true : false,
    });
  }, [
    initialValue.duration,
    initialValue.inquiry_info?.district,
    initialValue.region?.value,
    initialValue.partisipant_role,
    initialValue.interview_type,
    active,
  ]);

  const [disableTime, setDisableTime] = useState(false);
  useEffect(() => {
    {
      params.inquiry_id === false
        ? setDisableTime(false)
        : initialValue.status_id === "72ee7350-4a2b-4fc2-945c-34faa56b13ca"
        ? setDisableTime(false)
        : params.inquery_id && initialValue.inquiry_timetables.length !== 0
        ? setDisableTime(true)
        : initialValue.status_id === "7b75685f-ee20-4fb9-bc5f-7e40c115e708"
        ? setDisableTime(true)
        : setDisableTime(false);
    }
  }, [params.company_id, initialValue]);
  const permissions = useSelector((state) => state.auth.permissions);

  useEffect(() => {
    if (permissions.find((el) => el === "Менеджер")) {
      console.log("manager");
    } else {
      console.log("invest");
      setinitialValue((old) => ({
        ...old,
        researcher_id: localStorage.getItem("user_id"),
      }));
    }
  }, []);
  return (
    <div className="w-full">
      <div>
        <Hearder startAdornment={[<Breadcrumbs routes={routes} />]} />
        <main className={cls.root}>
          <div className={cls.row}>
            <div className={cls.step}>
              <Steps
                checkFirstStep={checkFirstStep}
                params={params}
                setActiveStep={setActiveStep}
                activeStep={activeStep}
                next={next}
              />
            </div>
            <SwipeableViews index={activeStep} disabled className="w-3/4">
              <StepperOneList
                loader={loader}
                setActiveStep={setActiveStep}
                handleBlur={handleBlur}
                checkFirstStep={checkFirstStep}
                setCheckFirstStep={setCheckFirstStep}
                calculateAmount={calculateAmount}
                status={status}
                activeStep={activeStep}
                handleNext={handleNext}
                handleBack={handleBack}
                partisipantRoles={partisipantRoles}
                studyTypes={studyTypes}
                interviewTypes={interviewTypes}
                setinitialValue={setinitialValue}
                initialValue={initialValue}
                type={type}
                changeBlur={changeBlur}
                setNext={setNext}
                next={next}
                active={active}
              />
              <StepperTwoList
                companyId={companyId}
                disableTime={disableTime}
                handleBlur={handleBlur}
                checkFirstStep={checkFirstStep}
                setCheckFirstStep={setCheckFirstStep}
                files={files}
                setFiles={setFiles}
                setNdaFiles={setNdaFiles}
                ndaFiles={ndaFiles}
                additionalCost={additionalCost}
                setAdditionalCost={setAdditionalCost}
                setImageUrl={setImageUrl}
                imageUrl={imageUrl}
                calculateAmount={calculateAmount}
                totalAmount={totalAmount}
                setCheckDuration={setCheckDuration}
                renderCompanaets={renderCompanaets}
                activeStep={activeStep}
                researchersList={researchersList}
                handleNext={handleNext}
                handleBack={handleBack}
                operationSistems={operationSistems}
                setinitialValue={setinitialValue}
                initialValue={initialValue}
                devices={devices}
                totalCostWithoutNds={totalCostWithoutNds}
                next={next}
                region={region}
                params={params}
                audience={audience}
                is_supported={is_supported}
                getRegions={getRegions}
                attributeValue={attributeValue}
                getInterests={getInterests}
                interests={interests}
                active={active}
                setActive={setActive}
              />
              <StepperThreeList
                checkFirstStep={checkFirstStep}
                setCheckFirstStep={setCheckFirstStep}
                handleNext={handleNext}
                rendertype={rendertype}
                setrendertype={setrendertype}
                setattirbutList={setattirbutList}
                attributList={attributList}
                className="w-full"
                params={params}
                handleBack={handleBack}
                activeStep={activeStep}
                request={request}
                setinitialValue={setinitialValue}
                initialValue={initialValue}
                arrayStringOptions={arrayStringOptions}
                arrayNumberOptions={arrayNumberOptions}
                setArrayStringOptions={setArrayStringOptions}
                setArrayNumberOptions={setArrayNumberOptions}
                checkStatusAttribute={checkStatusAttribute}
                next={next}
                calculateAmount={calculateAmount}
                totalCostWithoutNds={totalCostWithoutNds}
              />
              {next === false ? (
                <StepperFourthStep
                  getInqueryById={getInqueryById}
                  checkFirstStep={checkFirstStep}
                  setCheckFirstStep={setCheckFirstStep}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  initialValue={initialValue}
                  activeStep={activeStep}
                  inquiryId={params.inquery_id ? params.inquery_id : inquery_id}
                />
              ) : (
                <StepperFive
                  initialValue={initialValue}
                  activeStep={activeStep}
                  inquiryId={inquery_id}
                  next={next}
                />
              )}
              <StepperFive
                initialValue={initialValue}
                activeStep={activeStep}
                inquiryId={params.inquery_id ? params.inquery_id : inquery_id}
                next={next}
              />
            </SwipeableViews>
          </div>
        </main>
      </div>
    </div>
  );
};

export default InquiryCreateList;
