import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { QuestionIcon } from "../svg/Question";
import Cached from "@material-ui/icons/Cached";
import InfoIcon from "@material-ui/icons/Info";
import { Checkbox } from "@material-ui/core";
import { StyledTableCell } from "../DataTable";
import { StyledPropMenu } from "../../components/custom/StyledPropMenu";
import Tag from "../Tag";
import Pagination from "../Pagination";
import ChangeRating from "./ChangeRating";
import Rating from "../Rating";
import ChangeStatus from "./ChangeStatus";
import StarRateIcon from "@material-ui/icons/StarRate";
import axios from "../../utils/axios";
import { colors, status } from "../../mock/tags";
import { useParams, useHistory } from "react-router";
import ShowRatingRespondent from "./ShowRatingRespondent";
import SvgIcon from "../UploadWitchComment/Icons";
import { useSelector } from "react-redux";
import moment from "moment";
// import {useTransition} from "react-spring";

const TableIndex = ({
  dataSourse,
  setdataSourse,
  setGetInquiry,
  items,
  pageCount,
  pageLimit,
  setPageLimit,
  getId,
  getTabs,
  getRespondents,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();
  const [respondentId, setRespondentId] = useState();
  const [dropList, setDropList] = useState({ target: null, id: "" });
  const [open, setOpen] = useState(false);
  const [checkValue, setCheckValue] = useState(false);
  const [value, setValue] = useState({
    is_checked: 0,
    user_id: "",
  });
  const [showRating, setShowRating] = useState({
    count: null,
    data: [],
  });

  const communication = dropList?.data?.inquiry_rating?.communication;
  const experience = dropList?.data?.inquiry_rating?.experience;
  const punctuality = dropList?.data?.inquiry_rating?.punctuality;
  const statusId = dataSourse?.respondents?.map((item) => item?.status_id);

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const listRender = [
    {
      title: "Ответы",
      icon: (
        <QuestionIcon className="bg-blue-100 rounded text-blue-600 p-0.5" />
      ),
      type: "question",
    },
    {
      title: t("information"),
      icon: <InfoIcon style={{ color: "#11608b" }} />,
      type: "information",
    },
  ];
  const [column, setcolumn] = useState([
    t("number.icon"),
    t("Имя"),
    t("Phone"),
    t("apply_at"),
    t("email.name"),
    t("verify"),
    t("NDA"),
    // t('Рейтинг'),
    t("status"),
    "",
  ]);

  // const handleCheck = (e, id) => {
  //   setValue(() => ({
  //     is_checked: e.target.checked === true ? 1 : 0,
  //     user_id: id,
  //   }));
  //   setCheckValue(true);
  // };
  // const sendCost = (id) => {
  //   axios
  //     .put(`/respondent/is-checked`, {
  //       is_checked: value.is_checked,
  //       user_id: value.user_id,
  //     })
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   if (checkValue) {
  //     sendCost();
  //     setCheckValue(false);
  //   }
  // }, [checkValue]);

  const handleMoreClick = (id, type) => {
    // alert('Development on process...')
    // if (type === 'edit') {
    //   console.log('edit')
    //   setDropList((old) => {
    //     return { ...old, target: null }
    //   })
    // }
    if (type === "delete") {
      setDropList((old) => {
        return { ...old, target: null };
      });
    }
    if (type === "information") {
      history.push(`/extra/user/information/${id}`);
    }
  };

  return (
    <div>
      <StyledPropMenu
        anchorEl={dropList.target}
        open={Boolean(dropList.target)}
        onClose={(e) => {
          e.stopPropagation();
          setDropList((old) => {
            return { ...old, target: null };
          });
        }}
      >
        <div className="flex  flex-col divide-y font-body text-sm">
          <ChangeStatus
            getTabs={getTabs}
            getRespondents={getRespondents}
            setGetInquiry={setGetInquiry}
            element={dropList.data}
            t={t}
            setElement={setdataSourse}
            params={params}
            closeMenu={setDropList}
            Cached={Cached}
          />
          {/* {punctuality === 0 &&
          experience === 0 &&
          communication === 0 &&
          statusId.includes('2e857fbe-9add-4eae-a8c4-fe57fb384347') ? (
            <ChangeRating
              getRespondents={getRespondents}
              getTabs={getTabs}
              setGetInquiry={setGetInquiry}
              element={dropList.data}
              t={t}
              setElement={setdataSourse}
              params={params}
              closeMenu={setDropList}
              Cached={StarRateIcon}
            />
          ) : (
            ''
          )} */}
          {listRender.map((el, index) => (
            <div
              key={index}
              className="flex w-full cursor-pointer py-2"
              onClick={(e) => {
                if (el.type === "question") {
                  history.push(
                    `/home/question/${getId.survey_id}/${respondentId}`
                  );
                  setDropList({
                    ...dropList,
                    target: null,
                  });
                } else {
                  e.stopPropagation();
                  handleMoreClick(dropList.data.id, el.type);
                }
              }}
            >
              <div className="rounded">{el.icon}</div>
              <div className="my-auto mx-2">{el.title}</div>
            </div>
          ))}
        </div>
      </StyledPropMenu>
      <div className="fslex p-5 px-5 pb-4" style={{ borderRadius: "20px" }}>
        <TableContainer
          elevation={0}
          component={Paper}
          className=" shadow-none rounded-2xl p-5"
        >
          <Table
            elevation={0}
            aria-label="customized table"
            style={{ borderRadius: "20px" }}
          >
            <TableHead>
              <TableRow className="text-black font-body">
                {column?.map((element, index) => (
                  <StyledTableCell
                    id="respondent_count"
                    key={index}
                    style={{ textAlign: "center" }}
                  >
                    {element}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataSourse?.respondents?.map((element, index) => (
                <TableRow
                  style={{ borderBottom: "1px solid #ccc" }}
                  className="text-black font-body hover:bg-gray-100 cursor-pointer"
                  key={index}
                >
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {" "}
                    {parseInt(
                      pageCount * (pageLimit / pageCount - 1) + index + 1
                    )}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {element.name ? element.name : element.first_name}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {element.phone}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {moment(element.update_at)
                      .utcOffset(0, true)
                      .format("DD.MM.YYYY")}
                    <br />
                    <Tag
                      shape="subtle"
                      color="blue"
                      size="large"
                      style={{
                        width: "70px",
                        borderRaduis: "6px",
                        margin: "0 auto",
                        marginTop: "5px",
                      }}
                    >
                      {element.update_at.slice(11, 16)}
                    </Tag>
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {element.email}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ textAlign: "center" }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Checkbox
                      {...label}
                      color="primary"
                      defaultChecked={element.is_check === 1 ? true : false}
                      // onChange={(e) => {
                      //   handleCheck(e, element.id);
                      // }}
                      checkedIcon={<SvgIcon name="check" />}
                      icon={<SvgIcon name="notCheck" />}
                      style={{ pointerEvents: "none" }}
                    />
                  </StyledTableCell>

                  {element.nda !== null ? (
                    <StyledTableCell
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        border: "none",
                        textAlign: "center",
                      }}
                    >
                      {element.nda[1]
                        ? Object.keys(element.nda).map((el, i) => (
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={`${element.nda[el]}`}
                              className="truncate"
                              id="download_link"
                            >
                              {element.nda[el].split("+")?.[1]}
                            </a>
                          ))
                        : ""}
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell />
                  )}

                  {/* <StyledTableCell id='rating' style={{ textAlign: 'center' }}>
                    <div
                      onClick={() => {
                        setOpen(true)
                        setShowRating({
                          count: 1,
                          data: [element.rating],
                        })
                      }}
                    >
                      <Rating
                        count={`${
                          (element.rating.communication +
                            element.rating.experience +
                            element.rating.punctuality) /
                          3
                        }`}
                      />
                    </div>
                    <div
                      onClick={() => {
                        setOpen(true)
                        setShowRating({
                          count: 2,
                          data: [element.inquiry_rating],
                        })
                      }}
                      id='second_rating'
                    >
                      <Rating
                        count={`${
                          (element.inquiry_rating.communication +
                            element.inquiry_rating.experience +
                            element.inquiry_rating.punctuality) /
                          3
                        }`}
                      />
                    </div>
                  </StyledTableCell> */}
                  <StyledTableCell style={{ textAlign: "center" }}>
                    <Tag
                      shape="subtle"
                      color={colors(element.status_id)}
                      size="large"
                      style={{ width: "150px", margin: "0 auto" }}
                    >
                      {status(element.status_id)}
                    </Tag>
                  </StyledTableCell>

                  <StyledTableCell
                    className="w-2   border-gray-300"
                    style={{
                      borderLeft: "1px solid gainsboro",
                    }}
                  >
                    <MoreHorizIcon
                      onClick={(e) => {
                        setRespondentId(element.id);
                        e.stopPropagation();
                        setDropList({
                          target: e.currentTarget,
                          id: element.id,
                          data: element,
                        });
                      }}
                      className="cursor-pointer text-blue-500"
                      style={{
                        border: "1px solid gainsboro",
                        borderRadius: "5px",
                      }}
                    />
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="flex  flex-col divide-y font-body text-sm">
          {/* {showRating.count === 1 ? (
            <ShowRatingRespondent
              data={showRating}
              open={open}
              setOpen={setOpen}
            />
          ) : showRating.count === 2 ? (
            <ShowRatingRespondent
              data={showRating}
              open={open}
              setOpen={setOpen}
            />
          ) : (
            ""
          )} */}
        </div>
        <Pagination
          className="mt-5 text-black-700"
          size={"large"}
          count={items.count}
          pageCount={pageCount}
          onChange={(val) => {
            console.log("val =>", val);
            setPageLimit(val);
          }}
        />
      </div>
    </div>
  );
};

export default TableIndex;
