import { useState, useEffect, useMemo } from "react";
import axios from "../../utils/axios";
import moment from "moment";
import classes from "./test.module.scss";
import CheckIcon from "@material-ui/icons/Check";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { toast } from "react-toastify";
import config from "../../config/defaultSettings";

moment.locale("ru");

const startTime = 0;
const endTime = 24;

export default function StepperFourthStep({
  getInqueryById,
  activeStep,
  inquiryId,
  handleNext,
  initialValue,
  handleBack,
  checkFirstStep,
  setCheckFirstStep,
}) {
  const { t } = useTranslation();
  const [data, setData] = useState([[]]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const params = useParams();
  const [inquiryCount, setInquiryCount] = useState(10);
  const [numberOfPartisipants, setNumberOfPartisipants] = useState({
    now: 0,
    total: 0,
    exCount: 0,
  });
  const calendar = useMemo(() => {
    return moment().add(12.5, "hours");
  }, []);

  let hours = (new Date().getTimezoneOffset() / 60) * -1;
  useEffect(() => {
    if (activeStep >= 3) {
      if (
        numberOfPartisipants.total + numberOfPartisipants.exCount - 1 >=
        numberOfPartisipants.now
      ) {
        setCheckFirstStep({
          ...checkFirstStep,
          four: false,
        });
      } else {
        setCheckFirstStep({
          ...checkFirstStep,
          four: true,
        });
      }
    }
  }, [activeStep, numberOfPartisipants]);

  useEffect(() => {
    if (activeStep === 3) {
      getCalendar(inquiryId);
    }
  }, [activeStep, inquiryId]);

  const getCalendar = (id) => {
    setLoading(true);
    axios
      .get(`/inquiry/${id}`)
      .then((res) => {
        setNumberOfPartisipants({
          now: Number(res.data.inquiry_timetables.length),
          total: Number(res.data.number_of_partisipants),
          exCount: Number(res.data.additional_timetables),
        });
        formatDataForCalendar(res.data)
          .then((res) => {
            setTableHeaders(res.firstRow);
            setData(res.tableData);
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {});
  };
  useEffect(() => {
    setInquiryCount(
      initialValue.type_of_inquiry.type_of_inquiry === "group"
        ? numberOfPartisipants.total /
            Number(initialValue.type_of_inquiry.group_participants) -
            numberOfPartisipants.now
        : (numberOfPartisipants.total +
            numberOfPartisipants.exCount -
            numberOfPartisipants.now *
              Number(initialValue.type_of_inquiry.group_participants)) /
            Number(initialValue.type_of_inquiry.group_participants)
    );
  }, [numberOfPartisipants, activeStep]);
  const formatDataForCalendar = (res) => {
    return new Promise((resolve, reject) => {
      const tableData = [];
      const fromDate = new Date(res.from_date.split("T")[0]);
      const toDate = new Date(res.to_date.split("T")[0]);
      const xRange = (toDate - fromDate) / 86400000;
      const yRange = Math.trunc(((endTime - startTime) * 60) / res.duration);
      const beginDate = moment(res.from_date);
      const beginTime = moment().set("hour", startTime).set("minute", 0);
      let firstRow = [{ text: t("time") }];
      let defaultRow = [];

      for (let i = 0; i <= xRange; i++) {
        defaultRow.push({ text: "", date: beginDate.format("YYYY-MM-DD") });
        firstRow.push({
          text: beginDate.format("dd, DD MMM"),
          date: beginDate,
        });
        beginDate.add(1, "d");
      }
      // console.log(yRange, beginTime)
      for (let i = 0; i < yRange; i++) {
        const start = beginTime.format("HH:mm");
        const end = beginTime.add(res.duration, "m").format("HH:mm");
        tableData.push(
          [{ text: `${start} - ${end}` }].concat(
            defaultRow.map((el) => ({
              ...el,
              ...defineCellExist(res.inquiry_timetables, el, start, end),
              range: [start + ":00", end + ":00"],
              // id: new Date().getTime().toString() + Math.random().toString()
            }))
          )
        );
      }

      resolve({ firstRow, tableData });
    });
  };
  const defineCellExist = (inquiry_timetables, element, start, end) => {
    const item = inquiry_timetables.find((elm) => {
      const fromTime = moment(elm.start_time).subtract(0, "h").format("HH:mm");
      const toTime = moment(elm.end_time).subtract(0, "h").format("HH:mm");
      const date = moment(elm.dates).format("YYYY-MM-DD");
      return fromTime === start && toTime === end && element.date === date;
    });
    // console.log(item)
    return item ? { text: checkedElm, id: item.id } : { text: "" };
  };

  const onCellClick = (elm, index, index1) => {
    // console.log(elm, index, index1)
    if (elm.text) {
      setData((old) =>
        old.map((elm, i) =>
          i === index
            ? elm.map((el, j) =>
                index1 === j ? { ...el, text: loadingCell } : el
              )
            : elm
        )
      );
      axios
        .delete(`/inquiry/timetable/${elm.id}?inquiry_id=${inquiryId}`)
        .then((res) => {
          getInqueryById();
          setNumberOfPartisipants((old) => ({ ...old, now: old.now - 1 }));
        })
        .catch((err) => {})
        .finally(() => {
          setData((old) =>
            old.map((elm, i) =>
              i === index
                ? elm.map((el, j) =>
                    index1 === j ? { ...el, text: "", id: "" } : el
                  )
                : elm
            )
          );
        });
    } else {
      setData((old) =>
        old.map((elm, i) =>
          i === index
            ? elm.map((el, j) =>
                index1 === j ? { ...el, text: loadingCell } : el
              )
            : elm
        )
      );
      axios
        .post(`/inquiry/timetable?hour=${hours}`, {
          number_of_applicants: 0,
          number_of_partisipants: Number(
            initialValue.type_of_inquiry.group_participants
          ),
          inquiry_id: inquiryId,
          dates: elm.date,
          start_time: moment(elm.date + " " + elm.range[0])
            .subtract(hours, "h")
            .format("YYYY-MM-DD HH:mm:ss"),
          end_time: moment(elm.date + " " + elm.range[1])
            .subtract(hours, "h")
            .format("YYYY-MM-DD HH:mm:ss"),
        })
        .then((res) => {
          getInqueryById();
          setNumberOfPartisipants((old) => ({ ...old, now: old.now + 1 }));
          setData((old) =>
            old.map((elm, i) =>
              i === index
                ? elm.map((el, j) =>
                    index1 === j
                      ? { ...el, text: checkedElm, id: res.data.id }
                      : el
                  )
                : elm
            )
          );
        })
        .catch((err) => {
          toast.error(err.data.error, {
            className: "bg-red-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          });
          setData((old) =>
            old.map((elm, i) =>
              i === index
                ? elm.map((el, j) =>
                    index1 === j ? { ...el, text: "", id: "" } : el
                  )
                : elm
            )
          );
        });
    }
  };

  const checkedElm = (
    <div className={classes.checked}>
      <CheckIcon style={{ color: "rgba(0, 103, 244, 1)" }} />
    </div>
  );

  const loadingCell = (
    <div className={classes.checked} onClick={(e) => e.stopPropagation()}>
      <CircularProgress size={20} />
    </div>
  );
  const element = [];

  return (
    <div
      style={{ position: "relative" }}
      id="tables"
      className={`p-4 w-full ${classes["fourth-step"]}`}
    >
      {numberOfPartisipants.total + numberOfPartisipants.exCount >
        numberOfPartisipants.now &&
        (inquiryCount > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "10px",
            }}
          >
            <Typography
              style={{
                background:
                  " linear-gradient(0deg, rgba(247, 102, 89, 0.15), rgba(247, 102, 89, 0.15)), #FFFFFF",
                color: "#F76659",
                padding: "4px 20px",
                borderRadius: "6px",
              }}
            >
              {`Выберите время еще для ${inquiryCount} интервью`}
            </Typography>
          </div>
        ) : (
          ""
        ))}
      <div className={classes.calendar}>
        <table>
          <tr
            style={{
              position: "sticky",
              zIndex: "99999",
              background: "#e8e8e8",
              top: "-1px",
            }}
          >
            {tableHeaders.map(
              (el, i) => (
                el?.text.includes("сб") ? element.push(i) : "",
                el?.text.includes("вс") ? element.push(i) : "",
                i === 0 ? (
                  <th
                    style={{
                      position: "sticky",
                      zIndex: "9999",
                      background: element?.find((e) => e === i)
                        ? "rgb(177 177 177)"
                        : "#e8e8e8",
                      left: "-2px",
                      color: "#000",
                    }}
                    className={classes["time-range-head"]}
                  >
                    {el.text}
                  </th>
                ) : (
                  <th
                    style={{
                      marginLeft: "10px",
                      background: element?.find((e) => e === i)
                        ? "rgb(177 177 177)"
                        : "#e8e8e8",
                    }}
                  >
                    {el.text}
                  </th>
                )
              )
            )}
          </tr>
          {data.map((elm, index) => (
            <tr>
              {elm.map((el, index1) =>
                index1 === 0 ? (
                  <td
                    style={{
                      position: "sticky",
                      zIndex: "9999",
                      background: "#e8e8e8",
                      left: "-2px",
                      color: "#000",
                    }}
                    className={classes["time-range"]}
                  >
                    <div className={classes["table-cell"]}>{el.text}</div>
                  </td>
                ) : (
                  <td
                    style={
                      calendar.isAfter(moment(`${el.date} ${el.range[1]}`)) ===
                      true
                        ? {
                            marginLeft: "10px",
                            background: "rgba(132, 145, 154, 1)",
                          }
                        : {
                            marginLeft: "10px",
                          }
                    }
                    className="long"
                    onClick={() => onCellClick(el, index, index1)}
                  >
                    {el.text}
                  </td>
                )
              )}
            </tr>
          ))}
        </table>
      </div>
      {/* <button onClick={() => getCalendar(inquiryId)}>
        get calendar
        {loading ? <CircularProgress size={20} /> : <></>}
      </button> */}
      <div className="flex justify-end" style={{ gap: "16px" }}>
        <Button
          onClick={() => {
            if (!params.inquiry_id) {
              history.push(
                `/home/company/companyinquirycreate/${params.company_id}/edit/${inquiryId}/2`
              );
            } else {
              handleBack(2);
            }
          }}
          className="btn font-medium"
          style={{
            background: "#fff",
            color: "rgba(0, 103, 244, 1)",
            fontSize: "14px",
            lineHeight: "24px",
            borderRadius: "6px",
            border: "1px solid rgba(0, 103, 244, 1)",
            padding: "8px 58.5px",
            textTransform: "none",
          }}
        >
          Назад
        </Button>
        {initialValue.status_id === "72ee7350-4a2b-4fc2-945c-34faa56b13ca" && (
          <Button
            onClick={() => {
              handleNext();
            }}
            className="btn text-white font-medium"
            style={{
              background: "rgba(0, 103, 244, 1)",
              fontSize: "14px",
              lineHeight: "24px",
              padding: "8px 58.5px",
              borderRadius: "6px",
              letterSpacing: "-0.006em",
              color: "#fff",
              textTransform: "none",
            }}
          >
            {t("next")}
          </Button>
        )}
        <a
          href={`${config.canvasBaseURL}construct/editor/${initialValue.screener_project_id}/${initialValue.screener_survey_id}/${params.company_id}/${inquiryId}/1`}
          // target='_blank'
        >
          <Button
            className="btn text-white font-medium"
            style={{
              background: "rgba(0, 103, 244, 1)",
              fontSize: "14px",
              lineHeight: "24px",
              padding: "8px 19.5px",
              borderRadius: "6px",
              letterSpacing: "-0.006em",
              color: "#fff",
              textTransform: "none",
            }}

            // onClick={() =>
            //   router.push(`/home/company/companyinquiry/${params.company_id}`)
            // }
            // onClick={() => {
            //   handleNext()
            // }}
          >
            {t("Создать вопросы")}
          </Button>
        </a>
      </div>
    </div>
  );
}
//   initialValue.screener_project_id
// }/${initialValue.screener_survey_id}`
// window.open(urlGenerated, '_blank')
