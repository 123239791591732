import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { StyledTableCell } from "../DataTable";
import { StyledPropMenu } from "../custom/StyledPropMenu";
import Tag from "../Tag";
import ExcelFile from "../../models/excel";
import Pagination from "../Pagination";
import { useHistory } from "react-router";
import moment from "moment";
import { DownloadOutlined } from "@ant-design/icons";
const InfoTable = ({
  dataSourse,
  setdataSourse,
  setGetInquiry,
  items,
  toloka,
  pageCount,
  pageLimit,
  setPageLimit,
  getId,
  getRespondents,
  getTabs,
  clientInfoData,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [dropList, setDropList] = useState({ target: null, id: "" });

  const [column, setcolumn] = useState([
    t("number.icon"),
    t("Имя"),
    t("Дата"),
    t("Ответ"),
  ]);

  const handleMoreClick = (id, index) => {
    history.push(
      `/home/company/answers/${id}/${clientInfoData[0]?.pool_id}/${index + 1}`
    );
  };

  const getExcelFile = () => {
    const param = {
      pool_id: clientInfoData[0]?.pool_id,
    };
    ExcelFile.tolokaGetAllExcelFile(param)
      .then((res) => {
        window.location.href = res.data.url;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <StyledPropMenu
        anchorEl={dropList.target}
        open={Boolean(dropList.target)}
        onClose={(e) => {
          e.stopPropagation();
          setDropList((old) => {
            return { ...old, target: null };
          });
        }}
      >
        <div className="flex  flex-col divide-y font-body text-sm"></div>
      </StyledPropMenu>

      <div className="fslex p-5 px-5 pb-4" style={{ borderRadius: "20px" }}>
        <TableContainer
          elevation={0}
          component={Paper}
          className=" shadow-none rounded-2xl p-5"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginBottom: "16px",
              height: "40px",
            }}
          >
            <a
              onClick={() => {
                getExcelFile();
              }}
              id="download_file"
            >
              <DownloadOutlined />
            </a>
          </div>
          <Table
            elevation={0}
            aria-label="customized table"
            style={{ borderRadius: "20px" }}
          >
            <TableHead>
              <TableRow className="text-black font-body">
                {column?.map((element, index) => (
                  <StyledTableCell style={{ textAlign: "center" }} key={index}>
                    {element}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {toloka?.items?.map((element, index) => (
                <TableRow
                  style={{ borderBottom: "1px solid #ccc" }}
                  className="text-black font-body "
                  key={index}
                >
                  <StyledTableCell>
                    {" "}
                    {parseInt(
                      pageCount * (pageLimit / pageCount - 1) + index + 1
                    )}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    Пользователь{" "}
                    {parseInt(
                      pageCount * (pageLimit / pageCount - 1) + index + 1
                    )}
                  </StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {moment(element?.created)
                      .utcOffset(0, true)
                      .format("DD.MM.YYYY")}
                    <br />
                    <Tag
                      shape="subtle"
                      color="blue"
                      size="large"
                      style={{
                        width: "70px",
                        borderRaduis: "6px",
                        margin: "0 auto",
                        marginTop: "5px",
                      }}
                    >
                      {element?.created.slice(11, 16)}
                    </Tag>
                  </StyledTableCell>
                  <StyledTableCell>
                    <p
                      style={{
                        textAlign: "center",
                        color: "blue",
                        cursor: "pointer",
                        width: "100px",
                        margin: "0 auto",
                      }}
                      onClick={() => {
                        handleMoreClick(element?.user_id, index);
                      }}
                    >
                      Ответы
                    </p>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          className="mt-5 text-black-700"
          size={"large"}
          count={items.count}
          pageCount={pageCount}
          onChange={(val) => {
            setPageLimit(val);
          }}
        />
      </div>
    </div>
  );
};

export default InfoTable;
