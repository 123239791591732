import axios from "axios";
import config from "../../../src/config/defaultSettings";

export const GetGlobalVariables = async (id) => {
  const response = await axios.get(
    `${config.baseAppURL}variables?company_id=${id}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "platform-id": "7d4a4c38-dd84-4902-b744-0488b80a4c01",
      },
    }
  );
    // let result = response.data.global_variables
  //     let arr
  //   axios
  //     .get(`${config.baseAppURL}variables?company_id=${id}`, {
  //         headers : {
  //             Authorization: `Bearer ${localStorage.getItem("token")}`,
  //             "platform-id": "7d4a4c38-dd84-4902-b744-0488b80a4c01"
  //         }
  //     })
  //     .then((res) => {
  //      return res.data
  //         // console.log('reS', res)
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //     console.log('RESILT', arr)
  return response;
};
