export const status = (value) => {
  switch (value) {
    case '01ee5c38-5f56-4e72-9b5a-926e4adce26a':
      return 'В ожидании'
    case 'e86c9b5b-5c71-48c8-b61f-42eeb51e33c6':
      return 'Подтвержденный'
    case '2e857fbe-9add-4eae-a8c4-fe57fb384347':
      return 'Завершено'
    case '2a98c22e-cbee-49f9-90ef-f56429d96333':
      return 'Отменено'
    default:
      return '-'
  }
}

export const colors = (name) => {
  switch (name) {
    case '01ee5c38-5f56-4e72-9b5a-926e4adce26a':
      return 'blue'
    case 'e86c9b5b-5c71-48c8-b61f-42eeb51e33c6':
      return 'blue'
    case '2e857fbe-9add-4eae-a8c4-fe57fb384347':
      return 'blue'
    case '2a98c22e-cbee-49f9-90ef-f56429d96333':
      return 'blue'
    default:
      return '-'
  }
}

export const STATUSES = [
  {
    value: 'e86c9b5b-5c71-48c8-b61f-42eeb51e33c6',
    label: 'Подтвердить',
  },
  {
    value: '01ee5c38-5f56-4e72-9b5a-926e4adce26a',
    label: 'В ожидании',
  },
  {
    value: '2e857fbe-9add-4eae-a8c4-fe57fb384347',
    label: 'Завершено',
  },
  {
    value: '2a98c22e-cbee-49f9-90ef-f56429d96333',
    label: 'Отменить',
  },
]
