import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import logout from '../../models/logout'
import { authActionTypes } from '../../redux/actions/authActions/authActionTypes'
// import ProfileIcon from '../../assets/icons/profile.svg'

export default function Header({
  startAdornment = [],
  endAdornment = [],
  title,
}) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [open, setOpen] = React.useState(false)
  const userLogout = () => {
    // const token = localStorage.getItem('tokin')
    logout
      .logout()
      .then((res) => {
        if (res.code === 200) {
          localStorage.removeItem('token')
          dispatch({
            type: authActionTypes.AUTH_LOGIN,
            payload: {
              userLogin: '',
              phoneNumber: '',
              accessToken: '',
              refreshToken: '',
              permissions: '',
            },
          })
          history.push('/auth/login')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <div className='w-full bg-white flex justify-between'>
      <div className='p-4 flex items-center divide-x'>
        {title && title.length ? (
          <div
            className='text-lg leading-6 font-semibold pr-4'
            style={{ color: 'rgba(91, 104, 113, 1)' }}
          >
            {title}
          </div>
        ) : (
          <></>
        )}
        {startAdornment}
      </div>
      <div className='flex items-center divide-x'>
        {endAdornment && endAdornment.length ? (
          endAdornment.map((Button) => <div className='px-1'>{Button}</div>)
        ) : (
          <></>
        )}
        {/* <a
          onClick={() => {
            if (!open) {
              setOpen(true)
            } else {
              setOpen(false)
            }
          }}
          style={{
            border: '1px solid #000',
            borderRadius: '8px',
            minHeight: '39px',
            minWidth: '50px',
            borderColor: 'rgb(64, 148, 247)',
            marginRight: '10px',
          }}
          className={'flex justify-center p-1 cursor-pointer relative'}
        >
          <img src={ProfileIcon} alt='Profile' />
          {open && (
            <ul
              style={{
                bottom: '-44px',
                left: '-22px',
                borderRadius: '4px',
                background: '#fff',
                border: '1px solid transparent',
              }}
              className={'absolute'}
            >
              <a onClick={userLogout}>
                <li
                  style={{
                    padding: '8px 12px',
                    fontSize: '16px',
                    cursor: 'pointer',
                  }}
                >
                  Выход
                </li>
              </a>
            </ul>
          )}
        </a> */}
      </div>
    </div>
  )
}
