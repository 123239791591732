import React, { useState, useEffect } from "react";
import cls from "./test.module.scss";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { TimeIcon, Online } from "../svg/TimeIcon";
import { Charger, Calendar } from "../svg/Respondent";
import { useParams, useHistory } from "react-router-dom";
import axios from "../../utils/axios";
import { NumberFomat } from "../../functions/numberFormat";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Button } from "@material-ui/core";
import ShowBalance from "./ShowBalance";
import { toast } from "react-toastify";
import config from "../../config/defaultSettings";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      //   role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function StepperFive({
  activeStep,
  inquiryId,
  initialValue,
  next,
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const params = useParams();
  const history = useHistory();
  const [listInquery, setListInquery] = useState({});
  const [cost, setCost] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const toHour = (min = 0) => {
    var division = min / 60;
    var postfix = division == 1 ? "час" : "часа";
    var hours = Math.floor(division);
    var minutes = Math.round((division % 1) * 60);

    if (division < 1) {
      return `${minutes} минут`;
    } else if (Number.isInteger(division)) {
      return `${division} ${postfix}`;
    } else {
      return `${hours} ${postfix} ${minutes} минут`;
    }
  };

  const checkDevice = (text) => {
    switch (text) {
      case "smartphone":
        return "По телефону";
      case "computer":
        return "По компьютеру";
      case "planshet":
        return "По планшету";
      default:
        return t("smartphone");
    }
  };
  const checkOs = (text) => {
    switch (text) {
      case "windows":
        return "Windows";
      case "linux":
        return "Linux";
      case "ios":
        return "IOS";
      case "android":
        return "Android";
      case "macos":
        return "Macos";
      default:
        return "";
    }
  };
  const getInquiry = () => {
    axios
      .get(`/inquiry/${inquiryId ? inquiryId : params.inquery_id}`)
      .then((res) => {
        setListInquery(res.data);
        setRedirect(true);
        getCost(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCost = (props) => {
    const gender = props?.inquiry_requirement.filter(
      (item) => item?.name === "Пол"
    );
    axios
      .get(
        `/inquiry/calculate_cost?company_id=${
          params.company_id
        }&partisipant_role=${props.partisipant_role}&duration=${
          props.duration
        }&number_of_partisipants=${props.number_of_partisipants}&is_online=${
          props.interview_type === "online" ? true : false
        }&study_type=${props?.study_type}&is_supported=${
          props?.is_supported === true ? true : false
        }&select_interest=${
          props?.audience?.value === "1a2eeeac-5722-4908-823c-ea8c0fe47e57"
            ? true
            : false
        }&select_region=${
          props?.region?.value === "d244ae58-61c3-404a-a2fa-b1eea73039a6" &&
          props?.inquiry_info?.district?.length < 15 &&
          props?.inquiry_info?.district?.length !== 15
            ? true
            : false ||
              (props?.region?.value ===
                "d7a0af0b-e4c8-4927-b97a-c277b0718fe1" &&
                props?.inquiry_info?.district?.length < 6 &&
                props?.inquiry_info?.district?.length !== 6)
            ? true
            : false ||
              (props?.region?.value ===
                "44eb9407-316c-4d2c-80c8-b337179af5a2" &&
                props?.inquiry_info?.district?.length < 14 &&
                props?.inquiry_info?.district?.length !== 14)
            ? true
            : false
        }&select_gender=${
          gender[0]?.requirement_values?.length > 0 &&
          gender[0]?.requirement_values?.length !== 2
            ? true
            : false
        }`
      )
      .then((res) => {
        setCost(res.data.total_cost);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (inquiryId.length === 0) {
      console.log("undefined");
    } else {
      activeStep === 4 && getInquiry();
    }
    if (next && activeStep === 3) {
      getInquiry();
    }
  }, [activeStep]);
  useEffect(() => {
    if (activeStep === 4) {
      if (listInquery?.status_id === "7b75685f-ee20-4fb9-bc5f-7e40c115e708") {
        history.push(`/home/dashboard/edit/${params.company_id}`);
      }
    }
  }, [redirect]);

  const request = () => {
    axios
      .get(
        `${config.managerBaseURL}v1/survey/${listInquery.screener_survey_id}/all`
      )
      .then((res) => {
        if (res.questions.length === 0) {
          toast.error("Создайте скринер к запросу", {
            className: "bg-red-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          });
          setOpen(false);
        } else {
          setOpen(true);
        }
      })
      .catch((err) => {
        toast.error("Произошла ошибка!", {
          className: "bg-red-500 text-white",
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        });
        console.log(err);
      });
  };

  const CheckQuestion = () => {
    if (
      initialValue.title.length > 0 &&
      initialValue.connection_info.length > 0 &&
      initialValue.inquiry_requirement[1].requirement_value[0]
    ) {
      request();
    } else {
      toast.error("Заполните все необходимые поля!", {
        className: "bg-red-500 text-white",
        closeOnClick: true,
        ideProgressBar: false,
        autoClose: 4000,
      });
    }
  };
  const CheckQuestionWithToloka = () => {
    if (
      initialValue.title.length > 0 &&
      initialValue.inquiry_requirement[1]?.requirement_value[0]
    ) {
      request();
    } else {
      toast.error("Заполните все необходимые поля!", {
        className: "bg-red-500 text-white",
        closeOnClick: true,
        ideProgressBar: false,
        autoClose: 4000,
      });
    }
  };
  const WithIdAndTolokaCheckQuestion = () => {
    if (
      initialValue.title.length > 0 &&
      initialValue.inquiry_requirement[0]?.requirement_values[0] &&
      initialValue.inquiry_requirement[1]?.requirement_values[0]
    ) {
      request();
    } else {
      toast.error("Заполните все необходимые поля!", {
        className: "bg-red-500 text-white",
        closeOnClick: true,
        ideProgressBar: false,
        autoClose: 4000,
      });
    }
  };
  const WithIdCheckQuestion = () => {
    if (
      initialValue.title.length > 0 &&
      initialValue.connection_info.length > 0 &&
      initialValue.inquiry_requirement[0].requirement_values[0] &&
      initialValue.inquiry_requirement[1]?.requirement_values?.[0]
    ) {
      request();
    } else {
      toast.error("Заполните все необходимые поля!", {
        className: "bg-red-500 text-white",
        closeOnClick: true,
        ideProgressBar: false,
        autoClose: 4000,
      });
    }
  };
  return (
    <div className={cls.root}>
      <div className={cls.row}>
        <Box className={cls.box}>
          <AppBar id="tab" className={cls.tab} position="static">
            <Tabs
              className={cls.tabBox}
              value={value}
              onChange={handleChange}
              //   aria-label='simple tabs example'
            >
              <Tab
                className={`${cls.tabList} ${value === 0 && cls.active}`}
                label="Респондент"
                {...a11yProps(0)}
              />
              <a
                href={`${config.canvasBaseURL}construct/editor/${
                  initialValue.screener_project_id
                }/${initialValue.screener_survey_id}/${
                  params.company_id
                }/${inquiryId}/1${next ? "&toloka=true" : null}`}
              >
                <Tab
                  className={`${cls.tabList} ${value === 1 && cls.active}`}
                  label={next ? "Вопросы" : "Скринер"}
                  {...a11yProps(1)}
                />
              </a>
            </Tabs>
          </AppBar>
          <Box className={cls.container}>
            <TabPanel className={cls.tabPanel} value={value} index={0}>
              <Box className={cls.panel}>
                <div className={cls.header}>
                  <Typography className={cls.title}>
                    {listInquery?.title}
                  </Typography>
                  {/* <Typography className={cls.price}>
                    {' '}
                    {NumberFomat(cost)} ₽
                  </Typography> */}
                </div>
                <div className={cls.time}>
                  <div className={cls.left}>
                    <Typography
                      style={{ marginRight: "16px" }}
                      className={cls.timeList}
                    >
                      {t(`${listInquery?.study_type}`)}
                    </Typography>
                    {!next && (
                      <Typography className={cls.timeList}>
                        {t(`${listInquery?.interview_type}`)}
                      </Typography>
                    )}
                  </div>
                  {!next && (
                    <div className={cls.right}>
                      <Typography className={cls.itemList}>
                        <TimeIcon />{" "}
                        <span>{toHour(listInquery?.duration)}</span>
                      </Typography>
                    </div>
                  )}
                </div>
                <div className={cls.hr} />
                <div className={cls.description}>
                  <Typography className={cls.text}>
                    {listInquery?.description}
                  </Typography>
                </div>
                <div className={cls.hr} />
                <div className={cls.respondent}>
                  <Typography className={cls.title}>
                    Требования к респонденту
                  </Typography>
                  {listInquery?.inquiry_requirement?.map((el, ind) => (
                    <div key={ind} className={cls.details}>
                      {el?.requirement_values.find(
                        (item) =>
                          item?.requirement_attribute_id ===
                          "bf377060-1577-4797-a1b7-a29f59ad2c5d"
                      )?.value === "undefined/undefined" ? (
                        ""
                      ) : (
                        <>
                          <div className={cls.left}>
                            <Typography className={cls.name}>
                              {el.name}
                            </Typography>
                          </div>
                          <div className={cls.right}>
                            {el?.requirement_values.map((element) => (
                              <Typography className={cls.answer}>
                                {element?.requirement_attribute_id ===
                                "bf377060-1577-4797-a1b7-a29f59ad2c5d"
                                  ? `${element?.value.split("/").join("-")}`
                                  : element?.value}
                              </Typography>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                  {!next ? (
                    <>
                      <div className={cls.details}>
                        <div className={cls.left}>
                          <Typography className={cls.name}>
                            Устройства
                          </Typography>
                        </div>
                        <div className={cls.right}>
                          <Typography className={cls.answer}>
                            {checkDevice(listInquery?.device?.[1])}
                          </Typography>
                          {listInquery?.device?.[2] && (
                            <Typography className={cls.answer}>
                              {checkDevice(listInquery?.device?.[2])}
                            </Typography>
                          )}
                          {listInquery?.device?.[3] && (
                            <Typography className={cls.answer}>
                              {checkDevice(listInquery?.device?.[3])}
                            </Typography>
                          )}
                        </div>
                      </div>
                      <div className={cls.details}>
                        <div className={cls.left}>
                          <Typography className={cls.name}>
                            Операционные системы
                          </Typography>
                        </div>
                        <div className={cls.right}>
                          <Typography className={cls.answer}>
                            {checkOs(listInquery?.os?.[1])}
                          </Typography>
                          {listInquery?.os?.[2] && (
                            <Typography className={cls.answer}>
                              {checkOs(listInquery?.os?.[2])}
                            </Typography>
                          )}
                          {listInquery?.os?.[3] && (
                            <Typography className={cls.answer}>
                              {checkOs(listInquery?.os?.[3])}
                            </Typography>
                          )}
                          {listInquery?.os?.[4] && (
                            <Typography className={cls.answer}>
                              {checkOs(listInquery?.os?.[4])}
                            </Typography>
                          )}
                          {listInquery?.os?.[5] && (
                            <Typography className={cls.answer}>
                              {checkOs(listInquery?.os?.[5])}
                            </Typography>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={cls.details}>
                        <div className={cls.left}>
                          <Typography className={cls.name}>Регион</Typography>
                        </div>
                        <div className={cls.right}>
                          {listInquery?.region?.label}
                        </div>
                      </div>
                      <div className={cls.details}>
                        <div className={cls.left}>
                          <Typography className={cls.name}>
                            Опрос узкой аудитории
                          </Typography>
                        </div>
                        <div className={cls.right}>
                          {listInquery?.audience?.value ===
                          "1a2eeeac-5722-4908-823c-ea8c0fe47e57"
                            ? "По интересам"
                            : "По демографии"}
                        </div>
                      </div>
                      <div className={cls.details}>
                        <div className={cls.left}>
                          <Typography className={cls.name}>
                            Количество респондентов
                          </Typography>
                        </div>
                        <div className={cls.right}>
                          {listInquery?.number_of_partisipants}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {/* connecting*/}
                <div className={cls.footer}>
                  {!next ? (
                    <div className={cls.connect}>
                      <div className={cls.icon}>
                        <Charger />
                      </div>
                      <div className={cls.content}>
                        <Typography className={cls.typeConnect}>
                          СПОСОБ ПОДКЛЮЧЕНИЯ
                        </Typography>
                        <div className="flex flex-col">
                          {initialValue.status_id ===
                            "2e857fbe-9add-4eae-a8c4-fe57fb384347" ||
                          initialValue.status_id ===
                            "7b75685f-ee20-4fb9-bc5f-7e40c115e708" ? (
                            <>
                              {listInquery?.connection_info?.slice(0, 5) ===
                              "https" ? (
                                <span style={{ fontSize: "13px" }}>
                                  <a
                                    href={listInquery?.connection_info}
                                    style={{
                                      color: "#2463eb",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    target="_blank"
                                  >
                                    {listInquery?.connection_info}{" "}
                                  </a>
                                </span>
                              ) : (
                                <span
                                  style={{ color: "black", fontSize: "13px" }}
                                >
                                  {listInquery?.connection_info}
                                </span>
                              )}
                            </>
                          ) : (
                            <span style={{ fontSize: "13px" }}>
                              {t(listInquery?.interview_type)}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={cls.connect}>
                      <div className={cls.icon}>
                        <Online />
                      </div>
                      <div className={cls.content}>
                        <Typography className={cls.typeConnect}>
                          ОНЛАЙН ОПРОС
                        </Typography>
                        <div className="flex flex-col">
                          {initialValue.status_id ===
                            "2e857fbe-9add-4eae-a8c4-fe57fb384347" ||
                          initialValue.status_id ===
                            "7b75685f-ee20-4fb9-bc5f-7e40c115e708" ? (
                            <>
                              {listInquery?.connection_info?.slice(0, 5) ===
                              "https" ? (
                                <span style={{ fontSize: "13px" }}>
                                  <a
                                    href={listInquery?.connection_info}
                                    style={{
                                      color: "#2463eb",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    target="_blank"
                                  >
                                    {listInquery?.connection_info}{" "}
                                  </a>
                                </span>
                              ) : (
                                <span
                                  style={{ color: "black", fontSize: "13px" }}
                                >
                                  {listInquery?.connection_info}
                                </span>
                              )}
                            </>
                          ) : (
                            <span style={{ fontSize: "13px" }}>
                              {t(listInquery?.interview_type)}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/*  */}
                  <div className={cls.connect}>
                    <div className={cls.icon}>
                      <Calendar />
                    </div>
                    <div className={cls.content}>
                      <Typography className={cls.typeConnect}>
                        ДАТА ПРОВЕДЕНИЯ
                      </Typography>
                      {next ? (
                        <Typography className={cls.text}>
                          {moment(listInquery?.from_date).format("YYYY-MM-DD")}
                        </Typography>
                      ) : (
                        <Typography className={cls.text}>
                          {moment(listInquery?.from_date).format("YYYY-MM-DD")}/
                          {moment(listInquery?.to_date).format("YYYY-MM-DD")}
                        </Typography>
                      )}
                    </div>
                  </div>
                </div>
              </Box>
              <div className={cls.btnGroup}>
                {initialValue.status_id ===
                "7b75685f-ee20-4fb9-bc5f-7e40c115e708" ? (
                  <>
                    <Button
                      onClick={() => {
                        history.push(
                          `/home/dashboard/edit/${params.company_id}`
                        );
                      }}
                      className={cls.btnSecond}
                    >
                      Сохранить
                    </Button>
                  </>
                ) : (
                  <>
                    {next && (
                      <a
                        href={`${config.canvasBaseURL}construct/editor/${
                          initialValue.screener_project_id
                        }/${initialValue.screener_survey_id}/${
                          params.company_id
                        }/${inquiryId}/0${next ? "&toloka=true" : null}`}
                        // target='_blank'
                      >
                        <Button
                          className={cls.btnThird}

                          // onClick={() =>
                          //   router.push(`/home/company/companyinquiry/${params.company_id}`)
                          // }
                          // onClick={() => {
                          //   handleNext()
                          // }}
                        >
                          {t("Создать вопросы")}
                        </Button>
                      </a>
                    )}
                    <Button
                      onClick={() => {
                        history.push(`/home/research`);
                      }}
                      className={cls.btn}
                    >
                      Сохранить как черновик
                    </Button>
                    {initialValue?.is_supported === false ? (
                      next ? (
                        <Button
                          onClick={() => {
                            params.inquery_id
                              ? WithIdAndTolokaCheckQuestion()
                              : CheckQuestionWithToloka();
                          }}
                          className={cls.btnSecond}
                        >
                          Активировать
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            params.inquery_id
                              ? WithIdCheckQuestion()
                              : CheckQuestion();
                          }}
                          className={cls.btnSecond}
                        >
                          Активировать
                        </Button>
                      )
                    ) : null}
                  </>
                )}
              </div>
            </TabPanel>

            <TabPanel value={value} index={1}>
              Вопросы
            </TabPanel>
          </Box>
        </Box>
      </div>
      {open && (
        <ShowBalance
          open={open}
          setOpen={setOpen}
          list={listInquery}
          inquiryId={inquiryId}
          next={next}
        />
      )}
    </div>
  );
}
