import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import { StyledTableCell, StyledTableRow } from "../StyledTableCell/index";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { StyledPropMenu } from "../../components/custom/StyledPropMenu";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import { useHistory } from "react-router-dom";
import Pagination from "../../components/Pagination";
import Button from "../../components/Buttons/index";
import { toast } from "react-toastify";
import Researcher from "../../models/team";
import cls from "./index.module.scss";
import { DownloadOutlined } from "@ant-design/icons";
import ExcelFile from "../../models/excel";
import { useSelector } from "react-redux";

const TeamTable = () => {
  const permissions = useSelector((state) => state.auth.permissions);
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const [companyList, setcompanyList] = useState([]);
  const [dropList, setDropList] = useState({ target: null, id: "" });
  const [tableheadername, setTableheadername] = useState([
    t("№"),
    t("Ф.И.О"),
    t("Электронная почта"),
    t("Номер телефона"),
    t("Роль в системе"),
    t("Роль в команде"),
  ]);
  const listRender = [
    {
      title: t("edit"),
      icon: <EditIcon className="bg-blue-100 rounded text-blue-600 p-0.5" />,
      type: "edit",
    },
    // {
    //   title: t('delete'),
    //   icon: <ClearIcon className='bg-red-100 rounded text-red-600 p-0.5' />,
    //   type: 'delete',
    // },
  ];

  const routes = [
    {
      title: t("Команда"),
      link: true,
      route: "/home/dashboard",
    },
  ];
  const [items, setItems] = useState({});
  const [createcompany, setcreatecompany] = useState(true);
  const pageCount = 10;
  const [pageLimit, setPageLimit] = useState(pageCount);
  const handleMoreClick = (id, type) => {
    if (type === "edit") {
      history.push(`${"/home/dashboard/edit"}/${id}`);
      setDropList((old) => {
        return { ...old, target: null };
      });
    }
  };
  const cuttomRowClick = (id) => {
    history.push(`${"/home/dashboard/edit"}/${id}`);
  };
  const getResearchers = (params) => {
    setloading(true);
    const param = {
      page: pageLimit / pageCount,
      limit: pageCount,
    };
    Researcher.getAllResearcher(param).then((res) => {
      console.log(res);
      setItems(res.data);
      setcompanyList(res.data.researchers);
      setloading(false);
    });
  };
  // Excel Download
  const getExcelFile = () => {
    ExcelFile.researcherGetAllExcelFile()
      .then((res) => {
        window.location.href = res.data.url;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getResearchers();
  }, []);
  useEffect(() => {
    getResearchers();
  }, [pageLimit]);

  return (
    <div className={cls.root}>
      <div className={cls.row}>
        <TableContainer
          elevation={0}
          component={Paper}
          className=" shadow-none rounded-2xl p-5"
        >
          {permissions.find((el) => el === "Отчеты") &&
            <div
            style={{
              position: "sticky",
              right: "0",
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginBottom: "16px",
            }}
          >
            <a
              style={{ padding: "8px 12px" }}
              onClick={() => {
                getExcelFile();
              }}
              id="download_file"
            >
              <DownloadOutlined />
            </a>
          </div>}
          <Table
            elevation={0}
            aria-label="customized table"
            style={{ borderRadius: "20px" }}
          >
            <TableHead>
              <TableRow className="text-black font-body">
                {tableheadername.map((element, index) => (
                  <StyledTableCell key={index}>{element}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {companyList?.map((element, index) => (
                <TableRow
                  className="text-black font-body hover:bg-gray-100 cursor-pointer"
                  key={index}
                  onClick={() => cuttomRowClick(element.id)}
                >
                  <StyledTableCell>
                    {parseInt(
                      pageCount * (pageLimit / pageCount - 1) + index + 1
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{element.name}</StyledTableCell>
                  <StyledTableCell>{element.email}</StyledTableCell>
                  <StyledTableCell>{element.phone}</StyledTableCell>
                  <StyledTableCell>{element.profession_title}</StyledTableCell>
                  <StyledTableCell>{element.role.name}</StyledTableCell>
                  {/* <StyledTableCell
                    className='w-2   border-gray-300'
                    style={{ borderLeft: '1px solid gainsboro' }}
                  >
                    <MoreHorizIcon
                      onClick={(e) => {
                        e.stopPropagation()
                        setDropList({
                          target: e.currentTarget,
                          id: element.id,
                        })
                      }}
                      className='cursor-pointer text-blue-500'
                      style={{
                        border: '1px solid gainsboro',
                        borderRadius: '5px',
                      }}
                    />

                    <StyledPropMenu
                      anchorEl={dropList.target}
                      open={Boolean(dropList.target)}
                      onClose={(e) => {
                        e.stopPropagation()
                        setDropList((old) => {
                          return { ...old, target: null }
                        })
                      }}
                    >
                      <div className='flex  flex-col divide-y font-body text-sm'>
                        {listRender.map((el, index) => (
                          <div
                            key={index}
                            className='flex w-full cursor-pointer py-2 mx-2'
                            onClick={(e) => {
                              e.stopPropagation()
                              handleMoreClick(element.id, el.type)
                            }}
                          >
                            <div className='rounded'>{el.icon}</div>
                            <div className='my-auto mx-2'>{el.title}</div>
                          </div>
                        ))}
                      </div>
                    </StyledPropMenu>
                  </StyledTableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/*<TableLoader isVisible={loading}/>*/}

        <Pagination
          className="mt-5 text-black-700"
          size={"large"}
          count={items.count}
          pageCount={pageCount}
          onChange={(val) => {
            console.log(val);
            setPageLimit(val);
          }}
        />
      </div>
      {/*<Table list={companyList} Tableheadername={Tableheadername}></Table>*/}
    </div>
  );
};

export default TeamTable;
