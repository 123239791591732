import { InqueryIdAcctionsTypes } from '../actions/inquery/InqueryIdActionTypes'

const initialState = {
id:''
}

const inqueryReducer = (state = initialState, action) => {
  const { payload } = action
  switch (action.type) {
    case InqueryIdAcctionsTypes.SET_INQUIRY_ID:
      return {
        ...state,
        id: payload,
      }
  
    default:
      return state
  }
}

export default inqueryReducer
