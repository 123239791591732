import { Box, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import cls from './index.module.scss'
import { useParams, useHistory } from 'react-router-dom'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import axios from '../../utils/axios'

export default function TransactionsCheckList() {
  const { t } = useTranslation()
  const { id } = useParams()
  const history = useHistory()
  const [list, setList] = useState()
  useEffect(() => {
    axios
      .get(`/billing/transaction/{transaction_id}?transaction_id=${id}`)
      .then((res) => {
        setList(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [id])
  console.log('list', history)
  return (
    <div className={cls.root}>
      <div className={cls.row}>
        <Box className={cls.box}>
          <div className={cls.header}>
            <Typography className={cls.title}>
              Чек №{list?.transaction_number}
            </Typography>
            <div className={cls.date}>
              <Typography className={cls.day}>
                {' '}
                {moment(list?.created_at).format('DD.MM.YYYY')}{' '}
              </Typography>
              <Typography className={cls.time}>
                {' '}
                {moment(list?.created_at).format('h:mm:ss')}{' '}
              </Typography>
            </div>
            <div className={cls.hr} />
            <div className={cls.body}>
              {/* Client Name */}
              <div className={cls.username}>
                <Typography className={cls.clientName}>Имя</Typography>
                <Typography className={cls.clientName}>
                  {list?.object_info?.from}
                </Typography>
              </div>
              <div className={cls.hr} />
              {/* Transaction */}
              <div className={cls.transaction}>
                <div className={cls.transactionName}>
                  <Typography className={cls.list}>
                    {list?.object_info?.info}
                  </Typography>
                </div>
                <div className={cls.amount}>
                  <Typography className={cls.listAmount}>
                    {parseInt(list?.credit > 0 ? list?.credit : list?.debit)} ₽
                  </Typography>
                </div>
              </div>
              <div className={cls.hr} />
              <div className={cls.statusRow}>
                <Typography className={cls.text}>Статус</Typography>
                <div
                  className={cls.status}
                  style={{
                    background: `${
                      list?.object_info?.status == 'refilled'
                        ? 'linear-gradient(0deg, rgba(64, 148, 247, 0.15), rgba(64, 148, 247, 0.15)), #FFFFFF'
                        : list?.object_info?.status == 'recieved'
                        ? 'linear-gradient(0deg, rgba(64, 148, 247, 0.15), rgba(64, 148, 247, 0.15)), #FFFFFF'
                        : list?.object_info?.status == 'purchased'
                        ? 'linear-gradient(0deg, rgba(248, 221, 78, 0.3), rgba(248, 221, 78, 0.3)), #FFFFFF'
                        : list?.object_info?.status == 'transfered'
                        ? 'linear-gradient(0deg, rgba(56, 217, 185, 0.2), rgba(56, 217, 185, 0.2)), #FFFFFF'
                        : 'linear-gradient(0deg, rgba(64, 148, 247, 0.15), rgba(64, 148, 247, 0.15)), #FFFFFF'
                    }`,
                  }}
                >
                  <Typography
                    className={cls.listStatus}
                    style={{
                      color: `${
                        list?.object_info.status == 'refilled'
                          ? '#4094F7'
                          : list?.object_info?.status == 'recieved'
                          ? '#4094F7'
                          : list?.object_info?.status == 'purchased'
                          ? '#D29404'
                          : list?.object_info?.status == 'transfered'
                          ? '#1AC19D'
                          : '#4094F7'
                      }`,
                    }}
                  >
                    {t(`${list?.object_info.status}`)}
                  </Typography>
                </div>
              </div>
              <div className={cls.hr} />
              {/* Inn */}
              <div className={cls.inn}>
                <Typography className={cls.innName}>ИНН</Typography>
                <Typography className={cls.innNumber}>
                  {list?.object_info?.inn}
                </Typography>
              </div>
            </div>
          </div>
        </Box>
      </div>
    </div>
  )
}
