import { ErrorIcon } from "../svg"

const InputError = ({msg}) => {
  return ( 
    <div className="error flex items-center">
      <ErrorIcon />
      <span className="ml-2">{msg}</span>
    </div>

   );
}
 
export default InputError;