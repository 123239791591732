import {
    Container,
    Box,
    Typography,
    Button,
    makeStyles,
  } from '@material-ui/core'
  import React, { useState } from 'react'
  import cls from './index.module.scss'
  import { toast } from 'react-toastify'
  import CircularProgress from '@material-ui/core/CircularProgress'
  import { UrecruitBig, WhiteUrecruit, ErrorIcon } from '../../../components/svg/WhiteUrecruit'
  import axios from '../../../utils/axios'
  import { useHistory } from 'react-router-dom'
  import iMac from '../../../assets/icons/Imac.png'
  import config from '../../../../src/config/defaultSettings'
  
  const useStyles = makeStyles(() => ({
    errorBorder: {
      '& label + .MuiInput-formControl': {
        border: '1px solid #f71111 !important',
      },
      '& svg': {
        color: '#f71111',
      },
    },
    root: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      '& > * + *': {
        marginLeft: '15px',
      },
      '& .MuiCircularProgress-colorPrimary': {
        width: '20px !important',
        height: '20px !important',
      },
      '& svg': {
        marginLeft: '0 !important',
        color: '#fff',
      },
    },
  }))
  
  export default function CheckEmail() {
    const history = useHistory()
    const classes = useStyles()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)
    const [userData, setUserData] = useState({
      email: '',
    })
  
    const handleChange = (prop) => (event) => {
      setUserData({ ...userData, [prop]: event.target.value })
    }
  
    const handleSubmit = (e) => {
      e.preventDefault()
      setIsLoading(true)
      axios
        .post(`/auth/send-to-url`, {
          base_url: config.researcherBaseURL,
          email: userData.email.toLowerCase(),
        })
        .then(() => {
          toast.success('Отправлено линк ваш Email!', {
            className: 'bg-green-500 text-white',
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          })
          setIsLoading(false)
          history.push('/auth/login')
        })
        .catch((err) => {
          toast.error(err.data.error.includes("Вы") ? err.data.error : "Неверный e-mail", {
            className: 'bg-red-500 text-white',
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          })
          console.log(err)
          setIsLoading(false)
          setError(true)
        })
    }
  
    return (
      <div className={cls.root} id='toaster_alert'>
        <Container className={cls.container}>
          <div className={cls.row}>
            <Box className={cls.left}>
              <img src={iMac} alt='iMAC' />
              <WhiteUrecruit />
            </Box>
            <Box className={cls.right}>
              <form onSubmit={handleSubmit} className={cls.formControl}>
                <div className={cls.logo}>
                  <UrecruitBig />
                </div>
                <div className={cls.row}>
                  <Box className={cls.title}>
                    <Typography className={cls.text}>
                      Восстановить пароль
                    </Typography>
                  </Box>
  
                  <label
                    style={{ width: '100%' }}
                  >
                    Веедите e-mail
                    <input
                      className={`${error && cls.errorBorder}`}
                      name='email'
                      onChange={handleChange('email')}
                      value={userData.email}
                      type='email'
                    />
                  </label>
                  {error ? (
                    <div className={cls.wrongEmail}>
                      <ErrorIcon />
                      <Typography className={cls.errorText}>
                        Неправильный email
                      </Typography>
                    </div>
                  ) : (
                    ''
                  )}
                  <Typography className={cls.info}>
                    Введите E-mail чтобы получить код активации
                  </Typography>
                  <Box className={cls.btnGroup}>
                    <Button type='submit' className={cls.btn}>
                      {!isLoading ? (
                        'Продолжить'
                      ) : (
                        <div className={classes.root}>
                          {' '}
                          <CircularProgress />
                        </div>
                      )}
                    </Button>
                  </Box>
                </div>
              </form>
            </Box>
          </div>
        </Container>
      </div>
    )
  }
  