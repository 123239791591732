import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { ErrorIcon } from "../svg/Error";
import { Typography } from "@material-ui/core";
import InputMask from "react-input-mask";
import axios from "../../utils/axios";
import { useParams, useHistory } from "react-router-dom";
import { NumberFomat } from "../../functions/numberFormat";
import { toast } from "react-toastify";
import { FastfoodOutlined } from "@material-ui/icons";

export default function ShowBalance({ open, setOpen, list, inquiryId, next }) {
  const [error, setError] = useState(false);
  const params = useParams();
  const history = useHistory();
  const [amountInquiry, setAmounInquiry] = useState(0);
  const [cost, setCost] = useState(0);
  const [amountTransaction, setAmountTransaction] = useState(0);
  const gender = list?.inquiry_requirement.filter(
    (item) => item?.name === "Пол"
  );
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // console.log('PARMA', params)
  const getCompany = () => {
    axios
      .get(`/company/${params.company_id}`)
      .then((res) => {
        console.log(res);
        getTransaction(res.data?.account_number);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getTransaction = (id) => {
    axios
      .get(`/billing/transaction/get_balance/${id}`)
      .then((res) => {
        console.log(res);
        setAmountTransaction(res.data.balance);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const Calculate = () => {
    axios
      .get(
        `/inquiry/calculate_cost?company_id=${
          params.company_id
        }&partisipant_role=${list?.partisipant_role}&duration=${Number(
          list?.duration
        )}&number_of_partisipants=${
          list?.number_of_partisipants
        }&additional_cost=${list?.additional_cost}&is_online=${
          list?.interview_type === "online" ? true : false
        }&study_type=${list?.study_type}&is_supported=${
          list?.is_supported === true ? true : false
        }&select_interest=${
          list?.audience?.value === "1a2eeeac-5722-4908-823c-ea8c0fe47e57"
            ? true
            : false
        }&select_region=${
          list?.region?.value === "d244ae58-61c3-404a-a2fa-b1eea73039a6" &&
          list?.inquiry_info?.district?.length < 15 &&
          list?.inquiry_info?.district?.length !== 15
            ? true
            : false ||
              (list?.region?.value === "d7a0af0b-e4c8-4927-b97a-c277b0718fe1" &&
                list?.inquiry_info?.district?.length < 6 &&
                list?.inquiry_info?.district?.length !== 6)
            ? true
            : false ||
              (list?.region?.value === "44eb9407-316c-4d2c-80c8-b337179af5a2" &&
                list?.inquiry_info?.district?.length < 14 &&
                list?.inquiry_info?.district?.length !== 14)
            ? true
            : false
        }&select_gender=${
          gender[0]?.requirement_values?.length > 0 &&
          gender[0]?.requirement_values?.length !== 2
            ? true
            : false
        }`
      )
      .then((res) => {
        console.log(res);
        setAmounInquiry(res.data.total_cost);
        setCost(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getCompany();
    Calculate();
  }, [list?.inquiry_requirement, list]);
  useEffect(() => {
    if (amountTransaction < amountInquiry) {
      setError(true);
    } else {
      setError(false);
    }
  }, [amountTransaction]);
  const activeToloka = () => {
    const data = {
      inquiry_id: inquiryId,
    };
    axios
      .post(`/toloka`, data)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmit = (e) => {
    axios
      .put(`/inquiry/update_status`, {
        inquiry_id: params.inquery_id,
        researcher_id: list.researcher_id,
        status_id: "7b75685f-ee20-4fb9-bc5f-7e40c115e708",
      })
      .then((res) => {
        if (next) {
          activeToloka();
        }
        console.log(res);
        history.push(`/home/research`);
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          `${
            err.data.error === "Выберите время для интевью"
              ? "Выберите время для интевью"
              : "Недостаточно средств на счету"
          }`,
          {
            className: "bg-red-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          }
        );
      });
  };

  return (
    <div>
      <Dialog
        id="balance"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="header">
          <Typography className="title">Активация запроса</Typography>
          {error && (
            <Typography className="error">
              <ErrorIcon /> <span>Недостаточно средств на счету</span>
            </Typography>
          )}
        </div>
        <div className="body">
          <div className="first">
            <label>Баланс</label>
            <InputMask
              value={NumberFomat(parseInt(amountTransaction))}
              mask={`999999999999 ₽`}
              maskChar={null}
              disabled={true}
            />
          </div>
          <div className="first">
            <label>Стоимость запроса</label>
            <InputMask
              value={NumberFomat(parseInt(cost.total_cost_without_ndc))}
              className="secondInput"
              mask={`999999999999 ₽`}
              maskChar={null}
              disabled={true}
            />
          </div>
          <div className="first">
            <label>НДС</label>
            <InputMask
              value={NumberFomat(parseInt(cost.ndc))}
              className="secondInput"
              mask={`999999999999 ₽`}
              maskChar={null}
              disabled={true}
            />
          </div>
          <div className="first">
            <label>Всего</label>
            <InputMask
              value={NumberFomat(parseInt(amountInquiry))}
              className="secondInput"
              mask={`999999999999 ₽`}
              maskChar={null}
              disabled={true}
            />
          </div>
          <div className="third">
            <label>
              {amountTransaction < amountInquiry
                ? "Необходимая сумма для активации"
                : "Остаток"}
            </label>
            <InputMask
              value={NumberFomat(parseInt(amountTransaction - amountInquiry))}
              mask={
                amountTransaction < amountInquiry
                  ? `-99999999999`
                  : `99999999999`
              }
              maskChar={null}
              disabled={true}
            />
          </div>
          <div className="btnGroup">
            <Button
              onClick={() => {
                setOpen(false);
              }}
              className="btn"
            >
              Отменить
            </Button>
            <Button
              onClick={handleSubmit}
              // disabled={Boolean(error)}
              className="btnSecond"
            >
              Активировать
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
