import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import cls from "./index.module.css";
import { useHistory, useParams } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import axios from "../../utils/axios";
import { getIn } from "yup/lib/util/reach";
import SvgIcon from "../../components/UploadWitchComment/Icons";

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  company: "",
  position: "",
  age: "",
  city: "",
  sex: "",
  employment: "",
};

const onSubmit = (values) => {
  console.log("Form values:", values); //values should be fetch from API
};

// const validate = values => {
//     let errors = {}

//     if (!values.name) {
//         errors.name = "Required"
//     }
//     if (!values.lastname) {
//         errors.lastname = "Required"
//     }
//     if (!values.email) {
//         errors.email = "Required"
//     }else if (/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i.test(values.email)) {
//         errors.email = 'Invalid email format'
//     }

//     if (!values.phoneNumber) {
//         errors.phoneNumber = "Required"
//     }

//     if (!values.nameOfCompany) {
//         errors.nameOfCompany = "Required"
//     }

//     if (!values.responsibility) {
//         errors.responsibility = "Required"
//     }

//     if (!values.age) {
//         errors.age = "Required"
//     }

//     if (!values.city) {
//         errors.city = "Required"
//     }

//     if (!values.gender) {
//         errors.gender = "Required"
//     }

//     if (!values.occupation) {
//         errors.occupation = "Required"
//     }

//     return errors

// }

function UserInformation() {
  const history = useHistory();
  const params = useParams();
  const [data, setData] = useState(null);
  const formik = useFormik({
    initialValues,
    onSubmit,
  });
  const getInfoRespondent = () => {
    axios
      .get(`/respondent/${params.id}`)
      .then((res) => {
        console.log("res", res);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getInfoRespondent();
  }, [params.id]);

  return (
    <>
      {data && (
        <div className={cls.root}>
          <form className={cls.formGroup} onSubmit={formik.handleSubmit}>
            {data?.is_check === 1 ? (
              <div className={cls.timeRow}>
                <Typography className={cls.time}>
                  Аккаунт верифицирован <SvgIcon name="check" />
                </Typography>
              </div>
            ) : null}
            <div className={cls.formGroup__field}>
              <label htmlFor="first_name">Имя</label>
              <input
                disabled={true}
                className={cls.formGroup__input}
                type="text"
                id="first_name"
                onChange={formik.handleChange}
                value={data.first_name}
              />
              {/* {formik.errors.name ? <div>{formik.errors.name}</div> : null} */}
            </div>

            {/* <div className={cls.formGroup__field}>
                <label htmlFor='last_name'>Фамилия</label>
                <input disabled={true} className={cls.formGroup__input} type='text' id='last_name' onChange={formik.handleChange} value={data.last_name}/> */}
            {/* {formik.errors.lastname ? <div>{formik.errors.lastname}</div> : null} */}
            {/* </div> */}

            <div className={cls.formGroup__field}>
              <label htmlFor="email">Email</label>
              <input
                disabled={true}
                className={cls.formGroup__input}
                type="email"
                id="email"
                onChange={formik.handleChange}
                value={data.email}
              />
              {/* {formik.errors.email ? <div>{formik.errors.email}</div> : null} */}
            </div>

            <div className={cls.formGroup__field}>
              <label htmlFor="phone">Телефон</label>
              <input
                disabled={true}
                className={cls.formGroup__input}
                type="tel"
                id="phone"
                onChange={formik.handleChange}
                value={data.phone}
              />
              {/* {formik.errors.phoneNumber ? <div>{formik.errors.phoneNumber}</div> : null} */}
            </div>

            <div className={cls.formGroup__field}>
              <label htmlFor="company">Название компании</label>
              <input
                disabled={true}
                className={cls.formGroup__input}
                type="text"
                id="company"
                onChange={formik.handleChange}
                value={data.company}
              />
              {/* {formik.errors.nameOfCompany ? <div>{formik.errors.nameOfCompany}</div> : null} */}
            </div>

            <div className={cls.formGroup__field}>
              <label htmlFor="position">Должность</label>
              <input
                disabled={true}
                className={cls.formGroup__input}
                type="text"
                id="position"
                onChange={formik.handleChange}
                value={data.position}
              />
              {/* {formik.errors.responsibility ? <div>{formik.errors.responsibility}</div> : null} */}
            </div>

            {!data.additional_info.private && (
              <>
                <p className={cls.formGroup__text}>Дополнительная информация</p>
                <div className={cls.formGroup__field}>
                  <label htmlFor="age">Возраст</label>
                  <input
                    disabled={true}
                    className={cls.formGroup__input}
                    type="number"
                    id="age"
                    onChange={formik.handleChange}
                    value={data.additional_info.age}
                  />
                  {/* {formik.errors.age ? <div>{formik.errors.age}</div> : null} */}
                </div>

                <div className={cls.formGroup__field}>
                  <label htmlFor="city">Город</label>
                  <input
                    disabled={true}
                    className={cls.formGroup__input}
                    type="text"
                    id="city"
                    onChange={formik.handleChange}
                    value={data.additional_info.city}
                  />
                  {/* {formik.errors.city ? <div>{formik.errors.city}</div> : null}                 */}
                </div>

                <div className={cls.formGroup__field}>
                  <label htmlFor="sex">Пол</label>
                  {/* <select className={cls.formGroup__input} name='gender' onChange={formik.handleChange} value={data?.gender}>
                    <option value='none'>Выберите свой пол</option>
                    <option value='male'>Male</option>
                    <option value='female'>Female</option>
                    <option value='other'>Other</option>
                </select> */}
                  <input
                    disabled={true}
                    className={cls.formGroup__input}
                    type="text"
                    id="sex"
                    onChange={formik.handleChange}
                    value={data.additional_info.sex}
                  />
                </div>

                <div className={cls.formGroup__field}>
                  <label htmlFor="employment">Занятость</label>
                  <input
                    disabled={true}
                    className={cls.formGroup__input}
                    type="text"
                    id="employment"
                    onChange={formik.handleChange}
                    value={data.additional_info.employment}
                  />
                  {/* {formik.errors.occupation ? <div>{formik.errors.occupation}</div> : null} */}
                </div>
                {/* <button type='submit'>Отменить</button> */}
              </>
            )}
            <div className={cls.buttons}>
              {/* <button type='button' onClick={()=>{
                    history.goBack()
                }} className={cls.formGroup__btn}>Отменить</button> */}
              <Button
                onClick={() => {
                  history.goBack();
                }}
                type="button"
                className={cls.formGroup__btn__primary}
              >
                Назад
              </Button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default UserInformation;
