import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import cls from './INN.module.scss'
import { BackArrow }  from "../../svg/BackArrow"
import InputMask from 'react-input-mask'
import { Box, Button, Typography } from '@material-ui/core'
import SuccessResponse from './SuccessResponse'
import WaitResponse from './WaitResponse'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function INN({ setCheckINN, checkINN, setOpen,setPasscode, authPasscode, confirmItemToken }) {
  const [error, setError] = useState(false)
  const [waitResponse, setWaitResponse] = useState(false)
  const [successCard, setSuccessCard] = useState(false)
  const handleClose = () => {
    setCheckINN(false)
  }

  return (
    <div className={cls.root}>
      <form>
        <Dialog
          id='client_info'
          open={checkINN}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'
        >
          <div className={cls.row}>
            <a
              onClick={() => {
                setCheckINN(false)
                // setOpen(true)
              }}
            >
              <div className={cls.title}>
                <BackArrow />{' '}
                <Typography className={cls.text}>Назад</Typography>
              </div>
            </a>
            <Typography className={cls.header}>Отправили СМС с кодом</Typography>

            <label>
              {error ? (
                <Typography className={cls.errorText}>Отправили СМС с кодом на Email  телефон нумбер </Typography>
              ) : (
                ''
              )}
              <input
                className={`${!error ? cls.mt : cls.error}`}
                onChange={(e)=> {setPasscode(old => ({...old, passcode: e.target.value}))}}
                mask={`999999999999`}
                maskChar={null}
                required={true}
              />
            </label>

            <Typography className={cls.text}>Узнать свой ИНН</Typography>

            <div className={cls.btnGroup}>
              <Button
                onClick={confirmItemToken}
                className={cls.btn}
              >
                Отправить
              </Button>
            </div>
          </div>
        </Dialog>
      </form>
      <WaitResponse
        waitResponse={waitResponse}
        setWaitResponse={setWaitResponse}
        setCheckINN={setCheckINN}
      />
      <SuccessResponse
        successCard={successCard}
        setSuccessCard={setSuccessCard}
        setCheckINN={setCheckINN}
      />
    </div>
  )
}
