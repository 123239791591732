import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { StyledTableCell } from "../StyledTableCell/index";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Pagination from "../../components/Pagination";
import Inquiry from "../../models/inquiry";
import moment from "moment";
import { Typography, Menu, Tooltip, CircularProgress } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Cached from "@material-ui/icons/Cached";
import Tag from "../Tag/index";
import ChangeStatatus from "./ChangeStatus";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import ExcelFile from "../../models/excel";
import { Act, Balance } from "../svg/Act";
import { DownloadOutlined } from "@ant-design/icons";
import { NumberFomat } from "../../functions/numberFormat";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import companiyInquiry from "../../models/companyinquiry";
import CopyrightIcon from "@material-ui/icons/Copyright";
import CopyRight from "./CopyRight";
import SvgIcon from "../UploadWitchComment/Icons";

const Status = (value) => {
  switch (value) {
    case "72ee7350-4a2b-4fc2-945c-34faa56b13ca":
      return "Черновик";
    case "7b75685f-ee20-4fb9-bc5f-7e40c115e708":
      return "Активный";
    case "2e857fbe-9add-4eae-a8c4-fe57fb384347":
      return "Завершено";
    case "2a98c22e-cbee-49f9-90ef-f56429d96333":
      return "Отменено";
    default:
      return "-";
  }
};

const Colors = (name) => {
  switch (name) {
    case "72ee7350-4a2b-4fc2-945c-34faa56b13ca":
      return "linear-gradient(0deg, rgba(248, 221, 78, 0.3), rgba(248, 221, 78, 0.3)), #FFFFFF";
    case "7b75685f-ee20-4fb9-bc5f-7e40c115e708":
      return "linear-gradient(0deg, rgba(64, 148, 247, 0.15), rgba(64, 148, 247, 0.15)), #FFFFFF";
    case "2e857fbe-9add-4eae-a8c4-fe57fb384347":
      return "linear-gradient(0deg, rgba(56, 217, 185, 0.2), rgba(56, 217, 185, 0.2)), #FFFFFF";
    case "2a98c22e-cbee-49f9-90ef-f56429d96333":
      return "linear-gradient(0deg, rgba(247, 102, 89, 0.15), rgba(247, 102, 89, 0.15)), #FFFFFF";
    default:
      return "-";
  }
};
const TextColor = (name) => {
  switch (name) {
    case "72ee7350-4a2b-4fc2-945c-34faa56b13ca":
      return "#D29404";
    case "7b75685f-ee20-4fb9-bc5f-7e40c115e708":
      return "#4094F7";
    case "2e857fbe-9add-4eae-a8c4-fe57fb384347":
      return "#1AC19D";
    case "2a98c22e-cbee-49f9-90ef-f56429d96333":
      return "#F76659";
    default:
      return "-";
  }
};

const ResearchTable = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const [companyList, setcompanyList] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [show, setShow] = useState(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [tableheadername, setTableheadername] = useState([
    t("№"),
    t("Дата от"),
    t("Дата до"),
    t("Заголовок"),
    t("Описание"),
    t("total_cost"),
    t("Статус"),
    "",
  ]);

  const [items, setItems] = useState({});
  const [search, setSearch] = useState();
  const pageCount = 10;
  const [anchorEl, setAnchorEl] = useState(null);
  const [pageLimit, setPageLimit] = useState(pageCount);
  const [respondentCount, setRespondentCount] = useState(null);
  const [projectId, setProjectId] = useState({});
  const [inquerList, setinquerList] = useState([]);
  const [surveyId, setSurveyId] = useState({});
  const [listInquery, setListInquery] = useState(null);
  const [calculateCost, setCalculateCost] = useState(null);
  const [loader, setLoader] = useState(false);
  const [initialValue, setinitialValue] = useState({
    company_id: "",
    // one Steppers
    title: "",
    from_date: moment(new Date()),
    to_date: moment(new Date()).add(30, "d"),
    status_id: "72ee7350-4a2b-4fc2-945c-34faa56b13ca",
    partisipant_role: "respondent",
    study_type: "one_to_one",
    interview_type: "online",
    description: "",
    // two Stepppers
    researcher_id: "",
    type_of_inquiry: {
      group_participants: "",
      number_of_groups: "",
      type_of_inquiry: "",
    },
    // data researchdelAll select
    duration: 30,
    number_of_partisipants: 10,
    os: {},
    device: { 1: "smartphone" },
    cost: 0,
    additional_cost: 0,
    researcher_name: "",
    nda_text: "",
    // thwee Steppers
    inquiry_requirement: [
      // {
      //     input_type: 'range',
      //     name: 'attribute',
      //     data_type: 'number',
      //     id: "",
      //     requirement_value:
      //        {
      //             attribute_id: "",
      //             id: "",
      //             value: ""
      //         }
      // }
    ],
    // four Steppers
    inquiry_timetables: [
      // {
      //     dates: "",
      //     end_time: "",
      //     number_of_applicants: 0,
      //     number_of_partisipants: 0,
      //     start_time: ""
      // }
    ],
    nda: { 1: "" },
    example_nda: { 1: "" },
    connection_info: "",
    total_cost: 0,
  });

  const params = useParams();

  const checkRespondent = (ids, status) => {
    axios.get(`/inquiry/respondents?inquiry_id=${ids.id}`).then((res) => {
      setRespondentCount(res.data.count);
      if (status === "edit") {
        if (res.data.count >= 1) {
          toast.error("У этого запроса есть Респонденты!", {
            className: "bg-red-500 text-white",
            closeOnClick: true,
            ideProgressBar: false,
            autoClose: 4000,
          });
        } else {
          history.push(
            `/home/company/companyinquirycreate/${ids.researcher_id}/edit/${ids.id}/0`
          );
        }
      }
    });
  };

  const handleMoreClick = (ids, type) => {
    if (type === "edit") {
      checkRespondent(ids, "edit");
    }
    if (type === "update_status") {
      setSelectedMenu(ids);
    }
    if (type === "copy_right") {
      setSelectedMenu(ids);
    }
    if (type === "act") {
      axios
        .get(`excel/akt?inquiry_id=${ids.id}`)
        .then((res) => {
          window.location.href = res.data.url;
        })
        .catch((err) => console.log(err));
    }
    if (type === "balance") {
      axios
        .get(`/excel/factura?inquiry_id=${ids.id}`)
        .then((res) => {
          window.location.href = res.data.url;
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
    if (type === "excel") {
      axios
        .get(`excel/inquiry-respondents?inquiry_id=${ids.id}`)
        .then((res) => {
          window.location.href = res.data.url;
          console.log(res);
        })
        .catch((err) => console.log(err));
    }
    if (type === "word") {
      axios
      .get(`word/service-order?inquiry_id=${ids.id}`)
      .then((res) => {
        window.location.href = res.data.url;
        console.log(res);
      })
      .catch((err) => console.log(err));
    }
  };
  const handleCutomRow = (researcherId) => {
    history.push(
      `${"/home/company/inquiryitem"}/${researcherId.researcher_id}/${
        researcherId.id
      }`
    );
  };
  const handleClick = (event, data) => {
    setAnchorEl(event.currentTarget);
    setSelectedMenu(data);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedMenu(null);
  };
  const permissions = useSelector((state) => state.auth.permissions);
  const getResearchers = () => {
    if (permissions.find((el) => el === "Менеджер")) {
      const param = {
        page: pageLimit / pageCount,
        limit: pageCount,
        researcher_id: "",
        title: search,
      };
      Inquiry.inquiryGetAllList(param).then((res) => {
        console.log(res);
        setItems(res.data);
        setcompanyList(res.data.inquiries);
        setloading(false);
      });
      setloading(true);
    } else {
      setloading(true);
      const param = {
        page: pageLimit / pageCount,
        limit: pageCount,
        researcher_id: localStorage.getItem("user_id"),
        title: search,
      };
      Inquiry.inquiryGetAllList(param).then((res) => {
        console.log(res);
        setItems(res.data);
        setcompanyList(res.data.inquiries);
        setloading(false);
      });
    }
  };

  // Excel Download
  const getExcelFile = () => {
    const param = {
      search: search,
      researcher_id: permissions.find((el) => el === "Менеджер")
        ? ""
        : localStorage.getItem("user_id"),
    };
    ExcelFile.inqueryGetAllExcelFile(param)
      .then((res) => {
        window.location.href = res.data.url;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleNext = (arg) => {
    setActiveStep((prevActiveStep) => arg ?? prevActiveStep + 1);
  };
  const time =
    (new Date(selectedMenu?.to_date).getTime() -
      new Date(selectedMenu?.from_date).getTime()) /
    (1000 * 3600 * 24);

  const copyRight = {
    screener_project_id: projectId.id,
    screener_survey_id: surveyId.id,
    additional_cost: selectedMenu?.additional_cost,
    company_id: selectedMenu?.company_id,
    connection_info: selectedMenu?.connection_info,
    description: selectedMenu?.description,
    device: selectedMenu?.device,
    duration: selectedMenu?.duration,
    example_nda: selectedMenu?.example_nda,
    inquiry_requirement: selectedMenu?.inquiry_requirement,
    nda: selectedMenu?.nda,
    type_of_inquiry: selectedMenu?.type_of_inquiry,
    nda_text: selectedMenu?.nda_text,
    interview_type: selectedMenu?.interview_type,
    number_of_partisipants: selectedMenu?.number_of_partisipants,
    os: selectedMenu?.os,
    partisipant_role: selectedMenu?.partisipant_role,
    researcher_id: selectedMenu?.researcher_id,
    researcher_name: selectedMenu?.researcher_name,
    study_type: selectedMenu?.study_type,
    title: selectedMenu?.title,
    from_date: moment(new Date()).add(12, "hours").format("YYYY-MM-DD"),
    to_date: moment(new Date())
      .add(12, "hours")
      .add(time, "days")
      .format("YYYY-MM-DD"),
    total_cost: calculateCost?.total_cost,

    status_id: selectedMenu?.status_id,
  };
  const onSubmit = (data) => {
    let screenner_projec = "";
    let survey_id = "";
    const project = {
      auto_next: true,
      is_active: true,
      is_archive: true,
      languages: [
        {
          is_active: true,
          language: "ru",
        },
      ],
      multiple_answers: true,
      record_answers: true,
      return_answers: true,
    };
    // console.log("project_id", projectId.id);
    // console.log("survey_id", surveyId);

    if (params.inquery_id) {
      handleNext();
      return;
    }
    setLoader(true);

    companiyInquiry.createProject(project).then((res) => {
      const survey = {
        title: initialValue.title,
      };
      screenner_projec = res.response_data.id;
      setProjectId(res.response_data);

      companiyInquiry.createStroy(survey, res.response_data.id).then((res) => {
        // survey_id = res.response_data.id;
        setSurveyId(res.response_data);
        setinitialValue((el) => ({
          ...el,
          screener_project_id: screenner_projec,
          screener_survey_id: res.response_data.id,
        }));

        companiyInquiry.getInquiry(selectedMenu.id).then((res) => {
          setListInquery(res.data);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
      }).catch((err) => {
        console.log(err);
        setLoader(false);
      });
    })
    .catch((err) => {
      console.log(err);
      setLoader(false);
    });
    handleNext();
  };

  useEffect(() => {
    if (listInquery) {
      companiyInquiry
        .calculateCost(
          listInquery.partisipant_role,
          listInquery.duration,
          listInquery.number_of_partisipants,
          listInquery.additional_cost,
          listInquery.company_id,
          listInquery.interview_type === "online" ? "true" : "false"
        )
        .then((res) => {
          setCalculateCost(res.data);
        }).catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  }, [listInquery]);

  useEffect(() => {
    if (calculateCost) {
      companiyInquiry.InquieryCreate(copyRight).then(() => {
        toast.success(`${t("Успешно") + " " + t("скопировано")}`, {
          className: "bg-green-500 text-white",
          closeOnClick: true,
          ideProgressBar: false,
          autoClose: 4000,
        });
        window.location.reload();
        setLoader(false)
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
    }
  }, [calculateCost]);

  useEffect(() => {
    getResearchers();
  }, []);
  useEffect(() => {
    getResearchers();
  }, [pageLimit, search]);
  return (
    <div style={{ padding: "20px", borderRadius: "8px", marginTop: "50px" }}>
      <div style={{ width: "100%", background: "#fff" }}>
        <div>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => handleMoreClick(selectedMenu, "edit")}
              className="mx-1"
            >
              <EditIcon className="bg-blue-100 rounded text-blue-600 p-1 " />
              <span className="ml-2">{t("edit")}</span>
            </MenuItem>
            <MenuItem
              onClick={() => handleMoreClick(selectedMenu, "update_status")}
            >
              <ChangeStatatus
                element={selectedMenu}
                t={t}
                setElement={setcompanyList}
                closeMenu={setAnchorEl}
                Cached={Cached}
              />
              {/* <Cached className="bg-green-100 rounded text-green-600 p-1 my-1" /> <span className="ml-2">{t('Изменить статус')}</span> */}
            </MenuItem>
            <MenuItem
              onClick={() => handleMoreClick(selectedMenu, "copy_right")}
              style={loader ? {justifyContent: "center"} : null}
            >
          {loader ? (
            <CircularProgress size="20px" />
          ) : (
              <CopyRight
                checkActive={true}
                element={selectedMenu}
                t={t}
                setElement={setinquerList}
                closeMenu={setAnchorEl}
                Cached={CopyrightIcon}
                onSubmit={onSubmit}
              />
          )}
            </MenuItem>
            {show === "2e857fbe-9add-4eae-a8c4-fe57fb384347" && (
              <MenuItem
                onClick={() => handleMoreClick(selectedMenu, "act")}
                className="mx-1"
              >
                <Act className="bg-blue-100 rounded text-blue-600 p-1 " />
                <span className="ml-2">{"АКТ"}</span>
              </MenuItem>
            )}
            {show === "2e857fbe-9add-4eae-a8c4-fe57fb384347" && (
              <>
                <MenuItem
                  onClick={() => handleMoreClick(selectedMenu, "excel")}
                  className="mx-1"
                >
                  <FileCopyIcon className="bg-blue-100 rounded text-blue-600 p-1 " />
                  <span className="ml-2">{"Детализация"}</span>
                </MenuItem>
              </>
            )}
            {show === "2e857fbe-9add-4eae-a8c4-fe57fb384347" && (
              <>
                <MenuItem
                  onClick={() => handleMoreClick(selectedMenu, "word")}
                  className="mx-1"
                >
                  <SvgIcon name="word" className="bg-blue-100 rounded text-blue-600 p-1 " />
                  <span className="ml-2">{"Форма заказа"}</span>
                </MenuItem>
              </>
            )}
          </Menu>

          <TableContainer
            elevation={0}
            component={Paper}
            className=" shadow-none rounded-2xl p-5"
          >
            <div
              style={{
                position: "sticky",
                right: "0",
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                marginBottom: "16px",
              }}
            >
              <input
                name="search"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                style={{
                  border: "1px solid #E5E9EB",
                  borderRadius: "8px",
                  padding: "8px 12px",
                  maxWidth: "250px",
                }}
                placeholder="Поиск"
              />
              {permissions.find((el) => el === "Отчеты") && (
                <a
                  onClick={() => {
                    getExcelFile();
                  }}
                  id="download_file"
                >
                  <DownloadOutlined />
                </a>
              )}
            </div>

            <Table
              elevation={0}
              aria-label="customized table"
              style={{ borderRadius: "20px" }}
            >
              <TableHead>
                <TableRow className="text-black font-body">
                  {tableheadername.map((element, index) => (
                    <StyledTableCell
                      style={{
                        textAlign: `${index === 5 ? "center" : "start"}`,
                      }}
                      key={index}
                    >
                      {element}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {companyList?.map((els, index) => (
                  <TableRow
                    className="text-black font-body hover:bg-gray-100 cursor-pointer"
                    // <TableRow onClick={()=> handleCutomRow(els.id)} className="text-black font-body hover:bg-gray-100 cursor-pointer"
                    key={index}
                  >
                    <StyledTableCell onClick={() => handleCutomRow(els)}>
                      {parseInt(
                        pageCount * (pageLimit / pageCount - 1) + index + 1
                      )}
                    </StyledTableCell>
                    <StyledTableCell onClick={() => handleCutomRow(els)}>
                      {moment(els.from_date).format("DD-MM-YYYY")}
                    </StyledTableCell>
                    <StyledTableCell onClick={() => handleCutomRow(els)}>
                      {moment(els.to_date).format("DD-MM-YYYY")}
                    </StyledTableCell>
                    {/*<StyledTableCell>{element.owner_name}</StyledTableCell>*/}
                    <Tooltip
                      title={
                        <h1 style={{ fontSize: "14px", color: "#fff" }}>
                          {els.title}
                        </h1>
                      }
                      placement="top"
                    >
                      <StyledTableCell onClick={() => handleCutomRow(els)}>
                        {els.title}
                      </StyledTableCell>
                    </Tooltip>
                    <Tooltip
                      title={
                        <h1 style={{ fontSize: "14px", color: "#fff" }}>
                          {els.description}
                        </h1>
                      }
                      placement="top"
                    >
                      <StyledTableCell onClick={() => handleCutomRow(els)}>
                        <Typography id="text_limit">
                          {els.description}
                        </Typography>
                      </StyledTableCell>
                    </Tooltip>
                    <StyledTableCell
                      style={{ textAlign: "center" }}
                      onClick={() => handleCutomRow(els)}
                    >
                      {NumberFomat(els.total_cost)} ₽
                    </StyledTableCell>
                    <StyledTableCell>
                      <Tag
                        shape="subtle"
                        color={Colors(els.status_id)}
                        size="large"
                        style={{
                          width: "150px",
                          background: `${Colors(els.status_id)}`,
                          borderRaduis: "6px",
                          color: `${TextColor(els.status_id)}`,
                        }}
                      >
                        {Status(els.status_id)}
                      </Tag>
                    </StyledTableCell>
                    <StyledTableCell
                      className="w-2   border-gray-300"
                      style={{ borderLeft: "1px solid gainsboro" }}
                    >
                      <MoreHorizIcon
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(e) => {
                          handleClick(e, els);
                          setShow(els.status_id);
                        }}
                        className="cursor-pointer text-blue-500"
                        style={{
                          border: "1px solid gainsboro",
                          borderRadius: "5px",
                        }}
                      />
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/*<TableLoader isVisible={loading}/>*/}

          <Pagination
            style={{ padding: "20px" }}
            className="mt-5 text-black-700"
            size={"large"}
            count={items.count}
            pageCount={pageCount}
            onChange={(val) => {
              console.log(val);
              setPageLimit(val);
            }}
          />
        </div>
        {/*<Table list={companyList} Tableheadername={Tableheadername}></Table>*/}
      </div>
    </div>
  );
};

export default ResearchTable;
