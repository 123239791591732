import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { StyledTableCell } from "../../../components/DataTable/index";
import { StyledPropMenu } from "../../../components/custom/StyledPropMenu";
import { useParams, useHistory } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import axios from "../../../utils/axios";
import Rating from "../../../components/Rating/index";
import Tag from "../../../components/Tag";
import { colors, status } from "../../../mock/tags";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { QuestionIcon } from "../../../components/svg/Question";
import ChangeStatus from "../../../components/itemResearcherTable/ChangeStatus";
import Cached from "@material-ui/icons/Cached";
import ShowRatingRespondent from "../../../components/itemResearcherTable/ShowRatingRespondent";

const CalendarBooked = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [companyList, setcompanyList] = useState([]);
  const [searchTable, setSearchTable] = useState();
  const [items, setItems] = useState({});
  const pageCount = 10;
  const [pageLimit, setPageLimit] = useState(10);
  const param = useParams();
  const [tableheadername, setTableheadername] = useState([
    t("number.icon"),
    t("Ф.И.О"),
    t("Телефон"),
    t("E-mail"),
    t("NDA"),
    t("Рейтинг"),
    t("Статус"),
    "",
  ]);
  const [respondentId, setRespondentId] = useState();
  const [dropList, setDropList] = useState({ target: null, id: "" });
  const [open, setOpen] = useState(false);
  const [showRating, setShowRating] = useState({
    count: null,
    data: [],
  });
  const listRender = [
    {
      title: "Вопросы",
      icon: (
        <QuestionIcon className="bg-blue-100 rounded text-blue-600 p-0.5" />
      ),
      type: "question",
    },
  ];
  const companyGetAllList = () => {
    axios
      .get(`/timetable/respondents?timetable_id=${param.id}`)
      .then((res) => {
        console.log("res=>", res);
        setItems(res.data);
        setcompanyList(res.data.respondents);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [surveyId, setSurveyId] = useState();
  const getScreenerSurveyId = () => {
    axios
      .get(`/inquiry/${param.inquiry_id}`)
      .then((res) => {
        setSurveyId(res.data.screener_survey_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getScreenerSurveyId();
  }, [param.inquiry_id]);
  const handleMoreClick = (id, type) => {
    // alert('Development on process...')
    // if (type === 'edit') {
    //   console.log('edit')
    //   setDropList((old) => {
    //     return { ...old, target: null }
    //   })
    // }
    if (type === "delete") {
      setDropList((old) => {
        return { ...old, target: null };
      });
    }
  };
  useEffect(() => {
    companyGetAllList();
  }, []);
  useEffect(() => {
    companyGetAllList();
  }, [pageLimit]);

  return (
    <div>
      <StyledPropMenu
        anchorEl={dropList.target}
        open={Boolean(dropList.target)}
        onClose={(e) => {
          e.stopPropagation();
          setDropList((old) => {
            return { ...old, target: null };
          });
        }}
      >
        <div className="flex  flex-col divide-y font-body text-sm">
          <ChangeStatus
            element={dropList.data}
            t={t}
            params={param}
            closeMenu={setDropList}
            Cached={Cached}
          />
          {listRender.map((el, index) => (
            <div
              key={index}
              className="flex w-full cursor-pointer py-2"
              onClick={(e) => {
                if (el.type === "question") {
                  history.push(`/home/question/${surveyId}/${respondentId}`);
                  setDropList({
                    ...dropList,
                    target: null,
                  });
                } else {
                  e.stopPropagation();
                  handleMoreClick(dropList.data.id, el.type);
                }
              }}
            >
              <div className="rounded">{el.icon}</div>
              <div className="my-auto mx-2">{el.title}</div>
            </div>
          ))}
        </div>
      </StyledPropMenu>
      <div className="fslex p-5 px-5 pb-4" style={{ borderRadius: "20px" }}>
        <TableContainer
          elevation={0}
          component={Paper}
          className=" shadow-none rounded-2xl p-5"
        >
          <Table
            elevation={0}
            aria-label="customized table"
            style={{ borderRadius: "20px" }}
          >
            <TableHead>
              <TableRow className="text-black font-body">
                {tableheadername.map((element, index) => (
                  <StyledTableCell key={index}>{element}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {companyList?.map((element, index) => (
                <TableRow
                  style={{ borderBottom: "1px solid #ccc" }}
                  className="text-black font-body hover:bg-gray-100 cursor-pointer"
                  key={index}
                >
                  <StyledTableCell>
                    {parseInt(
                      pageCount * (pageLimit / pageCount - 1) + index + 1
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {element.name ? element.name : element.first_name}
                  </StyledTableCell>
                  <StyledTableCell>{element.phone}</StyledTableCell>
                  <StyledTableCell>{element.email}</StyledTableCell>
                  <StyledTableCell
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      border: "none",
                    }}
                  >
                    {element.nda && element.nda[1]
                      ? Object.keys(element.nda).map((el, i) => (
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href={`${element.nda[el]}`}
                            className="truncate"
                            id="download_link"
                          >
                            {element.nda[el].split("+")?.[1]}
                          </a>
                        ))
                      : ""}
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => {
                      setOpen(true);
                      setShowRating({
                        count: 1,
                        data: [element.rating],
                      });
                    }}
                  >
                    <Rating
                      count={`${
                        (element.rating.communication +
                          element.rating.experience +
                          element.rating.punctuality) /
                        3
                      }`}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Tag
                      shape="subtle"
                      color={colors(element.status_id)}
                      size="large"
                      style={{ width: "150px" }}
                    >
                      {status(element.status_id)}
                    </Tag>
                  </StyledTableCell>
                  <StyledTableCell
                    className="w-2   border-gray-300"
                    style={{
                      borderLeft: "1px solid gainsboro",
                    }}
                  >
                    <MoreHorizIcon
                      onClick={(e) => {
                        setRespondentId(element.id);
                        e.stopPropagation();
                        setDropList({
                          target: e.currentTarget,
                          id: element.id,
                          data: element,
                        });
                      }}
                      className="cursor-pointer text-blue-500"
                      style={{
                        border: "1px solid gainsboro",
                        borderRadius: "5px",
                      }}
                    />
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {showRating.count === 1 && (
          <ShowRatingRespondent
            data={showRating}
            open={open}
            setOpen={setOpen}
          />
        )}
        <Pagination
          className="mt-5 text-black-700"
          size={"large"}
          count={items.count}
          pageCount={pageCount}
          onChange={(val) => {
            setPageLimit(val);
          }}
        />
      </div>
      {/*<Table list={companyList} Tableheadername={Tableheadername}></Table>*/}
    </div>
  );
};

export default CalendarBooked;
