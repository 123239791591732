import Layout from '../components/Layout/index.jsx'
import Sidebar from '../components/Sidebar/index'

export default function App({ children }) {
  return (
    <div>
      <Layout sidebar={<Sidebar />}>{children}</Layout>
    </div>
  )
}
