import React from "react";
import Header from "../../components/Header/Header";
import ResearchTable from "./ResearchTable";

export default function AllResearcherTable() {
  return (
    <div>
      <Header
        title={"Запросы"}
        location={`/home/company/companyinquirycreate/d8861efd-e4c6-4339-9a55-a58b935a4428`}
      />
      <ResearchTable />
    </div>
  );
}
