import React, { useState , useEffect } from "react";
import cls from "./index.module.scss";
import axios from "axios";
import config from "../../../src/config/defaultSettings";

export const Instraction = () => {
  const [agreement, setAgreement] = useState(null);
  const getAgreement = () => {
    axios
      .get(
        `${config.baseAppURL}content/2904a6ae-9562-4e0c-93ae-d8f97363d3b5`
      )
      .then((res) => {
        console.log('Content',res);
        setAgreement(res.data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(()=>{
      getAgreement()
  },[])
  return (
    <>
      <div className={cls.root} id="agreement">
          <div className={cls.row}>
                {agreement && 
                    <div dangerouslySetInnerHTML={{__html : agreement?.body}}/>
                }
          </div>
      </div>
    </>
  );
};
