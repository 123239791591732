import React from 'react'
import { Rating } from '@material-ui/lab'
import Box from '@material-ui/core/Box'

export default function SimpleRating({ count }) {
  const [value, setValue] = React.useState(count)

  return (
    <div>
      <Box component='fieldset' mb={3} borderColor='transparent'>
        <Rating name='read-only' value={value} readOnly />
      </Box>
    </div>
  )
}

// import { useState, useEffect } from 'react'

// export default function App({ count, total = 5 }) {
//   const [activeStars, setActiveStars] = useState([])
//   const [notActive, setNotActive] = useState([])
//   console.log(count)
//   useEffect(() => {
//     setActiveStars(Math.ceil)
//     const active = []
//     const notActive = []
//     for (let index = 0; index < parseInt(count); index++) {
//       active.push(
//         <path
//           d='M36 17.27L42.18 21L40.54 13.97L46 9.24L38.81 8.62L36 2L33.19 8.62L26 9.24L31.45 13.97L29.82 21L36 17.27Z'
//           fill='#F8C51B'
//         />
//       )
//     }
//     for (let index = 0; index < total - parseInt(count); index++) {
//       notActive.push(
//         <path
//           d='M108 17.27L114.18 21L112.54 13.97L118 9.24L110.81 8.62L108 2L105.19 8.62L98 9.24L103.45 13.97L101.82 21L108 17.27Z'
//           fill='#D5DADD'
//         />
//       )
//     }

//     setActiveStars(active)
//     setNotActive(notActive)
//   }, [count])
//   return (
//     <svg
//       width='120'
//       height='24'
//       viewBox='0 0 120 24'
//       fill='none'
//       xmlns='http://www.w3.org/2000/svg'
//     >
//       {activeStars.map((el) => el)}
//       {notActive.map((el) => el)}
//       <path
//         d='M12 17.27L18.18 21L16.54 13.97L22 9.24L14.81 8.62L12 2L9.19 8.62L2 9.24L7.45 13.97L5.82 21L12 17.27Z'
//         fill='#F8C51B'
//       />
//       <path
//         d='M36 17.27L42.18 21L40.54 13.97L46 9.24L38.81 8.62L36 2L33.19 8.62L26 9.24L31.45 13.97L29.82 21L36 17.27Z'
//         fill='#F8C51B'
//       />
//       <path
//         d='M60 17.27L66.18 21L64.54 13.97L70 9.24L62.81 8.62L60 2L57.19 8.62L50 9.24L55.45 13.97L53.82 21L60 17.27Z'
//         fill='#F8C51B'
//       />
//       <path
//         d='M84 17.27L90.18 21L88.54 13.97L94 9.24L86.81 8.62L84 2V17.27Z'
//         fill='#DDE2E4'
//       />
//       <path
//         d='M84 2L81.19 8.62L74 9.24L79.45 13.97L77.82 21L84 17.27V2Z'
//         fill='#F8C51B'
//       />
//       <path
//         d='M108 17.27L114.18 21L112.54 13.97L118 9.24L110.81 8.62L108 2L105.19 8.62L98 9.24L103.45 13.97L101.82 21L108 17.27Z'
//         fill='#D5DADD'
//       />
//     </svg>
//   )
// }
