import { PartyModeSharp } from '@material-ui/icons';
import request from '../../utils/axios'
const researchBaseUrl ={
    getAll: '/researcher',
    post: '/researcher',
    confirm: '/auth/confirm-passcode'
}
 const research = {
GetResearchAll:(params) => (
 request({
        url: `${researchBaseUrl.getAll}`,
        method: 'get',
        params: {
            name: params.name,
            phone: params.phone,
            email: params.email,
            page: params.page.current,
            limit: params.page.pageSize
        }
    })
),
postResearch: (data) => (
   request({
        url: `${researchBaseUrl.getAll}`,
        method: 'post',
        data: data
    })
),
UpdateItem: (id) => (
   request({
        url: `${researchBaseUrl.getAll}/${id}`,
        method: 'get'
    })
),
ReasarchUpdate:(id, data) => (
    request({
        url: `${researchBaseUrl.getAll}/${id}`,
        method: 'put',
        data: data
    })
) ,
delteItemResearch:(id)=> (
    request({
        url: `${researchBaseUrl.getAll}/${id}`,
        method: 'delete'
    })
),
     itemTokenSet: (data) => (
         request({
             url: `${researchBaseUrl.confirm}`,
             method: 'post',
             data: data
    })
)
}
export default research;