import React from 'react';
import { Typography, Slide, Box, Dialog } from '@material-ui/core';
import { Rate } from 'antd';
import { useTranslation } from 'react-i18next'
import axios from '../../utils/axios'
import 'antd/dist/antd.css';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShowRatingRespondent({ open, setOpen , data }) {
    const {t} = useTranslation()
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
            id="show_rating"
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
           <div style={{dispay: 'flex' , flexDirection: 'column', padding: '20px'}}>
           <Box style={{ marginTop: '10px' }} id='change_rating'>
                    <Typography
                        style={{
                            // marginBottom: '6px',
                            fontSize: '14px',
                            lineHeight: '21px',
                            fontWeight: '400',
                            color: '#000',
                        }}
                    >
                        {t('Experience')}
                    </Typography>
                    <Rate
                       disabled={true}
                        name='simple-controlled-2'
                        value={data.count === 1 ? data.data[0].experience : data.count === 2 ? data.data[0].experience : ''}
                        // onChange={(event) => {
                        //     setRatingExperience(event)
                        // }}
                    />
                </Box>
                   <Box style={{ marginTop: '10px' }} id='change_rating'>
                    <Typography
                        style={{
                            // marginBottom: '6px',
                            fontSize: '14px',
                            lineHeight: '21px',
                            fontWeight: '400',
                            color: '#000',
                        }}
                    >
                        {t('Communication')}
                    </Typography>
                    <Rate
                       disabled={true}
                        name='simple-controlled-2'
                        value={data.count === 1 ? data.data[0].communication : data.count === 2 ? data.data[0].communication : ''}
                        // onChange={(event) => {
                        //     setRatingExperience(event)
                        // }}
                    />
                </Box>
                <Box style={{ marginTop: '10px' }} id='change_rating'>
                    <Typography
                      style={{
                        // marginBottom: '6px',
                        fontSize: '14px',
                        lineHeight: '21px',
                        fontWeight: '400',
                        color: '#000',
                      }}
                    >
                      {t('Punctuality')}
                    </Typography>
                    <Rate
                    disabled={true}
                      name='simple-controlled-3'
                      value={data.count === 1 ? data.data[0].punctuality : data.count === 2 ? data.data[0].punctuality : ''}
                    />
                  </Box>
           </div>
            </Dialog>
        </div>
    );
}