import { Box, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import cls from './index.module.scss'
import { DollarIcon } from '../../components/svg/Dollar'
import axios from '../../utils/axios'

export default function TopPanel({ minus = true }) {
  const [list, setList] = React.useState(undefined)
  useEffect(() => {
    axios
      .get(`/billing/account/get_balance_info`)
      .then((res) => {
        setList(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  return (
    <div className={cls.topPanel}>
      <div className={cls.row}>
        <Box className={cls.box}>
          <div className={cls.card}>
            <div className={cls.icon}>
              <DollarIcon arg={'#38D9B9'} />
            </div>
            <Typography className={cls.title}>Баланс</Typography>
            <Typography className={cls.price}>
              {minus
                ? parseInt(list === undefined ? 0 : list?.cost).toFixed(0)
                : parseInt(list === undefined ? 0 : list?.cost).toFixed(0) * -1}
            </Typography>
          </div>
          {/*  */}

          <div className={cls.card}>
            <div className={cls.icon}>
              <DollarIcon arg={'#4094F7'} />
            </div>
            <Typography className={cls.title}>Приход</Typography>
            <Typography className={cls.price}>
              {list === undefined ? 0 : list?.debit}
            </Typography>
          </div>

          {/*  */}

          <div className={cls.card}>
            <div className={cls.icon}>
              <DollarIcon arg={'#FF3D00'} />
            </div>
            <Typography className={cls.title}>Расход</Typography>
            <Typography className={cls.price}>
              {list === undefined ? 0 : parseInt(list?.credit).toFixed(0)}
            </Typography>
          </div>

          {/*  */}

          {/* <div className={cls.card}>
            <div className={cls.icon}>
              <DollarIcon arg={'#F8C51B'} />
            </div>
            <Typography style={{ color: '#696969' }} className={cls.title}>
              Ожидаемый расход
            </Typography>
            <Typography className={cls.price}>
              {list === undefined ? 0 : list?.active_cost}
            </Typography>
          </div> */}
        </Box>
      </div>
    </div>
  )
}
