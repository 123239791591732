import { authActionTypes } from '../actions/authActions/authActionTypes'

const initialState = {
  userRegister: {},
  userLogin: false,
  userLogout: false,
  phoneNumber: '',
  accessToken: localStorage.getItem('token'),
  refreshToken: '',
  userId: '',
  roleId: '',
  isLoading: false,
  permissions: [],
}

const authReducer = (state = initialState, action) => {
  const { payload } = action
  // console.log("action===>>", action)
  switch (action.type) {
    case authActionTypes.AUTH_REGISTER:
      return {
        ...state,
        userRegister: payload,
      }
    case authActionTypes.AUTH_LOGIN:
      // console.log("PAYLOAD ==>", payload )
      return {
        ...state,
        userLogin: payload.useLogin,
        phoneNumber: payload.phone,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
        permissions: payload.permissions,
      }
    case authActionTypes.AUTH_LOGOUT:
      localStorage.clear('token')
      // console.log("LOGOUT")
      return {
        ...initialState,
        accessToken: null,
      }
    default:
      return state
  }
}

export default authReducer
