import request from "../utils/axios"
const base_url = {
    logout: '/auth/logout',
    restoreEmail: '/auth/send-to-url',
    newLogin: '/auth/user-password'
};
const requests = {
    logout: () => (
        request({
            url: `${base_url.logout}`,
            method: 'post'
        })
    ),
    
    restoreSubmit: (data) => (
        request({
           url: `${base_url.restoreEmail}`,
            method: 'post',
            data: data
        })
    ),
    newLoginCreate: (data) => (
        request({
            url: `${base_url.newLogin}`,
            method: 'put',
            data: data
        })
    )
}
 export default requests