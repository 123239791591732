export const LogoIcon = () => (
  <svg
    width="169"
    height="33"
    viewBox="0 0 169 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.3601 4.67193V21.285C7.3601 22.9463 7.76883 24.2253 8.5863 25.1219C9.40377 26.0184 10.6036 26.4667 12.1858 26.4667C13.768 26.4667 14.981 26.0184 15.8249 25.1219C16.6687 24.2253 17.0906 22.9463 17.0906 21.285V4.67193H23.8545V21.2455C23.8545 23.7243 23.3271 25.8207 22.2723 27.5347C21.2175 29.2488 19.7936 30.5409 18.0004 31.4111C16.2336 32.2813 14.2559 32.7164 12.0671 32.7164C9.87843 32.7164 7.91387 32.2945 6.17345 31.4507C4.4594 30.5805 3.10134 29.2883 2.09928 27.5743C1.09722 25.8339 0.596191 23.7243 0.596191 21.2455V4.67193H7.3601Z"
      fill="white"
    />
    <path
      d="M42.5276 32.4395L36.7525 21.9575H35.1308V32.4395H28.3669V4.67193H39.7192C41.9079 4.67193 43.767 5.05429 45.2964 5.81902C46.8523 6.58375 48.0125 7.63855 48.7773 8.98342C49.542 10.3019 49.9244 11.7786 49.9244 13.4136C49.9244 15.2595 49.397 16.9076 48.3422 18.358C47.3137 19.8083 45.7843 20.8367 43.7538 21.4432L50.1617 32.4395H42.5276ZM35.1308 17.1713H39.3236C40.563 17.1713 41.486 16.8681 42.0925 16.2615C42.7254 15.655 43.0418 14.798 43.0418 13.6905C43.0418 12.6357 42.7254 11.805 42.0925 11.1985C41.486 10.592 40.563 10.2887 39.3236 10.2887H35.1308V17.1713Z"
      fill="white"
    />
    <path
      d="M74.7389 21.0477C74.7389 21.6806 74.6994 22.3398 74.6203 23.0254H59.3125C59.418 24.3967 59.8531 25.4515 60.6178 26.1898C61.4089 26.9018 62.3714 27.2578 63.5053 27.2578C65.193 27.2578 66.3665 26.5458 67.0257 25.1219H74.2247C73.8556 26.5722 73.1831 27.8775 72.2074 29.0378C71.2581 30.1981 70.0583 31.1079 68.6079 31.7671C67.1576 32.4264 65.5358 32.756 63.7427 32.756C61.5803 32.756 59.6553 32.2945 57.9676 31.3716C56.2799 30.4486 54.9614 29.1301 54.0121 27.4161C53.0628 25.702 52.5881 23.6979 52.5881 21.4037C52.5881 19.1095 53.0496 17.1054 53.9726 15.3913C54.9219 13.6773 56.2404 12.3588 57.9281 11.4358C59.6157 10.5129 61.5539 10.0514 63.7427 10.0514C65.8786 10.0514 67.7773 10.4997 69.4386 11.3963C71.0999 12.2929 72.392 13.5718 73.315 15.2331C74.2643 16.8944 74.7389 18.8326 74.7389 21.0477ZM67.8168 19.2677C67.8168 18.1074 67.4213 17.1845 66.6302 16.4989C65.8391 15.8133 64.8502 15.4704 63.6635 15.4704C62.5296 15.4704 61.5671 15.8001 60.776 16.4593C60.0113 17.1186 59.5366 18.0547 59.3521 19.2677H67.8168Z"
      fill="white"
    />
    <path
      d="M76.725 21.4037C76.725 19.1095 77.1865 17.1054 78.1095 15.3913C79.0588 13.6773 80.3641 12.3588 82.0254 11.4358C83.7131 10.5129 85.6381 10.0514 87.8004 10.0514C90.5693 10.0514 92.8767 10.7766 94.7226 12.2269C96.5948 13.6773 97.821 15.721 98.4012 18.358H91.2022C90.5957 16.6703 89.4222 15.8264 87.6818 15.8264C86.4424 15.8264 85.4535 16.3143 84.7151 17.29C83.9768 18.2393 83.6076 19.6105 83.6076 21.4037C83.6076 23.1969 83.9768 24.5813 84.7151 25.557C85.4535 26.5063 86.4424 26.9809 87.6818 26.9809C89.4222 26.9809 90.5957 26.1371 91.2022 24.4494H98.4012C97.821 27.0337 96.5948 29.0642 94.7226 30.5409C92.8503 32.0176 90.5429 32.756 87.8004 32.756C85.6381 32.756 83.7131 32.2945 82.0254 31.3716C80.3641 30.4486 79.0588 29.1301 78.1095 27.4161C77.1865 25.702 76.725 23.6979 76.725 21.4037Z"
      fill="white"
    />
    <path
      d="M108.546 14.0465C109.337 12.8334 110.326 11.8841 111.512 11.1985C112.699 10.4865 114.018 10.1305 115.468 10.1305V17.29H113.609C111.921 17.29 110.655 17.6592 109.812 18.3975C108.968 19.1095 108.546 20.3753 108.546 22.1948V32.4395H101.782V10.3678H108.546V14.0465Z"
      fill="white"
    />
    <path
      d="M140.258 10.3678V32.4395H133.494V29.4334C132.809 30.409 131.873 31.2001 130.686 31.8067C129.526 32.3868 128.234 32.6769 126.81 32.6769C125.122 32.6769 123.632 32.3077 122.34 31.5693C121.048 30.8046 120.046 29.7102 119.334 28.2863C118.622 26.8623 118.266 25.1878 118.266 23.2628V10.3678H124.99V22.353C124.99 23.8297 125.373 24.9768 126.137 25.7943C126.902 26.6118 127.93 27.0205 129.223 27.0205C130.541 27.0205 131.583 26.6118 132.347 25.7943C133.112 24.9768 133.494 23.8297 133.494 22.353V10.3678H140.258Z"
      fill="white"
    />
    <path
      d="M148.281 8.07366C147.095 8.07366 146.119 7.73085 145.354 7.04523C144.616 6.33324 144.247 5.46303 144.247 4.4346C144.247 3.3798 144.616 2.50959 145.354 1.82397C146.119 1.11198 147.095 0.755981 148.281 0.755981C149.442 0.755981 150.391 1.11198 151.129 1.82397C151.894 2.50959 152.276 3.3798 152.276 4.4346C152.276 5.46303 151.894 6.33324 151.129 7.04523C150.391 7.73085 149.442 8.07366 148.281 8.07366ZM151.643 10.3678V32.4395H144.88V10.3678H151.643Z"
      fill="white"
    />
    <path
      d="M168.609 26.7041V32.4395H165.168C162.716 32.4395 160.804 31.8462 159.433 30.6596C158.061 29.4465 157.376 27.482 157.376 24.7659V15.9847H154.686V10.3678H157.376V4.98837H164.14V10.3678H168.57V15.9847H164.14V24.845C164.14 25.5042 164.298 25.9789 164.614 26.269C164.931 26.559 165.458 26.7041 166.197 26.7041H168.609Z"
      fill="white"
    />
  </svg>
);

export const ErrorIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="8" fill="#F76659" />
    <path
      d="M11.5 5.205L10.795 4.5L8 7.295L5.205 4.5L4.5 5.205L7.295 8L4.5 10.795L5.205 11.5L8 8.705L10.795 11.5L11.5 10.795L8.705 8L11.5 5.205Z"
      fill="white"
    />
  </svg>
);

export const ProfileIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8ZM8 10C5.33 10 0 11.34 0 14V16H16V14C16 11.34 10.67 10 8 10Z"
      fill="#B0BABF"
    />
  </svg>
);
