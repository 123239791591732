import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useFormik } from "formik";
import Input from "../../../components/Input/index";
import { ToastContainer, toast } from "react-toastify";
import SendEmail from "../../../models/logout";
import * as Yup from "yup";
import InputError from "../../../components/Error/InputError";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import { useLocation, useHistory } from "react-router-dom";
import config from "../../../config/defaultSettings";

export default function RestorePassword({ setActiveTab }) {
  const { t } = useTranslation();
  const history = useHistory();
  const url = "/auth/login";

  const baseUrl = `${config.researcherBaseURL}/auth/new/login`;
  

  const formik = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: Yup.object({
      email: Yup.string().email("Invalid Email").required("Email is required."),
    }),

    onSubmit: (values) => {
      try {
        
        SendEmail.restoreSubmit({
          base_url: baseUrl,
          email: values.email,
        })
          .then((res) => {
            if (res.code === 200) {
              setActiveTab("login");
              history.push(`${url}`);
              toast.success(
                "Verification code has been sent, check your email"
              );
            }
          })
          .catch((err) => {
            let errorMsg = err.data.error;
            if (errorMsg === "invalid email") toast.error("Invalid Email");
            else if(errorMsg == "NOT_FOUND") toast.error("Email not found");
          });
      } catch (err) {
        toast("Something wnet wrong");
      }
    },
  });

  return (
    <div className=" w-full">
      <h2 className="mb-10 font-header">{t("restorPassword")}</h2>

      <div className="w-full flex justify-start ">
        <button
          onClick={() => setActiveTab("login")}
          type="button"
          className="text-gray-300 p-2 mb-3"
        >
          <ArrowBackIosIcon />
          {t("back")}
        </button>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col space-y-2">
          <label className="mt-2" htmlFor="email_id">
            {t("email.name")}
          </label>
          <span className="items-center space-x-2 p-0.5  rounded-lg flex">
            <Input
              name="email"
              icon={<AlternateEmailIcon className={"text-iconColor"} />}
              onChange={formik.handleChange}
              value={formik.values.email}
              type="text"
              id="email_id"
              className="w-full  p-1 rounded-md"
            ></Input>
          </span>
          {formik.touched.email && formik.errors.email ? (
            <InputError msg={formik.errors.email} />
          ) : null}
        </div>
        <div className="py-5">
          <button
            type="submit"
            className="w-full p-3 rounded-md bg-blue-500 p-3 text-white font-semibold active:bg-blue-700"
          >
            Send Code
          </button>
        </div>
        <ToastContainer />
      </form>
    </div>
  );
}
